import { useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

type Props = {
  gridApi: GridApi<any> | undefined;
  fetchNextPage: () => {};
  hasNextPage: boolean;
  paginationSize: number;
  totalSubmissions: number;
};

export const TablePagination = ({
  gridApi,
  fetchNextPage,
  hasNextPage,
  paginationSize,
  totalSubmissions,
}: Props) => {
  const totalPages =
    totalSubmissions != null
      ? Math.ceil(totalSubmissions / paginationSize)
      : "??";
  const [page, setPage] = useState(1);
  const [rowText, setRowText] = useState<string>();

  const onBtNext = useCallback(() => {
    fetchNextPage();
    gridApi?.paginationGoToNextPage();
    const newPage = page + 1;
    setPage(newPage);
    const min = Math.max(paginationSize * newPage - (paginationSize - 1), 1);
    // get the smaller of pagination or total submissions
    const max = Math.min(
      paginationSize * newPage,
      totalSubmissions ?? paginationSize * newPage,
    );
    setRowText(`${min} to ${max} of ${totalSubmissions}`);
  }, [gridApi, page]);

  const onBtPrevious = useCallback(() => {
    gridApi?.paginationGoToPreviousPage();
    const newPage = page - 1;
    setPage(newPage);
    const min = Math.max(paginationSize * newPage - (paginationSize - 1), 1);
    // get the smaller of pagination or total submissions
    const max = Math.min(
      paginationSize * newPage,
      totalSubmissions ?? paginationSize * newPage,
    );
    setRowText(`${min} to ${max} of ${totalSubmissions}`);
  }, [gridApi, page]);

  useEffect(() => {
    // when we have rows and total submissions set the row text
    if (totalSubmissions && rowText == null) {
      setRowText(
        `${paginationSize * page - (paginationSize - 1)} to ${
          paginationSize * page
        } of ${totalSubmissions}`,
      );
    }
  }, [totalSubmissions]);

  return (
    <div className="float-right flex items-center gap-2">
      {/* <span className="text-sm font-extralight">{rowText}</span> */}
      <Button
        variant={"outline"}
        size={"sm"}
        onClick={onBtPrevious}
        disabled={page === 1}
        className="h-10"
      >
        <Icons.chevronsLeft className="" />
        {/* <span className="hidden xl:block">{"Prev"}</span> */}
      </Button>
      {/* <span className=" text-sm font-extralight">
        Page {page} of {totalPages}
      </span> */}
      <Button
        variant={"outline"}
        size={"sm"}
        onClick={onBtNext}
        disabled={!hasNextPage && page === totalPages}
        className="h-10"
      >
        {/* <span className="hidden xl:block">{"Next"}</span> */}
        <Icons.chevronsRight className="" />
      </Button>
    </div>
  );
};
