import { CreateCustomSchemaPage } from "./create-custom-schema";
import { DocumentExtractLayout } from "./layout";
import { DocumentExtractHomePage } from "./page";
import { ResultsPage } from "./results-page";
import { UploadPage } from "./upload-page";

export const documentExtractRoutes = {
  id: "Document Extract",
  path: "document-extract",
  element: <DocumentExtractLayout />,
  children: [
    {
      id: "Landing",
      path: "",
      element: <DocumentExtractHomePage />,
    },
    {
      id: "Upload",
      path: "upload",
      element: <UploadPage />,
    },
    {
      id: "Custom",
      path: "custom",
      element: <CreateCustomSchemaPage />,
    },
    {
      id: "Reults",
      path: "results",
      element: <ResultsPage />,
    },
  ],
};
