import { LinkProps, NavLink } from "react-router-dom";

import { Icons } from "@/components/Icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/cn";
import { ValueOf } from "@/lib/typeHelpers";

export interface DashboardNavItemProps extends LinkProps {
  name: string;
  Icon: ValueOf<typeof Icons>;
}

export function DashboardNavItem({
  name,
  Icon,
  to,
  className,
  ...props
}: DashboardNavItemProps) {
  return (
    <Tooltip>
      <TooltipTrigger className="w-fit" tabIndex={-1}>
        <NavLink
          to={to}
          end={to === ""} // for the home page
          className={({ isActive }) =>
            cn(
              isActive
                ? "bg-primary/70 text-foreground"
                : "text-foreground/70 hover:bg-foreground/10 hover:text-foreground/90",
              "inline-flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm transition-colors duration-200 ease-in-out",
              className,
            )
          }
          {...props}
        >
          <span className="sr-only">{name}</span>
          <Icon className=" h-6 w-6" aria-hidden="true" />
        </NavLink>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={6} align="center">
        {name}
      </TooltipContent>
    </Tooltip>
  );
}
