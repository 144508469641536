import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TableCell, TableRow } from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { ColumnDataType } from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { submissionTypeEditFormSchema } from "../../submission-type-edit-form-schema";
import { z } from "zod";
import { match } from "ts-pattern";
import { CalendarFold, Hash, TextIcon } from "lucide-react";

type Column = z.infer<
  typeof submissionTypeEditFormSchema
>["outputColumns"][number];

interface NormalColumnRowProps extends React.ComponentProps<typeof TableRow> {
  column: Column;
  onColumnChange: (column: Column) => void;
  remove: () => void;
}

export const NormalColumnRow = ({
  column,
  remove,
  onColumnChange,
  className,
  ...props
}: NormalColumnRowProps) => {
  return (
    <TableRow className={cn("", className)} {...props}>
      <TableCell>
        <Input
          className="text-sm"
          placeholder="Column Name"
          value={column.name}
          onChange={(e) => {
            onColumnChange({ ...column, name: e.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <Select
          value={column.dataType}
          onValueChange={(value) => {
            onColumnChange({ ...column, dataType: value as ColumnDataType });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select data type" />
          </SelectTrigger>
          <SelectContent>
            {Object.values(ColumnDataType).map((dataType) => {
              return match(dataType)
                .with(ColumnDataType.String, () => {
                  return (
                    <SelectItem
                      key={ColumnDataType.String}
                      value={ColumnDataType.String}
                    >
                      <div className="flex items-center gap-2 text-sm">
                        <TextIcon className="h-4 w-4" />
                        Text
                      </div>
                    </SelectItem>
                  );
                })
                .with(ColumnDataType.Number, () => {
                  return (
                    <SelectItem
                      key={ColumnDataType.Number}
                      value={ColumnDataType.Number}
                    >
                      <div className="flex items-center gap-2 text-sm">
                        <Hash className="h-4 w-4" />
                        Number
                      </div>
                    </SelectItem>
                  );
                })
                .with(ColumnDataType.Date, () => {
                  return (
                    <SelectItem
                      key={ColumnDataType.Date}
                      value={ColumnDataType.Date}
                    >
                      <div className="flex items-center gap-2 text-sm">
                        <CalendarFold className="h-4 w-4" />
                        Date
                      </div>
                    </SelectItem>
                  );
                })
                .exhaustive();
            })}
          </SelectContent>
        </Select>
      </TableCell>
      <TableCell>
        <Textarea
          value={column.description}
          onChange={(e) => {
            onColumnChange({ ...column, description: e.target.value });
          }}
          placeholder="Column Description"
          className="w-full"
        />
      </TableCell>
      <TableCell>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={() => {
            remove();
          }}
        >
          <Icons.trash className="h-4 w-4" />
          <span className="sr-only">Remove column</span>
        </Button>
      </TableCell>
    </TableRow>
  );
};
