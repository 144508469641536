import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  QueryCache,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { GraphQLClient, Variables } from "graphql-request";

import { env } from "@/env";
import { queryClient } from "@/queryClient";

const graphQLClient = new GraphQLClient(env.VITE_PUBLIC_APOLLO_API_URL, {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const queryCache = new QueryCache({
  onError: (error) => {
    console.error(error);
  },
  onSuccess: (data) => {},
  onSettled: (data, error) => {},
});

export function getGraphqlQueryCache() {
  return queryCache;
}

export function getGraphqlClient() {
  return graphQLClient;
}

interface GqlQueryOptions<TData, TVariables>
  extends Omit<UseQueryOptions<TData, Error, TData>, "queryFn"> {
  query: TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
}

export const createQueryOptions = <TData, TVariables>({
  query,
  variables,
  ...options
}: Omit<GqlQueryOptions<TData, TVariables>, "queryKey">) => {
  return {
    queryKey: [query, variables],
    queryFn: () => {
      const client = getGraphqlClient();
      return client.request(query, variables as Variables);
    },
    ...options,
  } as UseQueryOptions<TData, Error, TData>;
};

export type GetQueryOptions = Omit<
  UseQueryOptions<any, Error, any>,
  "queryKey" | "queryFn"
>;

export const ensureQueryData = <TData, TVariables>(
  queryOptions: UseQueryOptions<TData, Error, TData>,
) => {
  return queryClient.ensureQueryData(queryOptions);
};

export const fetchQueryData = async <TData, TVariables>(
  queryOptions: UseQueryOptions<TData, Error, TData>,
) => {
  // const loader = createFetchDataLoader<TData, TVariables>(queryOptions);
  return queryClient.fetchQuery(queryOptions);
};

export const useGraphqlQuery = <TData = any, TVariables = Record<string, any>>({
  query,
  variables,
  ...options
}: GqlQueryOptions<TData, TVariables>) => {
  const client = getGraphqlClient();

  return useQuery<TData>({
    queryFn: () => client.request(query, variables as Variables),
    ...options,
  });
};

export interface GqlMutationOptions<TData, TVariables>
  extends Omit<UseMutationOptions<TData, Error, TVariables>, "mutationFn"> {
  mutation: TypedDocumentNode<TData, TVariables>;
}

export interface InfiniteQueryOptions<TData, TVariables>
  extends UseInfiniteQueryOptions<TData, Error, TData> {
  query: TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
}

export const useGraphqlInfiniteQuery = <
  TData = any,
  TVariables = Record<string, any>,
>({
  query,
  variables,
  ...options
}: InfiniteQueryOptions<TData, TVariables>) => {
  const client = getGraphqlClient();

  return useInfiniteQuery<TData>({
    queryFn: ({ pageParam }) =>
      client.request(query, { ...variables, cursor: pageParam } as Variables),
    ...options,
    select: undefined, // Ensure select is undefined or returns InfiniteData<TData, unknown>
  });
};

export const createMutationOptions = <TData, TVariables>({
  mutation,
  ...options
}: Omit<GqlMutationOptions<TData, TVariables>, "mutationFn">) => {
  return {
    mutationFn: (variables?: TVariables) =>
      getGraphqlClient().request(mutation, variables as Variables),
    ...options,
  } as UseMutationOptions<TData, Error, TVariables>;
};

export const useGraphqlMutation = <
  TData = any,
  TVariables = Record<string, any>,
>({
  mutation,
  ...options
}: GqlMutationOptions<TData, TVariables>) => {
  const client = getGraphqlClient();

  return useMutation({
    mutationFn: (variables?: TVariables) =>
      client.request(mutation, variables as Variables),
    ...options,
  });
};
