import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

export const DisableVendorDialog = () => {
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Button variant="outline">Disable Vendor</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>Disable Vendor</AlertDialogHeader>
        <AlertDialogDescription>
          A disabled vendor will no longer be able to submit data to your
          organization. After 30 days of inactivity, the vendor will be
          permanently deleted.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Go Back</AlertDialogCancel>
          <AlertDialogAction className="w-fit bg-destructive text-primary hover:bg-destructive/75">
            Disable Vendor
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
