import { ColumnDataType } from "@/gql/graphql";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

export const outputColumnsSchema = z.object({
  name: z
    .string()
    .min(1, "Output column name is required")
    .max(50, "Output column name is too long"),
  dataType: z.enum([
    ColumnDataType.String,
    ColumnDataType.Number,
    ColumnDataType.Date,
  ]),
  description: z.string().min(1, "Output column description is required"),
});

export const submissionTypeEditFormSchema = z.object({
  name: z
    .string()
    .min(1, "Submission name is required")
    .max(50, "Submission name is too long"),
  icon: z.string(),
  outputColumns: z.array(outputColumnsSchema),
});

export const useSubmissionTypeEditFormContext = () => {
  return useFormContext<z.infer<typeof submissionTypeEditFormSchema>>();
};
