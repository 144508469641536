import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useUpdateOneVendor = () => {
  return useGraphqlMutation({
    mutationKey: ["updateOneVendor"],
    mutation: graphql(`
      mutation UpdateOneVendor(
        $data: VendorUpdateInput!
        $where: VendorWhereUniqueInput!
      ) {
        updateOneVendor(data: $data, where: $where) {
          id
        }
      }
    `),
  });
};

// {
// "where": {
//     "id": null
//   }
//     "data": {
//       "email": {
//         "set": null
//       },
//       "name": {
//         "set": null
//       },
//       "vendorId": {
//         "set": null
//       },
//       "submissionTypes": {
//         "connect": [
//           {
//             "id": null
//           }
//         ]
//       }
//     }
//   }
