import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from "@/components/ui/table";

import { Button } from "@/components/ui/button";

import { cn } from "@/lib/cn";
import { NormalColumnRow } from "./normal-column-row";
import { useFieldArray } from "react-hook-form";

import { ColumnDataType } from "@/gql/graphql";
import { useSubmissionTypeEditFormContext } from "../../submission-type-edit-form-schema";

interface NormalColumnsEditProps extends React.HTMLAttributes<HTMLDivElement> {}

export function NormalColumnsEdit({
  className,
  ...props
}: NormalColumnsEditProps) {
  const { control } = useSubmissionTypeEditFormContext();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "outputColumns",
  });

  fields.forEach((field) => {
    console.log(field.name, field.id);
  });

  return (
    <div className={cn("grid w-full max-w-5xl gap-6", className)} {...props}>
      <Card>
        <CardHeader>
          <CardTitle>Preferred Upload Format</CardTitle>
          <CardDescription>
            The submission format you would prefer your vendors to upload data
            in.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-80">Column Name</TableHead>
                  <TableHead>Data Type</TableHead>
                  <TableHead className="w-80">Description</TableHead>
                  <TableHead className="w-24" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {fields.map((column, index) => (
                  <NormalColumnRow
                    key={index} // it has to be index because that's just how react-hook-form works...
                    column={column}
                    onColumnChange={(val) => {
                      update(index, val);
                    }}
                    remove={() => remove(index)}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="mt-4 flex">
            <Button
              size={"sm"}
              variant={"secondary"}
              className="w-full"
              onClick={() => {
                append({
                  name: "",
                  dataType: ColumnDataType.String,
                  description: "",
                });
              }}
            >
              Add Column
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
