import { format } from "date-fns";
import { DateRange } from "react-day-picker";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/cn";

import { useTableFilterContext } from "../table/TableFiltersContext";

export const ProductsTableDatePicker = () => {
  const { dateRangeFilter, setDateRangeFilter } = useTableFilterContext();

  const handleDateChange = (range: DateRange | undefined) => {
    setDateRangeFilter(range);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-fit pl-3 text-left font-normal",
            !dateRangeFilter && "text-muted-foreground",
          )}
        >
          {dateRangeFilter?.from != null && dateRangeFilter.to != null ? (
            <>
              {format(dateRangeFilter.from, "PPP")} -{" "}
              {format(dateRangeFilter.to, "PPP")}
            </>
          ) : (
            <span>Pick a date</span>
          )}
          <Icons.calendar className="ml-4 h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          selected={dateRangeFilter}
          onSelect={handleDateChange}
          disabled={(date) =>
            date > new Date() || date < new Date("1900-01-01")
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};
