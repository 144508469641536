import { useMemo } from "react";
import { ColDef } from "ag-grid-community";

import { GetProductsInfiniteQuery } from "@/gql/graphql";

import { ProductRowData, ProductsColumnHeaders } from "./getProductsTableRows";

type Props = {
  products: GetProductsInfiniteQuery["products"];
};

export const useGetProductsTableColDefs = ({ products }: Props) => {
  const columnHeaders = Object.values(ProductsColumnHeaders);
  return useMemo(() => {
    const colDefs = columnHeaders.reduce(
      (acc, header) => {
        // filter out certain columns - MFG part number and Attributes
        if (
          header === ProductsColumnHeaders.MANUFACTURER_PART_NUMBER ||
          header === ProductsColumnHeaders.ATTRIBUTES
        ) {
          return acc;
        }

        const standardColDef: ColDef<ProductRowData> = {
          headerName: header,
          field: header,
          colId: header,
          filter: false,
          editable: false,
          resizable: true,
          flex: 0.7,
        };

        let customColDef = standardColDef as ColDef<ProductRowData>;

        if (header === ProductsColumnHeaders.ID) {
          customColDef = {
            ...standardColDef,
            checkboxSelection: true,
            //   maxWidth: 100,
          };
        }

        acc.push(customColDef);
        return acc;
      },
      [] as Array<ColDef<ProductRowData>>,
    );

    return colDefs;
  }, [products, columnHeaders]);
};
