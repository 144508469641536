import { FeedbackCreateManyInput } from "@intuitive-systems/protege-engine";
import { QueryKey } from "@tanstack/react-query";
import { toast } from "sonner";
import { match, P } from "ts-pattern";

import { graphql } from "@/gql";
import { PredictionRowsQuery } from "@/gql/graphql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";
import { queryClient } from "@/queryClient";

export const submitManyFeedbackMutation = graphql(`
  mutation SubmitManyFeedback($data: [FeedbackCreateManyInput!]!) {
    createManyFeedback(data: $data) {
      count
    }
  }
`);

type Params = {
  queryKey: QueryKey;
};

export const useSubmitFeedback = ({ queryKey }: Params) => {
  return useGraphqlMutation({
    mutation: submitManyFeedbackMutation,
    onMutate: async ({ data: mutationData }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousData =
        queryClient.getQueryData<PredictionRowsQuery>(queryKey);

      const updateOptimistic = (feedback: FeedbackCreateManyInput[]) => {
        queryClient.setQueryData<PredictionRowsQuery>(
          queryKey,
          (_previousData) => {
            if (!_previousData) return undefined;

            // flat map of all labels
            const optimisticLabels = _previousData.predictions.flatMap((p) =>
              p.labels.map((label) => ({
                ...label,
                predictionId: p.id,
              })),
            );

            // update relevant labels with feedback
            feedback.forEach((f, i) => {
              const label = optimisticLabels.find((l) => l.id === f.labelId);
              if (!label) return;

              label.feedback = [
                {
                  ...f,
                  id: (i + 1) * -1,
                  upvote: !!f.upvote,
                },
                ...label.feedback,
              ];
            });

            // update predictions
            const optimisticPredictions = _previousData.predictions.map((p) => {
              const relevantLabels = optimisticLabels.filter(
                (l) => l.predictionId === p.id,
              );

              return {
                ...p,
                labels: relevantLabels,
              };
            });

            return {
              predictions: optimisticPredictions,
            };
          },
        );
      };

      match(mutationData)
        .with(P.array(P.any), updateOptimistic)
        .with(P.any, (feedback) => updateOptimistic([feedback]))
        .exhaustive();

      return { previousData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError(error, variables, context) {
      toast.error("Error submitting labels");
      // @ts-ignore - https://github.com/TanStack/query/discussions/3434#discussioncomment-2425225
      queryClient.setQueryData(queryKey, context.previousData);
    },
  });
};
