import { Suspense } from "react";
import { useParams } from "react-router-dom";

import { TransformationPredictionsTable } from "@/components/shared-components/transformation-predictions-table/TransformationPredictionsTable";
import { ValidationExcelTableDataWrapper } from "@/components/shared-components/validation-excel-table/ValidationExcelTableDataWrapper";
import { Spinner } from "@/components/Spinner";

import { SubmissionErrors } from "./errors/SubmissionErrors";
import { useGetSubmissionErrors } from "./useGetSubmissionErrors";
import { useGetTransformationType } from "./useGetTransformationType";

export function SubmissionCorrectionsView() {
  const { submissionId: sId, transformationId: tId } = useParams();
  const transformationId = Number(tId);
  const submissionId = Number(sId);

  const { data } = useGetSubmissionErrors({
    submissionId: Number(submissionId),
  });
  const submission = data?.submission;
  const errors = submission?.errors ?? [];
  const vendorId = submission?.vendorId;
  const submissionType = submission?.type?.type;

  const { data: transformationData } = useGetTransformationType({
    transformationId,
  });
  const isNormalizeTransformation =
    transformationData?.transformation?.transformationTypeId === 1;

  return (
    <Suspense fallback={<Spinner />}>
      {errors.length > 0 ? (
        <SubmissionErrors
          vendorId={vendorId}
          submissionType={submissionType}
          errors={errors}
        />
      ) : isNormalizeTransformation && vendorId ? (
        <ValidationExcelTableDataWrapper
          submissionId={submissionId}
          vendorId={vendorId}
        />
      ) : (
        <TransformationPredictionsTable />
      )}
    </Suspense>
  );
}
