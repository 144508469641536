import { Icons } from "@/components/Icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { SubmissionPeriod } from "../TableFiltersContext";
import { Draggable } from "./Draggable";

type Props = {
  period: SubmissionPeriod;
};

export const SubmissionPeriodItem = ({ period }: Props) => {
  return (
    <Tooltip key={period.label}>
      <TooltipTrigger>
        <Draggable
          key={period.label}
          id={period.label}
          className="w-24 cursor-grab"
        >
          {period.label}
          <Icons.gripVertical className="ml-1 h-4 w-4 shrink-0 text-muted-foreground" />
        </Draggable>
      </TooltipTrigger>
      <TooltipContent>
        {period.start} - {period.end}
      </TooltipContent>
    </Tooltip>
  );
};
