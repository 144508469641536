import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useResetSubmission = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation ResetSubmission($submissionId: Float!) {
        resetSubmission(submissionId: $submissionId) {
          id
        }
      }
    `),
  });
};
