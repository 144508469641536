import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TransformationStatus } from "@/gql/graphql";
import { ReviewRoutes } from "@/router";

import { getSubmissionTransformationsQueryOptions } from "../../../../../../components/shared-components/transformation-predictions-table/getSubmissionTransformationsQueryOptions";
import { useApproveTransformation } from "../../../../../../lib/hooks/mutations/useApproveTransformation";
import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { EnrichmentsList } from "./EnrichmentsList";
import { ListItem } from "./ListItem";

export const YourExtractionsPage = () => {
  const location = useLocation();
  const { submissionId: pId } = useParams();
  const submissionId = Number(pId);
  const queryOptions = getSubmissionTransformationsQueryOptions(submissionId);
  const { data } = useQuery(queryOptions);

  const transformations = (data?.submission?.transformations ?? []).sort(
    (a, b) => a.id - b.id,
  );
  const normalizedValidation = transformations.find(
    (transformation) => transformation.type.templateId === 1,
  );
  const enrichments = transformations.filter(
    (transformation) => transformation.type.templateId !== 1,
  );

  const approvedEnrichments = enrichments.filter(
    (transformation) => transformation.status === TransformationStatus.Approved,
  ).length;

  const { approveTransformation } = useApproveTransformation();

  // const onApproveAllTransformations = () => {
  //   transformations.forEach((transformation) => {
  //     mutate({
  //       data: {
  //         status: {
  //           set: TransformationStatus.Approved,
  //         },
  //       },
  //       where: {
  //         id: transformation.id,
  //       },
  //     });
  //   });
  // };

  return (
    <ScrollArea>
      <BodyLayout>
        <Header
          title="Your Data Extractions"
          description="Click on the items below to review the extracted data. We've surfaced the most uncertain items for your review (corrections you make will apply to all future submissions)."
        />
        <div className="flex justify-between gap-6 pt-6">
          {/* {approvedEnrichments === transformations.length ? (
            <HoverBorderGradient
              as="button"
              containerClassName=""
              onClick={() => navigate(`../${SubmissionRoutes.Summary}`)}
              className={cn(
                "min-w-[250px] hover:bg-primary-foreground hover:text-accent-foreground",
              )}
            >
              Go To Submit
            </HoverBorderGradient>
          ) : (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <HoverBorderGradient
                  as="button"
                  containerClassName=""
                  className={cn(
                    "min-w-[250px] hover:bg-primary-foreground hover:text-accent-foreground",
                  )}
                >
                  Go To Submit
                </HoverBorderGradient>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>Caution</AlertDialogHeader>
                <AlertDialogDescription>
                  Some extractions have not been reviewed. You may be missing
                  out on important information.
                </AlertDialogDescription>
                <AlertDialogFooter>
                  <AlertDialogCancel>Go Back</AlertDialogCancel>
                  <AlertDialogAction onClick={onApproveAllTransformations}>
                    <Link
                      to={`../${SubmissionRoutes.Summary}`}
                      className={buttonVariants({
                        variant: "default",
                      })}
                    >
                      Approve All & Continue
                    </Link>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )} */}
        </div>

        <section className="flex w-full flex-col pt-6 md:px-16">
          <div>
            <div className="mb-2 flex items-center">
              <h2 className="text-lg font-semibold">Normalize & Validate</h2>
            </div>
            <ListItem
              name="Normalized Data Validations"
              description="The normalized output file has been validated against our internal data model."
              href={`${location.pathname}/${ReviewRoutes.Validation}`}
              approved={
                normalizedValidation?.status === TransformationStatus.Approved
              }
              onApprove={() => {
                if (normalizedValidation?.id && normalizedValidation?.status) {
                  approveTransformation(
                    normalizedValidation?.id,
                    normalizedValidation?.status,
                  );
                }
              }}
            />
          </div>
          <div>
            <div className="mb-2 flex items-center">
              <h2 className="text-lg font-semibold">Enrichments</h2>
              <Badge variant={"outline"} className="ml-2 h-6">
                {approvedEnrichments}/{enrichments.length}
              </Badge>
            </div>
            <EnrichmentsList enrichments={enrichments} />
          </div>
        </section>
      </BodyLayout>
    </ScrollArea>
  );
};
