import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export const VendorDataSettings = () => {
  const formSchema = z.object({
    vendorId: z.string(),
    vendorName: z.string(),
    contactEmail: z.string(),
    approvedEmailList: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vendorId: "12345",
      vendorName: "Demo Vendor",
      contactEmail: "submissions@vendor.com",
      approvedEmailList: "matt@vendor.com, josh@vendor.com",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
  }

  const [show, setShow] = useState(true);

  return (
    <Card>
      <CardHeader className=" flex flex-row  justify-between">
        <div className="flex flex-col gap-1">
          <CardTitle>Vendor Data & Notification Preferences</CardTitle>
          <CardDescription>Update your account settings</CardDescription>
        </div>
        <Button onClick={() => setShow(!show)} className="" variant={"outline"}>
          {show ? (
            <Icons.chevronsUp className="h-4 w-4" />
          ) : (
            <Icons.chevronsDown className="h-4 w-4" />
          )}
        </Button>
      </CardHeader>
      {show && (
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid grid-cols-2 gap-8"
            >
              <FormField
                control={form.control}
                name="vendorId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className=" inline-flex">
                      Vendor Id
                      <Icons.copy
                        onClick={() => {}}
                        className="ml-2 h-4 w-4 cursor-pointer text-muted-foreground hover:text-primary"
                      />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="vendorName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vendor Name</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="contactEmail"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Contact Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="col-span-2 ">
                <Button
                  className="float-right"
                  variant={"outline"}
                  type="submit"
                >
                  Update Settings
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      )}
    </Card>
  );
};
