import { graphql } from "@/gql";
import { Submission, Vendor } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getSubmissionNormalMapQueryOptions = ({
  submissionId,
  vendorId,
}: {
  vendorId: Vendor["id"];
  submissionId: Submission["id"];
}) => {
  return createQueryOptions({
    query: graphql(`
      query SubmissionNormalMap(
        $where: SubmissionWhereUniqueInput!
        $normalizeMappingsWhere2: NormalizeMappingWhereInput
      ) {
        submission(where: $where) {
          type {
            id
            outputColumns {
              name
              dataType
              description
            }
            normalizeMappings(where: $normalizeMappingsWhere2) {
              id
              mapping
            }
          }
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
      normalizeMappingsWhere2: {
        vendorId: {
          equals: vendorId,
        },
      },
    },
  });
};
