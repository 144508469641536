import { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/cn";

// Mock data for demonstration purposes
const productData = [
  { name: "Ryzen 7 5800X", Sales: 4200 },
  { name: "Intel Core i7-11700K", Sales: 3900 },
  { name: "Ryzen 9 5900X", Sales: 3800 },
  { name: "Intel Core i9-11900K", Sales: 4300 },
  { name: "Ryzen 5 5600X", Sales: 3500 },
  { name: "Intel Core i5-11600K", Sales: 3300 },
  { name: "Ryzen 7 3700X", Sales: 3100 },
  { name: "Intel Core i7-9700K", Sales: 3000 },
  { name: "Nvidia RTX 3060", Sales: 4600 },
  { name: "Ryzen 9 3950X", Sales: 3200 },
  { name: "Intel Core i9-9900K", Sales: 3400 },
  { name: "Ryzen 5 3600", Sales: 3000 },
  { name: "Intel Core i5-10600K", Sales: 3200 },
  { name: "Nvidia GTX 1660 Ti", Sales: 4000 },
  { name: "Ryzen 3 3300X", Sales: 2800 },
  { name: "Intel Core i3-10100", Sales: 2700 },
  { name: "Nvidia RTX 2060", Sales: 4500 },
];

const partnerData = [
  { name: "Partner A", Sales: 5000 },
  { name: "Partner B", Sales: 3500 },
  { name: "Partner C", Sales: 3000 },
  { name: "Partner D", Sales: 4000 },
  { name: "Partner E", Sales: 3200 },
];

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#00c49f"];

enum View {
  PRODUCTS = "products",
  PARTNERS = "partners",
}

export const ChannelAnalysis = () => {
  const [view, setView] = useState<View>(View.PRODUCTS);
  const [chartData, setChartData] = useState<
    {
      name: string;
      Sales: number;
    }[]
  >([]);

  useEffect(() => {
    // Fetch data here and update the state based on view
    const data = view === View.PRODUCTS ? productData : partnerData;
    // Get top 5 items by sales
    const topData = data.sort((a, b) => b.Sales - a.Sales).slice(0, 5);
    setChartData(topData);
  }, [view]);

  return (
    <Card className={cn("grid min-h-[500px] grid-rows-[auto_1fr]")}>
      <CardHeader>
        <div className="flex flex-row items-start justify-between gap-4">
          <CardTitle className="font-semibold">Top Performers</CardTitle>
          <div>
            <Button
              className={cn(view === View.PRODUCTS ? "underline" : "")}
              variant={"ghost"}
              onClick={() => setView(View.PRODUCTS)}
            >
              Products
            </Button>
            <Button
              className={cn(view === View.PARTNERS ? "underline" : "")}
              variant={"ghost"}
              onClick={() => setView(View.PARTNERS)}
            >
              Partners
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="w-full">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="Sales"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label={({ name }) => name}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value) => [`${value} Sales`]} />
            {/* <Legend /> */}
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
