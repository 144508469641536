import {
  addDays,
  addWeeks,
  endOfDay,
  endOfYear,
  format,
  isBefore,
  isSameDay,
  parse,
  startOfYear,
} from "date-fns";

import { SubmissionPeriod } from "../TableFiltersContext";

type Params = {
  selectedYear: string;
};

export const getSubmissionPeriods = ({
  selectedYear,
}: Params): SubmissionPeriod[] => {
  const submissionPeriods: SubmissionPeriod[] = [];
  const yearDate = parse(selectedYear, "yyyy", new Date());
  const startDate = startOfYear(yearDate);
  const currentDate = endOfDay(new Date());
  const endDate =
    yearDate.getFullYear() === currentDate.getFullYear()
      ? currentDate
      : endOfYear(yearDate);
  const shortYear = format(yearDate, "yy");

  let periodStart = startDate;
  let weekNumber = 1;

  while (isBefore(periodStart, endDate) || isSameDay(periodStart, endDate)) {
    let periodEnd = addDays(periodStart, 6);

    if (isBefore(endDate, periodEnd)) {
      periodEnd = endDate; // Cap the end date to the current date or end of the year
    }

    submissionPeriods.push({
      start: format(periodStart, "yyyy-MM-dd"),
      end: format(periodEnd, "yyyy-MM-dd"),
      label: `W${weekNumber}/Y${shortYear}`,
    });

    // Move to the next week
    periodStart = addWeeks(periodStart, 1);
    weekNumber++;
  }

  return submissionPeriods;
};
