import { GetSubmissionSummaryDataQuery } from "@/gql/graphql";
import { getLabelValue } from "@/lib/getLabelValue";

type Submission = NonNullable<GetSubmissionSummaryDataQuery["submission"]>;

type Prediction = NonNullable<
  Submission["transformations"]
>[0]["predictions"][0];

export type Label = NonNullable<
  NonNullable<Submission["transformations"]>[0]["predictions"][0]["labels"][0]
>;

export type ProductSummaryData = {
  laptopsSold: number;
  desktopsSold: number;
  mobilesSold: number;
  serversSold: number;
  validGPUs: Prediction[];
  validCPUs: Prediction[];
  //   products: Prediction[];
  totalRows: number;
};

type Params = {
  transformations: Submission["transformations"];
};

export const getAllProductsSold = ({ transformations }: Params) => {
  if (transformations.length === 0) {
    return {
      laptopsSold: 0,
      desktopsSold: 0,
      mobilesSold: 0,
      serversSold: 0,
      totalRows: 0,
      validCPUs: [],
      validGPUs: [],
    };
  }
  const totalRows = transformations[0].predictions.length;
  //   const products: Label[] = [];
  let laptopsSold = 0;
  let desktopsSold = 0;
  let mobilesSold = 0;
  let serversSold = 0;
  const validCPUs: Prediction[] = [];
  const validGPUs: Prediction[] = [];

  transformations.forEach((transformation) => {
    transformation.predictions.forEach((prediction) => {
      prediction.labels.forEach((label) => {
        const value = getLabelValue(label);

        if (label.name === "CPU Manufacturer") {
          if (value !== "NULL") {
            validCPUs.push(prediction);
          }
        }

        if (label.name === "GPU Manufacturer") {
          if (value !== "NULL") {
            validGPUs.push(prediction);
          }
        }

        // fill in logic here based on above fields
        if (label.name === "Product Type") {
          if (value === "LAPTOP") {
            laptopsSold += 1;
          } else if (value === "COMPUTER SYSTEM") {
            desktopsSold += 1;
          } else if (value === "MOBILE") {
            mobilesSold += 1;
          } else if (value === "SERVER") {
            serversSold += 1;
          }
        }
      });
    });
  });
  return {
    laptopsSold,
    desktopsSold,
    mobilesSold,
    serversSold,
    totalRows,
    validCPUs,
    validGPUs,
  };
};
