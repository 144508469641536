import { useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { FilterTypeaheadTextInput } from "./FilterTypeaheadTextInput";
import {
  ActiveFilter,
  FilterOperation,
  filterOperationOptions,
  useTableFilterContext,
} from "./TableFiltersContext";
import { TableFiltersPopover } from "./TableFiltersPopover";
import { cn } from "@/lib/cn";

type Props = {
  activeFilter: ActiveFilter;
};

export const Filter = ({ activeFilter }: Props) => {
  const shouldInitOpen =
    activeFilter.filterValues && activeFilter.filterValues?.length === 0
      ? true
      : false;

  const { setActiveFilters } = useTableFilterContext();
  const { label, filterValues } = activeFilter;
  const [open, setOpen] = useState(shouldInitOpen);
  const [isWide, setIsWide] = useState(false);

  const validFilterOperations = activeFilter.validFilterOperations.reduce(
    (acc, filterValue) => {
      const filterOperation = filterOperationOptions.find(
        (option) => option.value === filterValue,
      );
      if (filterOperation) {
        acc.push(filterOperation);
      }
      return acc;
    },
    [] as FilterOperation[],
  );

  const modifyFilterOperation = (value: string) => {
    const newOperation = validFilterOperations.find(
      (option) => option.value === value,
    );

    if (newOperation != null) {
      setActiveFilters((prev) => {
        return prev.map((filter) => {
          if (filter.label === label) {
            return {
              ...filter,
              filterOperation: newOperation,
            };
          }
          return filter;
        });
      });
    }
  };

  const removeFilter = () => {
    setActiveFilters((prev) => {
      return prev.filter((filter) => filter.label !== label);
    });
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={"outline"} className="space-x-1">
          <Icons.filter className="mr-1 h-4 w-4" />
          <span>{label}</span>
          <span>
            <activeFilter.filterOperation.icon className="h-4 w-4" />
          </span>
          {(filterValues == null || filterValues?.length === 0) && (
            <span>?</span>
          )}

          <div className="flex max-w-[200px] items-center overflow-hidden">
            {filterValues && filterValues.length > 0 && (
              <ul className="flex items-center space-x-1 overflow-hidden">
                {filterValues.map((value, index) => {
                  const isLast = index === filterValues.length - 1;
                  return (
                    <li
                      key={value}
                      className={cn("flex-shrink-0 truncate text-ellipsis")}
                    >
                      {value}
                      {!isLast && ","}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <Icons.remove onClick={removeFilter} className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-fit items-start gap-2" align="center">
        <div className="flex h-10 w-fit items-center gap-1">
          <Icons.cornerDownRight className="h-4 w-4" />
          <p className="text-sm">where</p>
        </div>
        <div
          className={cn(
            "flex items-start gap-2",
            isWide ? "flex-col" : "flex-row",
          )}
        >
          <div className="flex gap-2">
            <TableFiltersPopover activeFilterLabel={label} />
            <Select
              defaultValue={activeFilter.filterOperation.value}
              onValueChange={modifyFilterOperation}
              required
            >
              <SelectTrigger className=" w-32">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="">
                <SelectGroup>
                  {validFilterOperations.map((option) => {
                    return (
                      <SelectItem value={option.value} key={option.value}>
                        {option.label}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <FilterTypeaheadTextInput
            activeFilter={activeFilter}
            setIsWide={setIsWide}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
