import { useGetVendorsTypeahead } from "../submissions-table/filters/useGetVendorsTypeahead";
import { NameFilter } from "../table/NameFilter";
import { useTableFilterContext } from "../table/TableFiltersContext";
type Props = {
  orgId: number;
};

export const VendorNameFilter = ({ orgId }: Props) => {
  const { searchNameFilter } = useTableFilterContext();
  const { data: vendorData } = useGetVendorsTypeahead({
    orgId,
    typeahead: searchNameFilter,
  });

  const names =
    vendorData?.vendors.map((vendor) => ({
      label: vendor.name,
      value: vendor.name,
    })) ?? [];

  return (
    <NameFilter
      showTypeahead={false}
      typeaheadItems={names}
      placeholder="Search vendor names..."
    />
  );
};
