import { CONFIDENCE_INTERVALS } from "@/lib/constants/confidenceIntervals";

import { TransformationPredictionsTableAgGridParams } from "./useGetTransformationsPredictionsTableColDefs";

type category = "low" | "mid" | "high";

type Params = {
  header: string;
  params: TransformationPredictionsTableAgGridParams;
  category: category;
};

export const getConfidenceStyle = ({ header, category, params }: Params) => {
  const cellData = params.data[header];
  if (!cellData) return false;
  // check if params.data is a string or a Label
  if (typeof cellData === "string") {
    return false;
  }

  const { feedback, confidence } = cellData;

  // if feedback exists, the cell is considered valid
  if (feedback && feedback.length > 0) {
    return false;
  }

  // iterate over confidence interval values and return the first one that is true, the key is the confidence category
  const confidenceCategory = getConfidenceCategory(confidence);

  return confidenceCategory === category;
};

export function getConfidenceCategory(confidence: number): category {
  const confidenceCategory = Object.keys(CONFIDENCE_INTERVALS)
    .find((key) => {
      // @ts-ignore - TS doesn't know the key is a key of CONFIDENCE_INTERVALS
      const [min, max] = CONFIDENCE_INTERVALS[key];
      return confidence >= min && confidence <= max;
    })
    ?.toLocaleLowerCase() as category;

  return confidenceCategory;
}
