import { QueryMode } from "@/gql/graphql";

import {
  FilterOperationOptions,
  useTableFilterContext,
} from "../table/TableFiltersContext";

export const useGetProductsSearchVarsFromActiveFilters = () => {
  const { searchNameFilter, dateRangeFilter, activeFilters } =
    useTableFilterContext();

  const variables: Record<string, any> = {};
  activeFilters.forEach((filter) => {
    const { searchField, filterValues } = filter;

    // for each filter value we need to add a new key to the variables object
    // then the filterValues will be an array of strings as the value to that key
    if (filterValues && filterValues.length > 0) {
      variables[searchField] = {};

      variables[searchField].mode = QueryMode.Insensitive;

      //   switch statement to handle the different filter operations
      switch (filter.filterOperation.value) {
        case FilterOperationOptions.CONTAINS:
          variables[searchField].contains = filterValues;
          break;
        case FilterOperationOptions.IN:
          variables[searchField].in = filterValues;
          break;
        default:
          break;
      }
    }
  });

  if (searchNameFilter) {
    variables.searchName = {
      mode: QueryMode.Insensitive,
      contains: searchNameFilter,
    };
  }

  if (dateRangeFilter) {
    variables.createdAt = {
      gte: dateRangeFilter.from,
      lte: dateRangeFilter.to,
    };
  }

  return variables;
};
