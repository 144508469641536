import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useBlocker,
  useParams,
} from "react-router-dom";

import { fetchQueryData, useGraphqlMutation } from "@/lib/hooks/graphql";

import { SubmissionTypeEditHeader } from "./(header)/submission-type-edit-header";
import { getSubmissionTypeLayoutQueryOptions } from "./submission-type-edit-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { z } from "zod";
import { queryClient } from "@/queryClient";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { toast } from "sonner";

import { submissionTypeEditMutation as mutation } from "./submission-type-edit-mutation";
import { zodResolver } from "@hookform/resolvers/zod";
import { submissionTypeEditFormSchema } from "./submission-type-edit-form-schema";

const loader = async ({
  params: { submissionTypeId: sId },
}: LoaderFunctionArgs) => {
  if (!sId) return redirect("/dashboard/settings");

  const submissionTypeId = Number(sId);

  const queryOpts = getSubmissionTypeLayoutQueryOptions(submissionTypeId);

  const { submissionType } = await fetchQueryData(queryOpts);

  if (!submissionType) return redirect("/dashboard/settings");

  return { submissionType };
};

export const SubmissionTypeEditLayout = () => {
  const { submissionTypeId } = useParams();

  const queryOptions = getSubmissionTypeLayoutQueryOptions(
    Number(submissionTypeId),
  );

  const {
    data: { submissionType },
  } = useSuspenseQuery(queryOptions);

  if (!submissionType) return null;

  const { name, icon, outputColumns } = submissionType;

  const methods = useForm<z.infer<typeof submissionTypeEditFormSchema>>({
    defaultValues: {
      name,
      icon,
      outputColumns,
    },
    resolver: zodResolver(submissionTypeEditFormSchema),
  });

  const { mutate } = useGraphqlMutation({
    mutation,
    onSuccess({ updateOneSubmissionType }, variables, context) {
      queryClient.invalidateQueries({
        queryKey: queryOptions.queryKey,
      });
      if (proceedBlocker) proceedBlocker();
      if (updateOneSubmissionType) {
        const { name, icon } = updateOneSubmissionType;
        methods.reset({
          name,
          icon,
        });
      }
    },
    onError(error, variables, context) {
      toast.error("Error saving submission type");
      if (resetBlocker) resetBlocker();
    },
  });

  const onSubmit = useCallback<
    SubmitHandler<z.infer<typeof submissionTypeEditFormSchema>>
  >(
    async ({
      name,
      icon,
      outputColumns,
    }: z.infer<typeof submissionTypeEditFormSchema>) => {
      await mutate({
        data: {
          name: {
            set: name,
          },
          icon: {
            set: icon,
          },
        },
        where: {
          id: submissionType.id,
        },
      });
    },
    [mutate, submissionType],
  );

  const {
    state: blockerState,
    reset: resetBlocker,
    proceed: proceedBlocker,
  } = useBlocker(
    ({ currentLocation, nextLocation }) =>
      methods.formState.isDirty &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <FormProvider {...methods}>
      <form
        className="grid max-h-screen grid-rows-[auto_1fr] gap-4 overflow-hidden px-7 py-6 pb-0"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SubmissionTypeEditHeader />
        <Outlet />

        <AlertDialog open={blockerState === "blocked"}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to leave?
              </AlertDialogTitle>
              <AlertDialogDescription>
                You have unsaved changes. Are you sure you want to leave?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  if (resetBlocker) resetBlocker();
                }}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                type="submit"
                onClick={() => {
                  {
                    /* 
                    This it doesn't behave as a form submit without explicitly calling the submit handler.
                    Possibly because the React Portal render is outside of the form context.
                  */
                  }
                  methods.handleSubmit(onSubmit)();
                }}
              >
                Save and Leave
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </form>
    </FormProvider>
  );
};

SubmissionTypeEditLayout.loader = loader;
