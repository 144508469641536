import { graphql } from "@/gql";

export const submissionTypeEditMutation = graphql(`
  mutation UpdateSubmissionTypeHeader(
    $data: SubmissionTypeUpdateInput!
    $where: SubmissionTypeWhereUniqueInput!
  ) {
    updateOneSubmissionType(data: $data, where: $where) {
      id
      name
      icon
      outputColumns {
        name
        dataType
        description
      }
    }
  }
`);
