import { CustomNoRowsOverlayProps } from "ag-grid-react";

import { Icons } from "@/components/Icons";

export const CustomNoRowsOverlay = (
  props: CustomNoRowsOverlayProps & { loadingMessage: string },
) => {
  return (
    <div className="" role="presentation">
      <div role="presentation" className="mx-auto h-40 w-40">
        <Icons.globe className="h-full w-full text-muted-foreground " />
      </div>
      <div aria-live="polite" aria-atomic="true" className="mt-4">
        {props.loadingMessage}
      </div>
    </div>
  );
};
