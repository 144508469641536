import { useGetProductTypeaheadItems } from "../product-typeahead/useGetProductTypeaheadItems";
import {
  FilterOperationOptions,
  FilterOption,
  TableFilterProvider,
} from "../table/TableFiltersContext";
import { ProductsTable } from "./ProductsTable";
import { useGetProductsSearchVarsFromActiveFilters } from "./useGetProductsSearchVarsFromActiveFilters";

const productFilterOptions: FilterOption[] = [
  {
    label: "Manufacturer",
    searchField: "manufacturer",
    selectValue: "manufacturer",
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Category",
    searchField: "category",
    selectValue: "category",
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Family",
    searchField: "searchFamily",
    selectValue: "family",
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Line",
    searchField: "searchLine",
    selectValue: "line",
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Model",
    searchField: "model",
    selectValue: "model",
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Manufacturer Part Number",
    searchField: "manufacturerPartNumbers",
    selectValue: "manufacturerPartNumber",
    validFilterOperations: [FilterOperationOptions.IN],
  },
];

export const ProductsTableWrapper = () => {
  return (
    <TableFilterProvider
      getSearchVars={useGetProductsSearchVarsFromActiveFilters}
      filterOptions={productFilterOptions}
      useTypeaheadHook={useGetProductTypeaheadItems}
    >
      <ProductsTable />
    </TableFilterProvider>
  );
};
