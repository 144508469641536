import { DataStewardshipSettings } from "./DataStewardshipSettings";
import { VendorDataSettings } from "./VendorDataSettings";

export const AutomationSettings = () => {
  return (
    <div className="space-y-2">
      <VendorDataSettings />
      <DataStewardshipSettings />
    </div>
  );
};
