import { graphql } from "@/gql";
import { VendorWhereUniqueInput } from "@/gql/graphql";

import { useGraphqlQuery } from "../graphql";

export const useVendorSubmissionTypesQuery = ({
  id,
}: VendorWhereUniqueInput) => {
  return useGraphqlQuery({
    queryKey: ["VendorSubmissionTypes", `vendorId=${id}`],
    query: graphql(`
      query VendorSubmissionTypes($where: VendorWhereUniqueInput!) {
        vendor(where: $where) {
          normalizeMappings {
            id
            submissionType {
              id
              name
              outputColumns {
                name
                dataType
                description
              }
              updatedAt
            }
            mapping
            examples
          }
        }
      }
    `),
    variables: { where: { id: Number(id) } },
  });
};
