import { graphql } from "@/gql";
import { createQueryOptions } from "@/lib/hooks/graphql";

type Props = {
  exampleRows: any[];
  outputColumns: any[];
  inputColumns: string[];
};

export const getInferNormalizeMappingQueryOptions = ({
  exampleRows,
  outputColumns,
  inputColumns,
}: Props) => {
  return createQueryOptions({
    query: graphql(`
      query InferNormalizeMapping(
        $exampleRows: [JSONObject!]!
        $outputColumns: [OutputColumnDefinitionInput!]!
        $inputColumns: [String!]!
      ) {
        inferNormalizeMapping(
          exampleRows: $exampleRows
          outputColumns: $outputColumns
          inputColumns: $inputColumns
        ) {
          mapping
        }
      }
    `),
    variables: {
      exampleRows,
      outputColumns,
      inputColumns,
    },
  });
};
