import { addDays, format, startOfToday } from "date-fns";
import { DateRange } from "react-day-picker";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "@/lib/cn";

export enum TimePeriod {
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
  CUSTOM = "Custom Period",
}
type Props = {
  className?: string;
  setDateRangeType: (value: TimePeriod) => void;
  setDateRange: (date: DateRange) => void;
  dateRangeType: TimePeriod;
  dateRange: DateRange;
};

export function DatePickerWithRange({
  className = "",
  setDateRangeType,
  setDateRange,
  dateRangeType,
  dateRange,
}: Props) {
  const onSelect = (date: DateRange | undefined) => {
    if (date) {
      setDateRange(date);
    }
  };

  const onToggleSelect = (value: TimePeriod) => {
    setDateRangeType(value);

    let endDate = startOfToday();
    let startDate = addDays(endDate, -7);

    if (value === TimePeriod.MONTH) {
      startDate = addDays(endDate, -30);
    } else if (value === TimePeriod.YEAR) {
      startDate = addDays(endDate, -365);
    }

    setDateRange({ from: startDate, to: endDate });
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        {/* <PopoverTrigger asChild> */}
        <Button
          id="date"
          variant={"outline"}
          className={cn(
            "w-[300px] justify-start text-left font-normal",
            !dateRange && "text-muted-foreground",
          )}
        >
          <Icons.calendar className="mr-2 h-4 w-4" />
          {dateRange?.from ? (
            dateRange.to ? (
              <>
                {format(dateRange.from, "LLL dd, y")} -{" "}
                {format(dateRange.to, "LLL dd, y")}
              </>
            ) : (
              format(dateRange.from, "LLL dd, y")
            )
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
        {/* </PopoverTrigger> */}
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={onSelect}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>

      <ToggleGroup
        type="single"
        className="rounded-md border"
        defaultValue={dateRangeType}
        value={dateRangeType}
        onValueChange={onToggleSelect}
      >
        <ToggleGroupItem
          value={TimePeriod.WEEK}
          aria-label="Toggle week"
          className="data-[state=on]:text-primary-background w-full text-sm font-normal  data-[state=on]:bg-transparent data-[state=on]:underline"
        >
          {TimePeriod.WEEK}
        </ToggleGroupItem>
        <ToggleGroupItem
          className="data-[state=on]:text-primary-background w-full text-sm font-normal  data-[state=on]:bg-transparent data-[state=on]:underline"
          value={TimePeriod.MONTH}
          aria-label="Toggle month"
        >
          {TimePeriod.MONTH}
        </ToggleGroupItem>
        <ToggleGroupItem
          className="data-[state=on]:text-primary-background w-full text-sm font-normal  data-[state=on]:bg-transparent data-[state=on]:underline"
          value={TimePeriod.YEAR}
          aria-label="Toggle year"
        >
          {TimePeriod.YEAR}
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
}
