import { RowClickedEvent } from "ag-grid-community";
import { useNavigate, useParams } from "react-router-dom";

import { SubmissionsTableWrapper } from "@/components/shared-components/submissions-table/SubmissionsTableWrapper";
import { SubmissionStatus } from "@/gql/graphql";
import { SubmissionsColumnHeaders } from "@/components/shared-components/submissions-table/getSubmissionsTableRows";

export const VendorSummaryContent = () => {
  const { vendorId } = useParams();

  const navigate = useNavigate();
  const onRowClick = (params: RowClickedEvent) => {
    const submissionId = params.data.Id;
    navigate(`/dashboard/submissions/${submissionId}`);
  };

  return (
    <div>
      <h2>Recent Submissions</h2>
      {vendorId ? (
        <SubmissionsTableWrapper
          vendorId={Number(vendorId)}
          customHeight="h-[650px]"
          onRowClick={onRowClick}
          validStatuses={[SubmissionStatus.Processed]}
          columnHeaders={[
            SubmissionsColumnHeaders.PARTNER,
            SubmissionsColumnHeaders.TYPE,
            SubmissionsColumnHeaders.SUBMISSION_PERIOD,
            SubmissionsColumnHeaders.STATUS,
            SubmissionsColumnHeaders.FILE_NAME,
            SubmissionsColumnHeaders.DOWNLOAD,
          ]}
        />
      ) : (
        <div>error finding vendor</div>
      )}
    </div>
  );
};
