import { NameFilter } from "../../table/NameFilter";
import { useTableFilterContext } from "../../table/TableFiltersContext";
import { useGetSubmissionsNameTypeahead } from "./useGetSubmissionsNameTypeahead";

type Props = {
  vendorId?: number;
};

export const SubmissionsNameFilter = ({ vendorId }: Props) => {
  const { searchNameFilter } = useTableFilterContext();
  const { data } = useGetSubmissionsNameTypeahead({
    vendorId,
    typeahead: searchNameFilter,
  });

  const names =
    data?.submissions?.map((submission) => ({
      label: submission.file.name,
      value: submission.file.name,
    })) ?? [];
  return (
    <NameFilter
      width={72}
      typeaheadItems={names}
      placeholder="Search submission file names..."
    />
  );
};
