import { Auth0Provider } from "@auth0/auth0-react";

import { env } from "@/env";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return (
    <Auth0Provider
      domain={env.VITE_PUBLIC_AUTH0_DOMAIN}
      clientId={env.VITE_PUBLIC_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: env.VITE_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/dashboard`,
        // organization: env.VITE_PUBLIC_AUTH0_ORG_ID,
      }}
    >
      {children}
    </Auth0Provider>
  );
}
