import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { getAllProductsSold } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/getAllProductsSold";
import { getSummaryDataQueryOptions } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/getSummaryDataQueryOptions";
import { SubmissionChartCard } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/SubmissionChartCard";
import { SubmissionChartCardSkeleton } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/SubmissionChartCardSkeleton";
import { SubmissionProductsTable } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/SubmissionProductsTable";
import { SubmissionSummaryCard } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/SubmissionSummaryCard";
import { SubmissionSummaryCardSkeleton } from "../../app/vendor-submit/:vendorId/submission/:submissionId/summary/SubmissionSummaryCardSkeleton";
import { ValidationExcelTableSkeleton } from "./validation-excel-table/ValidationExcelTableSkeleton";

export enum LabelNameEnum {
  CPUName = "CPU Line",
  GPUName = "GPU Line",
}

export const SummaryPageAnalytics = () => {
  const { submissionId: sId } = useParams();
  const submissionId = Number(sId);

  const { data, isLoading: isSummaryDataLoading } = useQuery(
    getSummaryDataQueryOptions(submissionId),
  );

  if (isSummaryDataLoading) {
    return (
      <div className="grid w-full grid-cols-2 grid-rows-[375px_1fr] gap-6">
        <SubmissionSummaryCardSkeleton />
        <SubmissionChartCardSkeleton />
        <div className="col-span-2 rounded-lg border">
          <ValidationExcelTableSkeleton />
        </div>
      </div>
    );
  }

  const submission = data?.submission;
  const transformations = data?.submission?.transformations;

  const productSummaryData = getAllProductsSold({
    transformations: transformations ?? [],
  });

  const submissionWeek = submission?.file.createdAt
    ? format(new Date(submission?.file.createdAt), "w")
    : "No Submission Period Found";
  const fileName = submission?.file.name;

  const productTableData = [];
  const { validCPUs, validGPUs } = productSummaryData;
  for (const cpu of validCPUs) {
    const cpuProductName = cpu.labels.find(
      (label) => label.name === LabelNameEnum.CPUName,
    )?.value;
    if (cpuProductName !== undefined && cpuProductName !== "NULL") {
      // search for the product name in the cpuData array
      const productIndex = productTableData.findIndex(
        (product) => product.name === cpuProductName,
      );
      const randomRebate = Math.floor(Math.random() * 10);

      if (productIndex === -1) {
        productTableData.push({
          name: cpuProductName,
          id: cpu.id,
          type: "CPU",
          quantity: 1,
          rebate: randomRebate,
        });
      } else {
        productTableData[productIndex].quantity += 1;
        productTableData[productIndex].rebate += randomRebate;
      }
    }
  }
  for (const gpu of validGPUs) {
    const gpuProductName = gpu.labels.find(
      (label) => label.name === LabelNameEnum.GPUName,
    )?.value;

    if (gpuProductName !== undefined && gpuProductName !== "NULL") {
      // search for the product name in the gpuData array
      const productIndex = productTableData.findIndex(
        (product) => product.name === gpuProductName,
      );
      const randomRebate = Math.floor(Math.random() * 100);
      if (productIndex === -1) {
        productTableData.push({
          name: gpuProductName,
          id: gpu.id,
          type: "GPU",
          quantity: 1,
          rebate: randomRebate,
        });
      } else {
        productTableData[productIndex].quantity += 1;
        productTableData[productIndex].rebate += randomRebate;
      }
    }
  }

  return (
    <div className="grid w-full grid-cols-2 grid-rows-[400px_1fr] gap-6">
      <SubmissionSummaryCard
        submissionWeek={submissionWeek}
        fileName={fileName ?? "No File Name"}
        productSummaryData={productSummaryData}
      />

      <SubmissionChartCard productSummaryData={productSummaryData} />

      <SubmissionProductsTable productData={productTableData} />
    </div>
  );
};
