import { getLabelValue } from "@/lib/getLabelValue";

import { normalizeLabelToProductTaxonomy } from "./LabelToProductTaxonomyMapping";
import { useGetLabelTypeahead } from "./useGetLabelTypeahead";
import { useGetProductTypeahead } from "./useGetProductTypeahead";

type Props = {
  labelName: string;
  typeaheadValue: string;
};

export const useGetTypeaheadItems = ({ labelName, typeaheadValue }: Props) => {
  const normalizedLabelName = normalizeLabelToProductTaxonomy(labelName);

  const { data: productData, isFetching } = useGetProductTypeahead({
    searchField: normalizedLabelName,
    typeahead: typeaheadValue,
  });

  const { data: labelData, isFetching: isFetchingLabels } =
    useGetLabelTypeahead({
      labelName,
      typeahead: typeaheadValue,
    });

  const labelItems = (labelData?.labels ?? []).map((label) =>
    getLabelValue(label),
  );

  const productItems = (productData?.products ?? [])
    // @ts-expect-error - TS doesn't know that normalizedLabelName is the fields of Products
    .filter((item) => item[normalizedLabelName])
    .map((item) => {
      // @ts-expect-error - TS doesn't know that labelName is a key of Product
      return item[normalizedLabelName];
    });

  const combinedItems =
    productItems.length >= 20 ? productItems : [...productItems, ...labelItems];

  // Normalize case to handle differences in uppercase and lowercase
  const uniqueTypeaheadItems = Array.from(
    new Set(combinedItems.map((item) => item.toLowerCase())),
  ).map((item) => item.toLocaleUpperCase());

  return {
    typeaheadItems: uniqueTypeaheadItems,
    isFetching: isFetching || isFetchingLabels,
  };
};
