import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Worksheet } from "exceljs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { match, P } from "ts-pattern";

import { useUpdateNormalizeMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useUpdateNormalizeMapping";
import { CreateSubmissionMutation } from "@/gql/graphql";
import { useProcessSubmission } from "@/lib/hooks/mutations/useProcessSubmission";
import { OrgSubmissionType } from "@/lib/hooks/queries/SubmissionTypes";

import { NormalizeVendorMapping } from "../home/useGetNormalizeVendorMappings";
import { useUploadFileAndCreateSubmission } from "../home/useUploadFileAndCreateSubmission";
import { Header } from "../submission/:submissionId/Header";
import { NormalizeMapping } from "../submission/:submissionId/normalize/NormalizeMapping";
import { getInferNormalizeMappingQueryOptions } from "./getInferNormalizeMappingQueryOptions";
import { useCreateNormalizeMapping } from "./useCreateNormalizeMapping";
import Rive from "@rive-app/react-canvas-lite";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

type LocationState = {
  worksheet: Worksheet | undefined;
  normalizeMapping: NormalizeVendorMapping | undefined;
  submissionType: OrgSubmissionType | undefined;
  file: File | undefined;
};

const AIAnim = () => <Rive src="/ai_extract.riv" />;

export const ConfirmMappingPage = () => {
  const { mutate: updateMapping, isPending: isUpdatePending } =
    useUpdateNormalizeMapping();
  const { mutate: createMapping, isPending: isCreatePending } =
    useCreateNormalizeMapping();
  const { mutate: uploadFileAndCreateSubmission } =
    useUploadFileAndCreateSubmission();
  const { mutate: processSubmission } = useProcessSubmission();

  const navigate = useNavigate();
  const { vendorId: vId } = useParams();
  const vendorId = Number(vId);
  const location = useLocation();

  const { worksheet, normalizeMapping, submissionType, file } =
    (location.state || {}) as LocationState;
  const typedMapping = normalizeMapping?.mapping as
    | Record<string, string>
    | undefined;
  const targetHeaders = (submissionType?.outputColumns ?? []).map(
    (col) => col.name,
  );

  const type = normalizeMapping == undefined ? "NEW" : "EDIT";

  const inputFileHeaders =
    useMemo(() => {
      if (worksheet && worksheet?.getRow) {
        const row = worksheet.getRow(1).values;

        return match(row)
          .with(P.array(), (cells) => {
            return cells.slice(1) as string[];
          })
          .otherwise(() => {
            console.error("Invalid row values");
            // throw new Error("Invalid row values");
          });
      }
    }, [worksheet]) ?? ([] as string[]);

  // get the first 3 rows of the worksheet after the headesr, start on index 2
  const exampleRows = useMemo(() => {
    const rows = [];
    if (worksheet && worksheet?.getRow) {
      for (let i = 2; i < 5; i++) {
        const row = worksheet.getRow(i).values;
        const rowVals = match(row)
          .with(P.array(), (cells) => {
            return cells.slice(1);
          })
          .otherwise(() => {
            throw new Error("Invalid row values");
          }) as string[];
        const rowObj = rowVals.reduce(
          (acc, val, i) => {
            acc[inputFileHeaders[i]] = val;
            return acc;
          },
          {} as Record<string, string>,
        );
        rows.push(rowObj);
      }
    }
    return rows;
  }, [worksheet]);

  const queryOptions = getInferNormalizeMappingQueryOptions({
    exampleRows,
    outputColumns: (submissionType?.outputColumns ?? []).map((col) => ({
      name: col.name,
      guidelines: col.description,
    })),
    inputColumns: inputFileHeaders,
  });
  const { data: inferredMappingData, isFetching } = useQuery(queryOptions);

  const inferredMapping = inferredMappingData?.inferNormalizeMapping.mapping;
  // the initial mapping should be the source headers from the input file
  // and the target headers from the submission type's current mapping
  //   if there is no mapping we should use the inferred mapping
  const initialMapping = inputFileHeaders.map((sourceHeader) => {
    const targetHeader = typedMapping?.[sourceHeader]
      ? typedMapping?.[sourceHeader]
      : inferredMapping?.[sourceHeader];
    return {
      sourceHeader,
      targetHeader: targetHeader ?? "unknown",
    };
  });

  const onMappingSuccess = () => {
    if (submissionType != null && file != null) {
      uploadFileAndCreateSubmission(
        { file, submissionTypeId: submissionType.id },
        {
          onSuccess: (submissionData: CreateSubmissionMutation) => {
            const submissionId = submissionData.createSubmission.id;
            processSubmission(
              { submissionId },
              {
                onSuccess: (data) => {
                  navigate(
                    `/vendor-submit/${vendorId}/submission/${submissionId}`,
                  );
                },
                onError: (error) => {
                  console.error("onProcessSubmission error", error);
                },
              },
            );
          },
          onError: (error) => {
            console.error("onUpload error", error);
          },
        },
      );
    }
  };

  const onSaveMapping = (mapping: Record<string, string>) => {
    // determine if we are updating a mapping or creating a new one
    if (typedMapping && normalizeMapping?.id) {
      // update the existing mapping
      updateMapping(
        {
          data: {
            mapping,
          },
          where: {
            id: normalizeMapping.id,
          },
        },
        {
          onSuccess: () => {
            // start processing submission
            onMappingSuccess();
          },
          onError: (error) => {
            console.error(`Failed to update mapping: ${error.message}`);
          },
        },
      );
    } else {
      // create a new mapping
      if (submissionType != null) {
        createMapping(
          {
            data: {
              examples: exampleRows,
              mapping,
              submissionType: {
                connect: {
                  id: submissionType.id,
                },
              },
              vendor: {
                connect: {
                  id: vendorId,
                },
              },
            },
          },
          {
            onSuccess: () => {
              // start processing submission
              onMappingSuccess();
            },
            onError: (error) => {
              console.error(`Failed to create mapping: ${error.message}`);
            },
          },
        );
      }
    }
  };

  useEffect(() => {
    if (!worksheet || !submissionType || !file) {
      navigate(`/vendor-submit/${vendorId}`);
    }
  }, [worksheet, submissionType, file, navigate, vendorId]);

  const title =
    type === "NEW" ? "Create First Mapping" : "Edit Existing Mapping";
  const description =
    type === "NEW" ? (
      <span>
        {`No mapping currently exists for this submission type. Please review and
      confirm the suggested mapping for submission type, `}
        <span className=" text-bold text-primary/90 underline">
          {submissionType?.name}
        </span>
        {`, below to continue. This mapping will be saved for all future submissions of this type.`}
      </span>
    ) : (
      <span>
        {`We've detected a new data structure in your file. Please review and
      confirm the mapping for submission type, `}
        <span className=" text-bold text-primary/90 underline">
          {submissionType?.name}
        </span>
        {`, below to continue.`}
      </span>
    );

  return (
    <div className="grid max-h-screen min-h-screen grid-rows-[auto_1fr] content-stretch">
      <Header title={title} description={description} className="py-6 " />
      {isFetching ? (
        <AIAnim />
      ) : (
        <div className="grid content-stretch overflow-hidden">
          {worksheet != null ? (
            <NormalizeMapping
              showBackButton={true}
              worksheet={worksheet}
              targetHeaders={targetHeaders}
              initialMapping={initialMapping}
              buttonText={
                typedMapping
                  ? "Save & Process Submission"
                  : "Create & Process Submission"
              }
              onSaveMapping={onSaveMapping}
              isPending={isUpdatePending || isCreatePending}
            />
          ) : (
            <AlertDialog open={true}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Uploaded File Error</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogDescription>
                  There was some error with the uploaded file, please go back
                  and try again.
                </AlertDialogDescription>
                <AlertDialogFooter>
                  <AlertDialogAction>
                    <Link to={`/vendor-submit/${vendorId}`}>Go Back</Link>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      )}
    </div>
  );
};
