import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";

import { Icons } from "@/components/Icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/cn";

import { useGetTypeaheadItems } from "./useGetTypeaheadItems";

type Props = {
  labelName: string;
  currValue: string;
  handleSubmitFeedback: (content: string) => void;
};

export function ProductTypeahead({
  currValue,
  handleSubmitFeedback,
  labelName,
}: Props) {
  const [typeaheadValue, setTypeaheadValue] = useState("");
  const { typeaheadItems, isFetching } = useGetTypeaheadItems({
    labelName,
    typeaheadValue,
  });

  return (
    <Popover defaultOpen={true}>
      <PopoverTrigger asChild>
        <div className={"group relative flex h-full w-full flex-1 @container"}>
          <div
            className={cn(
              "duration-400 flex h-full w-full max-w-prose cursor-text items-start justify-start rounded-none border-2 border-blue-100 px-2 py-2.5 text-start text-sm tabular-nums transition-colors",
            )}
          >
            {currValue}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput
            showIcon={false}
            placeholder="Input new value..."
            className="h-9"
            onInput={(e) => {
              setTypeaheadValue(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                if (typeaheadValue) {
                  handleSubmitFeedback(typeaheadValue);
                }
              }
            }}
          />
          {isFetching ? (
            <CommandEmpty className="roundedshadow mt-4 h-12 animate-pulse bg-muted/30">
              <div className="flex h-full items-center justify-center">
                <ThreeDots color="#a1a1aa" height={50} width={50} />
              </div>
            </CommandEmpty>
          ) : (
            <CommandEmpty>No products found.</CommandEmpty>
          )}
          <CommandGroup className="max-h-[250px] overflow-auto">
            {typeaheadItems.map((item, i) => (
              <CommandItem
                key={i}
                value={item}
                onSelect={(selectValue) => {
                  if (selectValue) {
                    handleSubmitFeedback(selectValue.toLocaleUpperCase());
                  }
                }}
              >
                {item}
                <Icons.check
                  className={cn(
                    "ml-auto h-4 w-4",
                    typeaheadValue === item ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
