import { Handle, HandleProps } from "reactflow";

import { cn } from "@/lib/cn";

interface DataHandleProps<T>
  extends Omit<HandleProps, "isValidConnection" | "id">,
    React.HTMLAttributes<HTMLDivElement> {
  dataSchemaKey: string;
}

export const DataHandle = <T,>({
  dataSchemaKey,
  className,
  children,
  ...props
}: DataHandleProps<T>) => {
  return (
    <Handle
      id={dataSchemaKey}
      className={cn(
        "h-5 w-5 cursor-grab  transition-colors hover:border-border hover:bg-primary/50",
        className,
      )}
      {...props}
    >
      {children}
    </Handle>
  );
};
