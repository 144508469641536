// index.tsx
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { Main } from "./main";
import { router } from "./router";

const rootEl = document.getElementById("root");

if (rootEl) {
  ReactDOM.createRoot(rootEl).render(
    <Main routerProvider={<RouterProvider router={router} />} />,
  );
}
