import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
// import { useParams } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";

// import { toast } from "sonner";
// import { graphql } from "@/gql";
// import { useGraphqlMutation } from "@/lib/hooks/graphql";

const memberInviteFormSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
  role: z.enum(["admin", "member"]),
});

type memberInviteFormValues = z.infer<typeof memberInviteFormSchema>;

export const MemberInviteForm = () => {
  // const { organizationId } = useParams();
  //   const { mutate, isPending } = useGraphqlMutation({
  //     mutation: graphql(`
  //
  //   `),
  //     onSuccess: () => {
  //       toast({
  //         title: "Success",
  //         description: `Member invited to organization.`,
  //         duration: 2000,
  //       });
  //     },
  //   });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<memberInviteFormValues>({
    resolver: zodResolver(memberInviteFormSchema),
    defaultValues: {
      email: "",
      role: "member",
    },
  });

  const onSubmit: SubmitHandler<memberInviteFormValues> = (data) => {
    alert("mutation not implemented");

    // mutate({
    //   data: {
    //   },
    //   where: {
    //     id: Number(organizationId),
    //   },
    // });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="h-fit max-h-min max-w-lg">
        <CardHeader className="flex gap-2 p-4">
          <CardTitle>Add New Member</CardTitle>
          <CardDescription>
            Invite a new member by email address
          </CardDescription>
        </CardHeader>
        <CardContent className="flex gap-3 p-4 pt-0">
          <div className="relative">
            <Input
              placeholder="jane@example.com"
              className="w-64"
              {...register("email")}
            />
            {errors.email && (
              <p className="absolute -top-6 pl-2 pt-1 text-xs text-red-400/70">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="relative">
            <Select
              onValueChange={(value) => {
                setValue("role", value as memberInviteFormValues["role"]);
              }}
              defaultValue="member"
            >
              <SelectTrigger className="w-[180px] bg-background">
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="member">Member</SelectItem>
              </SelectContent>
            </Select>

            {errors.role && (
              <p className="absolute pl-1 pt-1 text-red-400/70">
                {errors.role.message}
              </p>
            )}
          </div>
        </CardContent>
        <Separator orientation="horizontal" className="mb-4" />
        <CardFooter className="flex justify-end gap-2 px-4 pb-3">
          <Button
            size="sm"
            variant="ghost"
            className="w-32"
            disabled={!isDirty}
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            className="w-32"
            type="submit"
            isLoading={false}
            disabled={!isDirty}
          >
            Invite
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};
