import { toast } from "sonner";

import { useParams } from "react-router-dom";
import { SubmissionTypeIconSelect } from "./submission-type-icon-select";
import { useSuspenseQuery } from "@tanstack/react-query";

import { Input } from "@/components/ui/input";

import { Controller } from "react-hook-form";
import { cn } from "@/lib/cn";

import { getSubmissionTypeLayoutQueryOptions } from "../submission-type-edit-query";
import { Button } from "@/components/ui/button";
import { useSubmissionTypeEditFormContext } from "../submission-type-edit-form-schema";

interface SubmissionTypeEditHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const SubmissionTypeEditHeader = ({
  ...props
}: SubmissionTypeEditHeaderProps) => {
  const { submissionTypeId: sId } = useParams();

  const submissionTypeId = sId ? Number(sId) : undefined;
  if (!submissionTypeId) return null;

  const queryOptions = getSubmissionTypeLayoutQueryOptions(submissionTypeId);
  const {
    data: { submissionType },
  } = useSuspenseQuery(queryOptions);
  if (!submissionType) return null;
  const { control, resetField, formState, reset } =
    useSubmissionTypeEditFormContext();

  return (
    <div
      className="flex justify-between"
      style={{ width: "clamp(500px, 100%, 48rem)" }}
      {...props}
    >
      <div className="flex flex-1 space-x-4">
        <Controller
          control={control}
          name="icon"
          render={({
            field: { onChange, value, onBlur },
            formState: { errors },
          }) => (
            <SubmissionTypeIconSelect
              icon={value}
              onIconSelect={(i) => {
                onChange(i);
                onBlur();

                if (errors.icon) {
                  toast.error(
                    errors?.icon?.message?.toString() ??
                      "Error setting submission type icon",
                  );
                  resetField("icon", { defaultValue: submissionType.icon });
                  return;
                }
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              className={cn(
                "mt-1 h-fit rounded-none border-none p-0 text-lg font-semibold outline-none transition-all focus-visible:border-0 focus-visible:ring-0",
              )}
              {...field}
            />
          )}
        />
      </div>

      <Button
        className="mr-2 w-24"
        disabled={!formState.isDirty}
        onClick={() => {
          reset();
        }}
        variant="outline"
      >
        Discard
      </Button>
      <Button className="w-28" disabled={!formState.isDirty} type="submit">
        Save
      </Button>
    </div>
  );
};
