import { CustomCellRendererProps } from "ag-grid-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export const CustomDescriptionCellRenderer = (
  props: CustomCellRendererProps,
) => {
  return (
    <Popover>
      <PopoverTrigger>{props.value}</PopoverTrigger>
      <PopoverContent align="start">{props.value}</PopoverContent>
    </Popover>
  );
};
