import { Edge } from "reactflow";

import { TABULARMAPPING_EDGE_TYPE } from "./edges";
import { getHeaderNodeId } from "./getHeaderNodeId";
import { SOURCE_HEADER_NODE_TYPE, TARGET_HEADER_NODE_TYPE } from "./nodes";
import { TabularMappingProps } from "./TabularMapping";

export const createInitialEdges = (
  initialMapping: TabularMappingProps["initialMapping"],
): Edge[] => {
  if (!initialMapping) return [];

  return initialMapping.map(({ sourceHeader, targetHeader }) => {
    const sourceNodeId = getHeaderNodeId({
      header: sourceHeader,
      type: SOURCE_HEADER_NODE_TYPE,
    });

    const targetNodeId = getHeaderNodeId({
      header: targetHeader,
      type: TARGET_HEADER_NODE_TYPE,
    });

    return {
      id: `edge-${sourceHeader}-${targetHeader}`,
      type: TABULARMAPPING_EDGE_TYPE,
      source: sourceNodeId,
      target: targetNodeId,
      animated: true,
    } satisfies Edge;
  });
};
