import { useMemo } from "react";
import {
  BaseEdge,
  EdgeProps,
  getBezierPath,
  getConnectedEdges,
  useReactFlow,
} from "reactflow";

import { useTabularMappingStore } from "../store";

// import "./buttonedge.css";

interface Props extends EdgeProps {
  isSpotlighted: boolean;
}

export function TabularMappingEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  animated = true,
  ...edgeProps
}: EdgeProps<Props>) {
  const { getEdges } = useReactFlow();
  const { spotlightedNodes } = useTabularMappingStore();
  const spotlightedEdges = useMemo(() => {
    return getConnectedEdges(spotlightedNodes, getEdges());
  }, [spotlightedNodes, getEdges]);

  const isSpotlighted = useMemo(
    () => spotlightedEdges.some((e) => e.id === id),
    [spotlightedEdges, id],
  );

  const strokeColor = useMemo(() => {
    if (isSpotlighted) return "white";

    return spotlightedEdges.length > 0
      ? "hsl(240 3.8% 26.1%)"
      : "hsl(240 3.8% 56.1%)";
  }, [isSpotlighted, spotlightedEdges]);

  const [edgePath, _labelX, _labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          stroke: strokeColor,
          strokeWidth: isSpotlighted ? 2 : 1,
          transitionProperty:
            "color, background-color, border-color, text-decoration-color, fill, stroke, stroke-width",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "150ms",
        }}
      />
      {/* <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <button className="edgebutton" onClick={onEdgeClick}>
            ×
          </button>
        </div>
      </EdgeLabelRenderer> */}
    </>
  );
}
