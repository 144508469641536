import { graphql } from "@/gql/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const usePrepareFileDownloadMutation = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation PrepareFileDownload($fileId: Float!) {
        prepareFileDownload(fileId: $fileId) {
          signedUrl
        }
      }
    `),
  });
};
