import { Suspense } from "react";
import { LoaderFunctionArgs, RouteObject } from "react-router-dom";
import { z } from "zod";

import { DashboardScreenFallback } from "@/components/DashboardScreenFallback";
import { Icons } from "@/components/Icons";
import { Organization } from "@/gql/graphql";

import { AccountGeneralPage } from "./account/general/AccountGeneralPage";
// import { getQueryOptions as getTransformationTypeListQueryOptions } from "./organization/data-action/TransformationTypeList";
import { ExportPage } from "./organization/export/ExportPage";
import { OrgGeneralPage } from "./organization/general/OrgGeneralPage";
import { MemberEditPage } from "./organization/member/[userId]/MemberEditPage";
import { MembersPage } from "./organization/member/MembersPage";
import { SubmissionTypeEditLayout } from "./organization/submission-type/[submissionTypeId]/submission-type-edit-layout";
import { OrgSubmissionTypePage } from "./organization/submission-type/SubmissionTypesPage";
import { VendorsSettingsPage } from "./organization/vendors/VendorsSettingsPage";
import { SubmissionTypeEditPage } from "./organization/submission-type/[submissionTypeId]/submission-type-edit-page";

export const settingsRoutes = [
  {
    id: "Organization",
    path: "org/:organizationId",
    handle: {
      Icon: Icons.building,
    },
    loader: async ({ params }: LoaderFunctionArgs) => {
      let organizationId: Organization["id"] = Number(params.organizationId);

      try {
        organizationId = z.number().parse(organizationId);
      } catch (error) {
        console.error("Invalid organizationId", error);
        return;
      }

      // const tTLQO = getTransformationTypeListQueryOptions(organizationId);
      // ensureQueryData(tTLQO);

      return null;
    },
    children: [
      {
        id: "Org General",
        handle: {
          name: "General",
          showInNav: true,
        },

        path: "",

        element: <OrgGeneralPage key="org-general" />,
      },
      {
        id: "Vendors",
        handle: {
          name: "Vendors",
          showInNav: true,
        },
        path: "vendors",

        element: <VendorsSettingsPage key="vendors" />,
      },
      {
        id: "Submission Types",
        handle: {
          name: "Submissions",
          showInNav: true,
        },
        path: "submission-type",

        element: <OrgSubmissionTypePage key="submission-types" />,
      },
      {
        id: "Submission Type",
        handle: {
          name: "Submission",
          showInNav: false,
        },
        path: "submission-type/:submissionTypeId",
        element: <SubmissionTypeEditLayout key="submission-type" />,
        loader: SubmissionTypeEditLayout.loader,
        children: [
          {
            id: "Submission Type Page",
            path: "",
            element: <SubmissionTypeEditPage />,
          },
        ],
      },

      // {
      //   id: "Data Actions",
      //   handle: {
      //     name: "Data Actions",
      //     showInNav: true,
      //   },
      //   path: "data-action",
      //   element: <OrgDataActionPage />,
      // },
      // {
      //   id: "Transformation Types",
      //   handle: {
      //     name: "Transformation Types",
      //     showInNav: false,
      //   },
      //   path: "data-action/transformation-type",
      //   element: <TransformationTypesPage />,
      // },
      // {
      //   id: "Transformation Type",
      //   handle: {
      //     name: "Transformation Type",
      //     showInNav: false,
      //   },
      //   loader: loader,
      //   path: "data-action/transformation-type/:transformationTypeId",
      //   element: <TransformationTypeEditPage />,
      // },
      {
        id: "Members",
        handle: {
          name: "Members",
          showInNav: true,
        },
        path: "member",
        element: <MembersPage key="members" />,
      },
      {
        id: "Member",
        handle: {
          name: "Member",
          showInNav: false,
        },
        path: "member/:userId",
        element: (
          <Suspense fallback={<DashboardScreenFallback />}>
            <MemberEditPage key="member" />
          </Suspense>
        ),
      },
      {
        id: "Export",
        handle: {
          name: "Export Data",
          showInNav: true,
        },
        path: "export",
        element: <ExportPage key="export" />,
      },
    ],
  },
  {
    id: "Account",
    path: "account/:userId",
    handle: {
      Icon: Icons.user,
    },
    children: [
      {
        id: "Account General",
        handle: {
          name: "General",
          showInNav: true,
        },
        path: "",
        element: <AccountGeneralPage key="account-general" />,
      },
      // {
      //   id: "Account Security",
      //   name: "Security",
      //   path: "security",
      //   showInNav: true,
      //   element: <AccountSecurityPage />,
      // },
    ],
  },
] satisfies RouteObject[];
