import { useMemo } from "react";
import { CellClassParams, ColDef, ITooltipParams } from "ag-grid-community";

import { ColumnDataType } from "@/gql/graphql";

import { ProductTypeahead } from "../product-typeahead/ProductTypeahead";
import { getHeaderType } from "./getHeaderType";
import {
  TypedOutputColumn,
  TypedOutputColumnTypes,
} from "./useGetTypedOutputColumns";
import { validateCell } from "./validateCell";

type Params = {
  headerValues: string[];
  typedOutputColumns: TypedOutputColumn[];
};

export const useGetValidationExcelTableColDefs = ({
  headerValues,
  typedOutputColumns,
}: Params) => {
  // add metadata to the output columns

  return useMemo((): ColDef[] => {
    if (!headerValues) {
      return [];
    }

    const firstCol = {
      headerName: "Row",
      field: "rowIndex",
      colId: String(0),
      checkboxSelection: true,
      headerCheckboxSelection: true,
      flex: 0.7,
      cellParams: {
        type: TypedOutputColumnTypes.STRING,
        dataType: ColumnDataType.String,
      },
      enableCellChangeFlash: true,
      cellClassRules: {},
      resizable: true,
    };

    const dynamicCols = headerValues.map((header, i) => {
      const { type, dataType } = getHeaderType({ header, typedOutputColumns });

      const standardColDef = {
        headerName: header,
        field: header,
        filter: true, // Enable filtering on all columns
        editable: true, // Enable editing on all columns
        resizable: true,
        // flex: 2,
        enableCellChangeFlash: true,
        maxWidth: 300,
        // The colID is +1 because excel rows are 1-indexed, header 1 is colId 1
        colId: String(i + 1),
        cellParams: {
          type,
          dataType,
        },
        tooltipValueGetter: (p: ITooltipParams) => {
          const validationStatus = validateCell(
            p.value,
            type,
            p.colDef?.headerName ?? "",
          );
          if (validationStatus !== true) {
            return validationStatus;
          }
        },
        cellClassRules: {
          "cell-error": (params: CellClassParams) => {
            const column = params?.colDef?.headerName ?? "";
            if (params.value === "NULL") {
            }
            const validationStatus = validateCell(params.value, type, column);
            // update row error state
            if (validationStatus !== true) {
              return true;
            }
            return false;
          },
        },
      };

      if (type === TypedOutputColumnTypes.SKU) {
        return {
          ...standardColDef,
          flex: 1,
          cellEditor: (p: { value: string }) => {
            return (
              <ProductTypeahead
                labelName={"Product Manufacturer SKU"}
                currValue={p.value}
                handleSubmitFeedback={() => {}}
              />
            );
          },
        };
      } else if (type === TypedOutputColumnTypes.TEXTAREA) {
        return {
          ...standardColDef,
          flex: 2,
          cellEditorPopup: true,
          cellEditor: "agLargeTextCellEditor",
          cellEditorParams: {
            maxLength: 100,
          },
        };
      } else if (type === TypedOutputColumnTypes.DATE) {
        return {
          ...standardColDef,
          cellEditor: "agDateCellEditor",
        };
      } else if (type === TypedOutputColumnTypes.NUMBER) {
        return {
          ...standardColDef,
          type: "numericColumn",
          cellEditor: "agNumberCellEditor",
          cellDataType: "number",
        };
      } else {
        return standardColDef;
      }
    });
    return [firstCol, ...dynamicCols];
  }, [headerValues, typedOutputColumns]);
};
