import { useCallback } from "react";
import { JSONSchemaType } from "ajv";
import { Connection, Node, NodeProps, useReactFlow } from "reactflow";

export interface FunctionProps {
  parameters?: Record<string, JSONSchemaType<any>>;
  outputs?: Record<string, JSONSchemaType<any>>;
}

export type FunctionNodeProps<T> = NodeProps<FunctionProps & T>;
export type FunctionNode<T> = Node<FunctionProps & T>;

export const checkSchemaEquality = (
  schema1: JSONSchemaType<any>,
  schema2: JSONSchemaType<any>,
) => {
  return JSON.stringify(schema1) === JSON.stringify(schema2);
};

export const useIsValidDataConnection = () => {
  const { getNode } = useReactFlow<FunctionProps>();

  const isValidDataConnection = useCallback(
    (connection: Connection) => {
      const {
        source: sourceId,
        target: targetId,
        sourceHandle: sourceDataSchemaKey,
        targetHandle: targetDataSchemaKey,
      } = connection;

      if (
        !sourceId ||
        !targetId ||
        !sourceDataSchemaKey ||
        !targetDataSchemaKey
      ) {
        return false;
      }

      const sourceOutputSchema =
        getNode(sourceId)?.data?.outputs?.[sourceDataSchemaKey];
      const targetInputSchema =
        getNode(targetId)?.data?.parameters?.[targetDataSchemaKey];

      if (!sourceOutputSchema || !targetInputSchema) return false;

      return checkSchemaEquality(sourceOutputSchema, targetInputSchema);
    },
    [getNode],
  );
  return { isValidDataConnection };
};

// const FunctionNode = ({ data, ...props }: NodeProps<FunctionProps>) => {
//   const { parameters, outputs } = data;

//   return (
//     <div>
//       <div>Function Node</div>
//       <div>Parameters</div>
//       <div>
//         {parameters &&
//           Object.entries(parameters).map(([key, value]) => (
//             <div key={key}>
//               <div>{key}</div>
//               <div>{JSON.stringify(value)}</div>
//             </div>
//           ))}
//       </div>
//       <div>Outputs</div>
//       <div>
//         {outputs &&
//           Object.entries(outputs).map(([key, value]) => (
//             <div key={key}>
//               <div>{key}</div>
//               <div>{JSON.stringify(value)}</div>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };
