import { cn } from "@/lib/cn";

type Props = {
  title: string;
  description: string | JSX.Element;
  className?: string;
};

export const Header = ({ title, description, className }: Props) => {
  return (
    <header className={cn("flex flex-col items-center gap-2", className)}>
      <h1 className="text-center text-3xl font-semibold">{title}</h1>
      <h2 className="max-w-prose text-center text-sm text-muted-foreground">
        {description}
      </h2>
    </header>
  );
};
