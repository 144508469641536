import { useRevalidator } from "react-router-dom";

import { useProcessSubmission } from "@/lib/hooks/mutations/useProcessSubmission";
import { useResetSubmission } from "@/lib/hooks/mutations/useResetSubmission";

type Params = {
  submissionId: number;
};

export const useReprocessSubmission = ({ submissionId }: Params) => {
  const revalidator = useRevalidator();

  const { mutate: resetSubmission, isPending: isResetPending } =
    useResetSubmission();
  const { mutate: processSubmission, isPending: isProcessPending } =
    useProcessSubmission();
  const reprocessSubmission = () => {
    // call resetSubmission
    resetSubmission(
      { submissionId },
      {
        onSuccess: () => {
          processSubmission(
            { submissionId },
            {
              onSuccess: () => {
                revalidator.revalidate();
              },
            },
          );
        },
      },
    );
  };

  const isPending = isResetPending || isProcessPending;

  return {
    reprocessSubmission,
    isPending,
  };
};
