import { NavLink } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { SubmissionTypesQuery } from "@/gql/graphql";
import { SubmissionTypeIcon } from "./SubmissionTypeIcon";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/cn";

type Props = {
  submissionType: SubmissionTypesQuery["submissionTypes"][number];
  isDisabled?: boolean;
};

export const SubmissionTypeItem = ({
  submissionType,
  isDisabled = false,
}: Props) => {
  const { id, type, description } = submissionType;
  const href = `mapping/${type}`;

  const Wrapper: React.ElementType = isDisabled ? "div" : NavLink;
  const wrapperProps = isDisabled ? {} : { to: href };

  return (
    <Wrapper {...wrapperProps}>
      <li
        key={id}
        className={cn(
          "col-span-1 flex w-56  rounded-md ",
          `${
            isDisabled
              ? "bg-primary/10"
              : "cursor-pointer ring-white hover:ring-1 "
          }`,
        )}
      >
        <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md border text-sm font-medium ">
          <SubmissionTypeIcon submissionTypeId={id} />
        </div>
        <div className="relative flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t">
          <div className="flex-1 truncate px-4 py-2 text-sm">
            <p>{type}</p>
          </div>
          <div className="flex-shrink-0 pr-2">
            <Tooltip>
              <TooltipTrigger>
                <Icons.information className="h-5 w-5" aria-hidden="true" />
              </TooltipTrigger>
              <TooltipContent>{description}</TooltipContent>
            </Tooltip>
          </div>
        </div>
      </li>
    </Wrapper>
  );
};
