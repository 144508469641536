import React, { memo, useEffect, useMemo } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { format } from "date-fns";
import { CircleAlert, Dot } from "lucide-react";
import { useMeasure } from "react-use";
import { Position, useUpdateNodeInternals } from "reactflow";
import { match, P } from "ts-pattern";

import { MonoText } from "@/components/MonoText";
import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";

import {
  SOURCE_HEADER_NODE_TYPE,
  tabularMappingNodeTypes,
  TARGET_HEADER_NODE_TYPE,
} from ".";
import { DataHandle } from "../../functional/DataHandle";
import {
  FunctionNode,
  FunctionNodeProps,
} from "../../functional/nodes/FunctionNode";
import { useTabularMappingStore } from "../store";
import { TabularMappingProps } from "../TabularMapping";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  data: any[];
  orientation: TabularMappingProps["orientation"];
  // isSpotlighted: boolean;
  // someNodesSpotlighted: boolean;
}

export type HeaderNode = FunctionNode<Props>;

function _HeaderNode({
  data: { parameters, outputs, className, orientation, header, data, ...props },
  ...nodeProps
}: FunctionNodeProps<Props>) {
  const type = nodeProps.type as keyof typeof tabularMappingNodeTypes;

  const [measureRef, { height, width }] = useMeasure<HTMLDivElement>();

  const [animParent] = useAutoAnimate({
    easing: easeSnappy,
    duration: 150,
  });
  const updateNodeInternals = useUpdateNodeInternals();

  const nodeHandlePosition = useMemo(
    () =>
      match({
        orientation,
        type,
      })
        .with(
          { orientation: "horizontal", type: SOURCE_HEADER_NODE_TYPE },
          () => Position.Right,
        )
        .with(
          { orientation: "vertical", type: SOURCE_HEADER_NODE_TYPE },
          () => Position.Bottom,
        )
        .with(
          { orientation: "horizontal", type: TARGET_HEADER_NODE_TYPE },
          () => Position.Left,
        )
        .with(
          { orientation: "vertical", type: TARGET_HEADER_NODE_TYPE },
          () => Position.Top,
        )
        .exhaustive(),
    [orientation, type],
  );

  const { spotlightedNodes } = useTabularMappingStore();
  const isSpotlighted = useMemo(
    () =>
      spotlightedNodes.find((node) => node.id === nodeProps.id) !== undefined,
    [nodeProps, spotlightedNodes],
  );
  const someNodesSpotlighted = useMemo(
    () => spotlightedNodes.length > 0,
    [spotlightedNodes],
  );

  const outputKeys = useMemo(() => {
    if (!outputs) return [];
    return Object.keys(outputs);
  }, [outputs]);

  const parametersKeys = useMemo(() => {
    if (!parameters) return [];
    return Object.keys(parameters);
  }, [parameters]);

  // necessary to change the handle position https://reactflow.dev/api-reference/hooks/use-update-node-internals
  useEffect(() => {
    updateNodeInternals(nodeProps.id);
  }, [nodeHandlePosition]);

  useEffect(() => {
    match(type)
      .with(SOURCE_HEADER_NODE_TYPE, () => {
        if (!outputs)
          throw new Error("Outputs are required for SourceHeaderNode");
      })
      .with(TARGET_HEADER_NODE_TYPE, () => {
        if (!parameters)
          throw new Error("Parameters are required for TargetHeaderNode");
      })
      .exhaustive();
  }, [type, outputs, parameters]);

  const headerTextSize = useMemo(() => {
    if (width < 75 || height < 75) return "text-[10px]";
    if (width < 90 || height < 90) return "text-[12px]";
    if (width < 125 || height < 125) return "text-[13px]";
    if (width < 150 || height < 150) return "text-[14px]";
    if (width < 175 || height < 175) return "text-[15px]";
    if (width < 200 || height < 200) return "text-[16px]";
    return "text-[17px]";
  }, [width, height]);

  const headerPadding = useMemo(() => {
    if (width < 125 || height < 125) return "p-1 pb-0.5";
    return "p-2";
  }, [width, height]);

  const dataTextSize = useMemo(() => {
    if (width < 90 || height < 90) return "text-[10px]";
    if (width < 125 || height < 125) return "text-[11px]";
    if (width < 150 || height < 150) return "text-[12px]";
    if (width < 175 || height < 175) return "text-[13px]";
    if (width < 200 || height < 200) return "text-[14px]";
    return "text-[15px]";
  }, [width, height]);

  const dataPadding = useMemo(() => {
    if (width < 175 || height < 175) return "p-1";
    return "p-1.5";
  }, [width, height]);

  return (
    <div
      id={nodeProps.id}
      tabIndex={0}
      className={cn("group @container")}
      style={{ width: props.style?.width, height: props.style?.height }}
      {...props}
    >
      {outputs &&
        outputKeys.map((outputKey) => (
          <DataHandle
            key={"output-" + outputKey}
            className={cn(
              "h-full w-full rounded-none border-transparent bg-transparent hover:border-transparent hover:bg-transparent",
            )}
            dataSchemaKey={outputKey}
            type={"source"}
            position={nodeHandlePosition}
          />
        ))}

      {parameters &&
        parametersKeys.map((paramKey) => (
          <DataHandle
            key={"parameter-" + paramKey}
            className={cn(
              "h-full w-full rounded-none border-transparent bg-transparent hover:border-transparent hover:bg-transparent",
            )}
            dataSchemaKey={paramKey}
            type={"target"}
            position={nodeHandlePosition}
          />
        ))}

      {/* PRESENTATION LAYER */}
      <div
        ref={measureRef}
        tabIndex={undefined}
        className={cn(
          "grid h-full w-full grid-rows-[auto_1fr] overflow-hidden border border-border bg-muted font-mono text-[hsl(0_0_73)] transition-all", // defaults
          // "group-hover:z-40 group-hover:border-primary group-hover:text-primary group-hover:shadow-lg", // hover
          // "group-focus:z-40 group-focus:border-primary group-focus:text-primary group-focus:shadow-lg", // focus
          isSpotlighted && "z-40 border-primary text-primary shadow-lg", // selected
          !isSpotlighted && someNodesSpotlighted && "opacity-50", // not selected
          className,
          isSpotlighted && "border-l-primary",
          // nodeProps.selected && "bg-red-400",
        )}
        style={props.style}
        {...props}
      >
        <div className={cn(headerTextSize, headerPadding)}>{header}</div>

        <MonoText
          className={cn(" self-stretch bg-zinc-900", dataTextSize, dataPadding)}
        >
          <div
            ref={animParent}
            className="pointer-events-none select-none flex-col"
          >
            {data.length > 0 ? (
              <>
                {data.map((d, i) => (
                  <span key={d}>
                    {match(d)
                      .with(P.string, (s) => s)
                      .with(P.number, (n) => n.toString())
                      .with(P.nullish, () => "null")
                      .with(P.any, (val) => {
                        if (val instanceof Date) {
                          return format(val, "MM-dd-yyyy");
                        }
                        return val.toString();
                      })
                      .exhaustive()}
                    {i < data.length - 1 && (
                      <Dot
                        size={20}
                        className="inline-block shrink-0 text-foreground"
                      />
                    )}
                  </span>
                ))}
              </>
            ) : (
              <div className="flex items-center gap-2 uppercase text-status-failed-foreground">
                <CircleAlert className="mb-[1.5px] inline-block" size={13} />
                No data in column
              </div>
            )}
          </div>
        </MonoText>
      </div>
    </div>
  );
}

export const HeaderNode = memo(_HeaderNode);
