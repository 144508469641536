import { graphql } from "@/gql/gql";
import { GetNormalizeVendorMappingsQuery } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

export type NormalizeVendorMapping = NonNullable<
  GetNormalizeVendorMappingsQuery["vendor"]
>["normalizeMappings"][0];

type Props = {
  vendorId: number | undefined;
};

export const useGetNormalizeVendorMapping = ({ vendorId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetNormalizeVendorMapping", `vendorId=${vendorId}`],
    query: graphql(`
      query GetNormalizeVendorMappings($where: VendorWhereUniqueInput!) {
        vendor(where: $where) {
          normalizeMappings {
            id
            mapping
            submissionType {
              outputColumns {
                name
                dataType
                description
              }
              type
              id
            }
          }
        }
      }
    `),
    variables: {
      where: {
        id: vendorId,
      },
    },
  });
};
