import { useCallback, useMemo, useState } from "react";
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { SubmissionStatus } from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { DateRangeFilter } from "../table/DateRangeFilter";
import { Filter } from "../table/Filter";
import {
  FilterOption,
  useTableFilterContext,
} from "../table/TableFiltersContext";
import { TableFiltersPopover } from "../table/TableFiltersPopover";
import { TablePagination } from "../table/TablePagination";
import { CustomLoadingOverlay } from "./CustomLoadingOverlay";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import { SubmissionsNameFilter } from "./filters/SubmissionsNameFilter";
import {
  getSubmissionsTableRows,
  SubmissionsColumnHeaders,
} from "./getSubmissionsTableRows";
import { useGetSubmissions } from "./useGetSubmissions";
import { useGetSubmissionsTableColDefs } from "./useGetSubmissionsTableColDefs";

export type SubmissionsTableProps = {
  validStatuses?: SubmissionStatus[];
  filterOptions?: FilterOption[];
  columnHeaders?: SubmissionsColumnHeaders[];
  onRowClick: (params: RowClickedEvent) => void;
  customHeight?: string;
  vendorId?: number;
  orgId?: number;
};

export const SUBMISSIONS_TABLE_PAGINATION_SIZE = 20;

export const SubmissionsTable = ({
  validStatuses = [],
  columnHeaders = [],
  onRowClick,
  customHeight = "h-[500px]",
  vendorId,
  orgId,
}: SubmissionsTableProps) => {
  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();
  const {
    activeFilters,
    getSearchVars,
    setDateRangeFilter,
    dateRangeFilter,
    selectedSubmissionPeriods,
    setSelectedSubmissionPeriods,
  } = useTableFilterContext();

  const searchVars = getSearchVars();

  const { data, isLoading, fetchNextPage, hasNextPage } = useGetSubmissions({
    vendorId,
    searchVars,
  });

  const submissions = useMemo(() => {
    return data?.pages?.flatMap((page) => page.submissions) ?? [];
  }, [data]);

  const rows = getSubmissionsTableRows({
    submissions,
  });

  const colDefs = useGetSubmissionsTableColDefs({ submissions, columnHeaders });

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlay;
  }, []);
  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "One moment please...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Oops! No submissions found.",
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const totalSubmissions = data?.pages[0]?.aggregateSubmission._count?.id;

  return (
    <div
      id="transformation-table"
      className={cn("ag-theme-custom cell-error flex flex-col", customHeight)}
    >
      <div className="flex flex-row items-center justify-between gap-4 pb-2">
        <div className="flex gap-2">
          <div className="w-fit">
            <SubmissionsNameFilter vendorId={vendorId} />
          </div>
          <div className="w-fit">
            <DateRangeFilter
              dateRangeFilter={dateRangeFilter}
              setDateRangeFilter={setDateRangeFilter}
              selectedSubmissionPeriods={selectedSubmissionPeriods}
              setSelectedSubmissionPeriods={setSelectedSubmissionPeriods}
            />
          </div>

          <TableFiltersPopover />
          <div className="space-x-2">
            {activeFilters.map((filter) => (
              <Filter key={filter.label} activeFilter={filter} />
            ))}
          </div>
        </div>
        <TablePagination
          gridApi={gridApi}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          paginationSize={SUBMISSIONS_TABLE_PAGINATION_SIZE}
          totalSubmissions={totalSubmissions ?? 20}
        />
      </div>
      <AgGridReact
        onRowClicked={onRowClick}
        onGridReady={onGridReady}
        rowSelection="multiple"
        pagination={true}
        paginationPageSize={SUBMISSIONS_TABLE_PAGINATION_SIZE}
        suppressPaginationPanel={true}
        // onPaginationChanged={onPaginationChanged}
        suppressScrollOnNewData={true}
        rowData={isLoading ? undefined : rows}
        columnDefs={colDefs}
        loadingOverlayComponent={loadingOverlayComponent}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
        noRowsOverlayComponent={noRowsOverlayComponent}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        reactiveCustomComponents
      />
    </div>
  );
};
