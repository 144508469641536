import { graphql } from "@/gql";
import { createQueryOptions } from "@/lib/hooks/graphql";

const query = graphql(`
  query SubmissionTypeEditPage($where: SubmissionTypeWhereUniqueInput!) {
    submissionType(where: $where) {
      id
      name
      description
      icon
      createdAt
      updatedAt
      frequency
      outputColumns {
        id
        name
        description
        dataType
      }
      vendors {
        id
        email
        name
        isActive
      }
      transformationTypes {
        id
        name
        description
        index
        updatedAt
        parameters
        template {
          id
          name
        }
      }
      validationTypes {
        id
        name
        description
        updatedAt
        parameters
      }
    }
  }
`);

export const getSubmissionTypeLayoutQueryOptions = (
  submissionTypeId: number,
) => {
  return createQueryOptions({
    query,
    variables: {
      where: {
        id: submissionTypeId,
      },
    },
  });
};
