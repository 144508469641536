import Excel from "exceljs";
import { usePrepareFileDownloadQuery } from "./queries/usePrepareFileDownloadQuery";
import { loadWorkbookFromBuffer } from "../fileUtils";
import { useState } from "react";

// Moved outside the hook to prevent recreating on each render
const validateFile = async (
  response: Response,
): Promise<{ isValid: boolean; message: string; contentType: string }> => {
  const contentType = response.headers.get("Content-Type") ?? "";
  const validTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/octet-stream",
    "text/csv",
    "application/csv",
  ];

  if (validTypes.includes(contentType)) {
    return {
      isValid: true,
      contentType,
      message: `Valid file type: ${contentType}`,
    };
  } else {
    return {
      isValid: false,
      contentType,
      message: `Invalid Content-Type: ${contentType}. Expected Excel or CSV.`,
    };
  }
};

export function useExcelWorkbookFromFileId(fileId: number) {
  const [workbook, setWorkbook] = useState<Excel.Workbook>(
    new Excel.Workbook(),
  );
  const [worksheet, setWorksheet] = useState<Excel.Worksheet | undefined>(
    undefined,
  );
  const [isPending, setIsPending] = useState<boolean>(true);
  const [validationMessage, setValidationMessage] = useState<string>("");

  const { data } = usePrepareFileDownloadQuery(fileId);
  const signedUrl = data?.prepareFileDownload?.signedUrl;

  const handleDownload = async (signedUrl: string) => {
    try {
      const response = await fetch(signedUrl);
      if (!response.ok) throw new Error("Failed to fetch the file");

      const { isValid, message } = await validateFile(response.clone());
      setValidationMessage(message);

      if (!isValid) {
        throw new Error(message);
      }

      const arrayBuffer = await response.arrayBuffer();
      const loadedWorkbook = await loadWorkbookFromBuffer(arrayBuffer);
      if (loadedWorkbook) {
        setWorkbook(loadedWorkbook);
        const loadedWorksheet = loadedWorkbook.getWorksheet(1);
        setWorksheet(loadedWorksheet);
      } else {
        console.error("failed to load worksheet");
      }
    } catch (error: any) {
      console.error("Error loading Excel file:", error);
    } finally {
      setIsPending(false);
    }
  };

  if (signedUrl && worksheet == null) {
    handleDownload(signedUrl);
  }
  return { worksheet, isPending, validationMessage, workbook };
}
