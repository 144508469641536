import { useInfiniteQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import {
  // ErrorStatus,
  GetVendorsInfiniteQueryVariables,
  SortOrder,
} from "@/gql/graphql";
import { getGraphqlClient } from "@/lib/hooks/graphql";

// $lowAccuracySubmissions: SubmissionWhereInput
// $errorSubmissions: SubmissionWhereInput
// lowAccuracyVendors: vendors {
//   lowAccuracySubmissions: _count {
//     submissions(where: $lowAccuracySubmissions)
//   }
// }
// errorVendors: vendors {
//   errorSubmissions: _count {
//     submissions(where: $errorSubmissions)
//   }
// }

const query = graphql(`
  query GetVendorsInfinite(
    $cursor: VendorWhereUniqueInput
    $where: VendorWhereInput
    $take: Int
    $aggregateVendorWhere2: VendorWhereInput
  ) {
    vendors(cursor: $cursor, take: $take, where: $where) {
      id
      vendorId
      name
      email
      totalSubmissions: _count {
        submissions
      }
    }
    aggregateVendor(where: $aggregateVendorWhere2) {
      _count {
        id
      }
    }
  }
`);

type Props = {
  searchVars: GetVendorsInfiniteQueryVariables["where"];
};

export const useGetVendorsInfinite = ({ searchVars }: Props) => {
  const client = getGraphqlClient();

  return useInfiniteQuery({
    queryKey: ["GetVendorsInfinite", searchVars],
    queryFn: async (data) => {
      const lastVendorId = data.pageParam + 1;

      const cursorObj =
        data.pageParam !== 0
          ? {
              id: lastVendorId,
            }
          : undefined;

      const queryVars = {
        where: {
          ...searchVars,
        },
        take: 40,
        cursor: cursorObj,

        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        // errorSubmissions: {
        //   errors: {
        //     some: {
        //       status: {
        //         in: [ErrorStatus.Unrecoverable, ErrorStatus.Unresolved],
        //       },
        //     },
        //   },
        // },
        // lowAccuracySubmissions: {
        //   metadata: {
        //     some: {
        //       key: {
        //         equals: "initial_estimated_accuracy",
        //       },
        //       AND: [
        //         {
        //           value: {
        //             lt: "0.6",
        //           },
        //         },
        //       ],
        //     },
        //   },
        // },
        aggregateProductWhere2: {
          ...searchVars,
        },
      };

      const vendorData = await client.request(query, queryVars);
      return vendorData;
    },
    initialPageParam: 0,
    getNextPageParam: (currentQuery, allQueriedData) => {
      const vendors = allQueriedData.flatMap((page) => page.vendors);
      if (vendors.length === 0) {
        return undefined;
      }
      return vendors[vendors.length - 1].id;
    },
  });
};
