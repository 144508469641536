import { useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Card, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

import { useGetVendorMapping } from "../useGetVendorMapping";
import { useUpdateNormalizeMapping } from "../useUpdateNormalizeMapping";
import { MouseSensor, TouchSensor } from "./CustomDnDSensors";
import { Input, InputColumnItem } from "./InputColumnItem";
import { Output, OutputColumnItem } from "./OutputColumnItem";

export function ColumnMapping() {
  const { vendorId, submissionType: submissionTypeParam } = useParams();
  if (!vendorId || !submissionTypeParam) {
    throw new Error("Missing vendorId or submissionType");
  }

  const { submissionType, mapping, examples, normalizeMappingId } =
    useGetVendorMapping();

  const initOutputColumn: Output[] = (submissionType?.outputColumns ?? []).map(
    (col) => {
      const associatedInputs = Object.keys(mapping || {}).filter(
        (key) => mapping[key] === col.name,
      );

      const newAssociatedInputs = associatedInputs.map((input) => {
        return {
          id: input,
          example: examples[input] as string,
        };
      });

      return {
        id: col.name,
        associatedInputs: newAssociatedInputs,
      };
    },
  );

  const initInputColumn: Input[] = Object.keys(mapping || []).reduce(
    (acc, input) => {
      if (
        !mapping[input] ||
        mapping[input] === "Unclear" ||
        mapping[input] === "undefined"
      ) {
        return [
          ...acc,
          {
            id: input,
            example: examples[input] as string,
          },
        ];
      }
      return acc;
    },
    [] as Input[],
  );

  // Used for Optimistic Updates
  const [inputColumn, setInputColumn] = useState<Input[]>(initInputColumn);
  const [outputColumn, setOutputColumn] = useState<Output[]>(initOutputColumn);

  const [activeId, setActiveId] = useState<string>();
  const [isDragging, setIsDragging] = useState(false);

  const { mutate } = useUpdateNormalizeMapping();
  const queryClient = useQueryClient();

  const onAssociateInput = (
    outputId: string | void,
    inputId: string | void,
  ) => {
    if (!outputId || outputId === "undefined" || !inputId) return;

    const newMapping = {
      ...mapping,
      [inputId]: outputId,
    };
    mutate(
      {
        where: {
          id: normalizeMappingId,
        },
        data: {
          mapping: newMapping,
        },
      },
      {
        onSuccess: () => {
          toast("Success", {
            description: `New mapping submitted successfully.`,
            duration: 2000,
          });
          queryClient.invalidateQueries({
            queryKey: ["VendorSubmissionTypes", `vendorId=${vendorId}`],
          });
        },
        onError: () => {
          toast("Error", {
            description: `Failed to submit new mapping.`,
            duration: 2000,
          });

          // Undo Optimistic Updates
          const newOutputColumn = Array.from(outputColumn);
          const outputIndex = newOutputColumn.findIndex(
            (output) => output.id === outputId,
          );
          newOutputColumn[outputIndex].associatedInputs = newOutputColumn[
            outputIndex
          ].associatedInputs.filter((input) => input.id !== inputId);
          setOutputColumn(newOutputColumn);

          const newInputColumn = Array.from(inputColumn);
          newInputColumn.push({
            id: inputId,
            example: examples[inputId] as string,
          });
          setInputColumn(newInputColumn);
        },
      },
    );
    // Optimistic Updates
    const newOutputColumn = Array.from(outputColumn);
    const outputIndex = newOutputColumn.findIndex(
      (output) => output.id === outputId,
    );
    newOutputColumn[outputIndex].associatedInputs.push({
      id: inputId,
      example: examples[inputId] as string,
    });
    setOutputColumn(newOutputColumn);

    const newInputColumn = Array.from(inputColumn);
    const inputIndex = newInputColumn.findIndex(
      (input) => input.id === inputId,
    );
    newInputColumn.splice(inputIndex, 1);
    setInputColumn(newInputColumn);
  };

  const onRemoveAssociatedInput = (
    outputId: string | void,
    inputId: string | void,
  ) => {
    if (!outputId || outputId === "undefined" || !inputId) return;
    const newMapping = {
      ...mapping,
      [inputId]: null,
    };
    mutate(
      {
        where: {
          id: normalizeMappingId,
        },
        data: {
          mapping: newMapping,
        },
      },
      {
        onSuccess: () => {
          toast("Success", {
            description: `Mapping removed successfully.`,
            duration: 2000,
          });
          queryClient.invalidateQueries({
            queryKey: ["VendorSubmissionTypes", `vendorId=${vendorId}`],
          });
        },
        onError: () => {
          toast("Error", {
            description: `Failed to remove mapping.`,
            duration: 2000,
          });

          // Undo Optimistic Updates
          const newOutputColumn = Array.from(outputColumn);
          const outputIndex = newOutputColumn.findIndex(
            (output) => output.id === outputId,
          );
          newOutputColumn[outputIndex].associatedInputs.push({
            id: inputId,
            example: examples[inputId] as string,
          });
          setOutputColumn(newOutputColumn);

          const newInputColumn = Array.from(inputColumn);
          newInputColumn.push({
            id: inputId,
            example: examples[inputId] as string,
          });
          setInputColumn(newInputColumn);
        },
      },
    );
    // Optimistic Updates
    const newOutputColumn = Array.from(outputColumn);
    const outputIndex = newOutputColumn.findIndex(
      (output) => output.id === outputId,
    );
    newOutputColumn[outputIndex].associatedInputs = newOutputColumn[
      outputIndex
    ].associatedInputs.filter((input) => input.id !== inputId);
    setOutputColumn(newOutputColumn);

    const newInputColumn = Array.from(inputColumn);
    newInputColumn.push({
      id: inputId,
      example: examples[inputId] as string,
    });
    setInputColumn(newInputColumn);
  };

  function handleDragStart(event: DragStartEvent) {
    setIsDragging(true);
    setActiveId(String(event.active.id));
  }

  function handleDragEnd({ over }: DragEndEvent) {
    onAssociateInput(String(over?.id), activeId);
    setIsDragging(false);
    setActiveId(undefined);
  }

  const activeItem = inputColumn.find((input) => input.id === activeId);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setIsDragging(false)}
    >
      <Card className="grid grid-cols-2 gap-6 border-none">
        <div className="col-span-1 flex flex-col gap-4">
          <CardHeader className="text-center text-xl">
            Uploaded Columns
          </CardHeader>
          <Separator />
          {inputColumn.map((input) => (
            <InputColumnItem
              input={input}
              key={input.id}
              outputColumn={outputColumn}
              onAssociateInput={onAssociateInput}
            />
          ))}
          {initInputColumn.length === 0 && (
            <Card className="flex h-full justify-center text-muted-foreground">
              <CardHeader>All uploaded columns are mapped</CardHeader>
            </Card>
          )}
        </div>
        <div className="col-span-1 flex flex-col gap-4">
          <CardHeader className="text-center text-xl">
            Template Columns
          </CardHeader>
          <Separator />

          {outputColumn.map((output) => {
            return (
              <OutputColumnItem
                key={output.id}
                output={output}
                isDragging={isDragging}
                onRemoveAssociatedInput={onRemoveAssociatedInput}
              />
            );
          })}
        </div>
      </Card>

      <DragOverlay zIndex={40}>
        {activeItem ? (
          <InputColumnItem
            input={activeItem}
            outputColumn={outputColumn}
            onAssociateInput={onAssociateInput}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
