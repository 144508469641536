import "../ag-grid-theme-custom.css";
import "../cell-error.css";

import { useEffect, useState } from "react";
import { CellValueChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import Excel from "exceljs";

import { Card } from "@/components/ui/card";

import { getHeaderType } from "./getHeaderType";
import { ReprocessSubmissionButton } from "./ReprocessSubmissionButton";
import { TypedOutputColumn } from "./useGetTypedOutputColumns";
import { useGetValidationExcelTableColDefs } from "./useGetValidationExcelTableColDefs";
import { validateCell } from "./validateCell";
import {
  FilterType,
  ValidationExcelTableFilters,
} from "./ValidationExcelTableFilters";
import { useValidationStore } from "./ValidationStore";

type Props = {
  headerValues: string[];
  rowsValues: {
    [key: string]: Excel.CellValue;
  }[];
  worksheet: Excel.Worksheet;
  workbook: Excel.Workbook;
  typedOutputColumns: TypedOutputColumn[];
};

export const ValidationExcelTable = ({
  rowsValues,
  headerValues,
  workbook,
  worksheet,
  typedOutputColumns,
}: Props) => {
  const { validationErrors, setValidationErrors } = useValidationStore();
  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();
  const [externalFilter, setExternalFilter] = useState<{
    type: FilterType;
    value: string;
  }>({ type: FilterType.All, value: FilterType.All });

  useEffect(() => {
    setValidationErrors(
      rowsValues.reduce(
        (acc, row) => {
          Object.entries(row).forEach(([key, value]) => {
            const { type } = getHeaderType({ header: key, typedOutputColumns });
            const validationStatus = validateCell(value, type, key);
            if (validationStatus !== true) {
              acc.push({
                header: key,
                value,
                validationStatus,
                rowIndex: row.rowIndex as number,
              });
            }
          });
          return acc;
        },
        [] as {
          header: string;
          value: any;
          validationStatus: string;
          rowIndex: number;
        }[],
      ),
    );
  }, []);

  const colDefs = useGetValidationExcelTableColDefs({
    headerValues,
    typedOutputColumns,
  });

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    const { value, data, colDef } = event;
    if (worksheet != null && data != null) {
      const rowIndex = data.rowIndex ?? event?.rowIndex ?? 0 + 2; // +2 because data starts from row 2 in Excel
      // ExcelJS rows and columns are 1-based, AG Grid rows are 0-based
      const excelRow = worksheet.getRow(rowIndex);
      const excelCell = excelRow.getCell(Number(colDef.colId));

      excelCell.value = value; // Set the new value
      // now update the validationErrors, if the cell updated we can find it by matching the rowIndex and headerName
      const newValidationErrors = validationErrors.filter(
        (error) =>
          !(error.rowIndex === rowIndex && error.header === colDef.headerName),
      );

      setValidationErrors(newValidationErrors);
    }
  };

  function doesExternalFilterPass(node: any): boolean {
    // node.data is row values {}
    if (node.data) {
      if (
        externalFilter.type === FilterType.All ||
        externalFilter.type === FilterType.Valid ||
        externalFilter.type === FilterType.Invalid
      ) {
        // check whether the row has a validation error
        let isRowValid = true;
        validationErrors.find((error) => {
          if (error.rowIndex === node.data.rowIndex) {
            isRowValid = false;
          }
        });

        switch (externalFilter.type) {
          case FilterType.Valid:
            return isRowValid;
          case FilterType.Invalid:
            return !isRowValid;
          default:
            return true;
        }
      } else if (externalFilter.type === FilterType.Custom) {
        // check whether the row has a validation error and if the value matches the filter
        //  find all errors with the same rowIndex
        const errors = validationErrors.filter((error) => {
          const isRowError = error.rowIndex === node.data.rowIndex;
          const isMatchedError =
            error.validationStatus.toLowerCase() ===
            externalFilter.value.toLowerCase();

          return isRowError && isMatchedError;
        });
        // if there are no errors, the row is valid
        return errors.length > 0;
      }
    }
    return true;
  }

  return (
    <>
      <div className="flex gap-4">
        <ValidationExcelTableFilters
          workbook={workbook}
          gridApi={gridApi}
          rowsValues={rowsValues}
          setExternalFilter={setExternalFilter}
        />
        <ReprocessSubmissionButton />
      </div>

      <Card
        id="myGrid"
        className="ag-theme-custom cell-error h-[600px]"
        // className="ag-theme-quartz-dark cell-error" // applying the grid theme
      >
        {/* @ts-ignore */}
        <AgGridReact
          columnDefs={colDefs}
          onGridReady={(params) => {
            setGridApi(params.api);
            params.api.autoSizeAllColumns();
          }}
          alwaysShowHorizontalScroll={true}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          pagination={true}
          rowData={rowsValues}
          reactiveCustomComponents={true}
          isExternalFilterPresent={() => externalFilter.type !== FilterType.All}
          doesExternalFilterPass={doesExternalFilterPass}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={10}
        />
      </Card>
    </>
  );
};
