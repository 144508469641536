import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { DateRangeFilter } from "@/components/shared-components/table/DateRangeFilter";
import { SubmissionPeriod } from "@/components/shared-components/table/TableFiltersContext";

// Sales By Region
// Inventory By Region
// Average Sales Per PArtner
// Rank Partner Table By Sales
// Average Accuracy by report type
export const VendorsSummaryCard = () => {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(
    undefined,
  );
  const [selectedSubmissionPeriods, setSelectedSubmissionPeriods] = useState<
    SubmissionPeriod[]
  >([]);

  return (
    <Card className="col-span-1 row-span-1">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Program Stats</CardTitle>
        <DateRangeFilter
          dateRangeFilter={dateRangeFilter}
          setDateRangeFilter={setDateRangeFilter}
          selectedSubmissionPeriods={selectedSubmissionPeriods}
          setSelectedSubmissionPeriods={setSelectedSubmissionPeriods}
        />
      </CardHeader>
      {/* labels on the far left, stats on far right */}
      <CardContent>
        <div className="flex justify-between py-2">
          <p>POS Report Compliance</p>
          <p>95%</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>INV Report Compliance</p>
          <p>88%</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Partners Submitting Feedback</p>
          <p>58%</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Fastest Growing Partner</p>
          <p>BD Tech</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Top GPU Seller</p>
          <p>BD Tech</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Top CPU Seller</p>
          <p>BD Tech</p>
        </div>
      </CardContent>
    </Card>
  );
};
