import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";
import { queryClient } from "@/queryClient";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { useCreateOneVendor } from "./useCreateOneVendor";
import { useUpdateOneVendor } from "./useUpdateOneVendor";
import { GetVendorDataQuery } from "@/gql/graphql";
import { useCurrentOrgSubmissionTypesQuery } from "@/lib/hooks/queries/SubmissionTypes";
import { Icons } from "@/components/Icons";
import { SubmissionTypeIcon } from "@/app/dashboard/vendors/summary/SubmissionTypeIcon";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  vendorData?: GetVendorDataQuery["vendor"];
};

const vendorSchema = z.object({
  name: z.string(),
  email: z.string(),
  vendorId: z.string(),
  submissionTypes: z.array(z.object({ id: z.number(), type: z.string() })),
});

export const CreateOrUpdateVendorForm = ({ setIsOpen, vendorData }: Props) => {
  const orgId = useGetOrgIdOrLogout();
  const { data } = useCurrentOrgSubmissionTypesQuery();
  const { submissionTypes } = data;
  const orgSubmissionTypes = submissionTypes.map((type) => ({
    id: type.id,
    type: type.type,
  }));

  const { mutate: createVendor, isPending: isCreatePending } =
    useCreateOneVendor();
  const { mutate: updateVendor, isPending: isUpdatePending } =
    useUpdateOneVendor();

  const form = useForm<z.infer<typeof vendorSchema>>({
    resolver: zodResolver(vendorSchema),
    defaultValues: {
      name: vendorData?.name,
      email: vendorData?.email,
      vendorId: vendorData?.vendorId,
      submissionTypes: vendorData?.submissionTypes ?? orgSubmissionTypes,
    },
  });

  useEffect(() => {
    form.reset();
  }, []);

  const onFormSubmit = (values: z.infer<typeof vendorSchema>) => {
    if (vendorData) {
      const editVendorData = {
        email: {
          set: values.email,
        },
        name: {
          set: values.name,
        },
        vendorId: {
          set: values.vendorId,
        },
        submissionTypes: {
          set: values.submissionTypes.map((type) => ({
            id: type.id,
          })),
        },
      };
      updateVendor(
        {
          where: {
            id: vendorData.id,
          },
          data: editVendorData,
        },
        {
          onSuccess: () => {
            toast("Success", {
              description: `Vendor updated.`,
              duration: 2000,
            });
            queryClient.invalidateQueries({
              queryKey: ["GetVendorData"],
            });
            queryClient.invalidateQueries({
              queryKey: ["GetVendorsInfinite"],
            });
            setIsOpen(false);
          },
        },
      );
    } else {
      createVendor(
        {
          data: {
            name: values.name,
            email: values.email,
            vendorId: values.vendorId,
            submissionTypes: {
              connect: values.submissionTypes.map((type) => ({
                id: type.id,
              })),
            },
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
        {
          onSuccess: () => {
            toast("Success", {
              description: `Vendor created.`,
              duration: 2000,
            });
            queryClient.invalidateQueries({
              queryKey: ["GetVendorsInfinite", "GetVendorData"],
            });
            setIsOpen(false);
          },
        },
      );
    }
  };

  const toggleSubmissionType = (id: number) => {
    const currentTypes = form.getValues("submissionTypes");
    const updatedTypes: { id: number; type: string }[] = [];

    // if the id already exists in currentTypes, remove it
    if (currentTypes.find((type) => type.id === id)) {
      updatedTypes.push(...currentTypes.filter((type) => type.id !== id));
    } else {
      // if the id does not exist in currentTypes, add it
      const foundType = submissionTypes.find((type) => type.id === id);
      if (foundType) {
        const newType = { id: id, type: foundType.type };
        updatedTypes.push(...currentTypes, newType);
      }
    }

    form.setValue("submissionTypes", updatedTypes);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} className="w-96" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} className="w-96" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="vendorId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Vendor ID</FormLabel>
              <FormControl>
                <Input {...field} className="w-96" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="submissionTypes"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Submission Types</FormLabel>
                <FormControl>
                  <ul className="grid grid-cols-2">
                    {submissionTypes.map((type) => {
                      const isSelected =
                        field.value.find((t) => t.id === type.id) ?? false;

                      return (
                        <li
                          key={type.id}
                          className={`col-span-1 flex w-56 cursor-pointer rounded-md hover:bg-primary/10
                      ${isSelected ? " ring-1 ring-white" : ""}`}
                          onClick={() => toggleSubmissionType(type.id)}
                        >
                          <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md border text-sm font-medium ">
                            <SubmissionTypeIcon submissionTypeId={type.id} />
                          </div>
                          <div className="relative flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t">
                            <div className="flex-1 truncate px-4 py-2 text-sm">
                              <p>{type.type}</p>
                            </div>
                            <div className="flex-shrink-0 pr-2">
                              <Tooltip>
                                <TooltipTrigger>
                                  <Icons.information
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {type.description}
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <DialogFooter className="pt-6">
          <Button type="submit" className="w-32">
            {vendorData ? "Update" : "Create"}
            {(isUpdatePending || isCreatePending) && <Spinner></Spinner>}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};
