import { Edge } from "reactflow";
import { match } from "ts-pattern";

import { cn } from "@/lib/cn";

import { calculateNodePosition } from "./calculateNodePosition";
import { getHeaderNodeId } from "./getHeaderNodeId";
import { SOURCE_HEADER_NODE_TYPE, TARGET_HEADER_NODE_TYPE } from "./nodes";
import { HeaderNode } from "./nodes/HeaderNode";
import { TabularMappingNode, TabularMappingProps } from "./TabularMapping";

interface createNodesProps
  extends Pick<
    TabularMappingProps,
    "sourceHeaders" | "targetHeaders" | "orientation" | "exampleRowData"
  > {
  flowWidth: number;
  flowHeight: number;
  edges: Edge[];
  // spotlightedNodesMap: Map<HeaderNode["id"], HeaderNode>;
}

export const createNodes = ({
  sourceHeaders,
  targetHeaders,
  orientation,
  // spotlightedNodesMap,
  flowWidth,
  flowHeight,
  exampleRowData,
  edges,
}: createNodesProps) => {
  const {
    sourceNodeWidth,
    sourceNodeHeight,
    targetNodeWidth,
    targetNodeHeight,
  } = match(orientation)
    .with("vertical", () => {
      return {
        sourceNodeWidth: flowWidth / sourceHeaders.length,
        sourceNodeHeight: 100,
        targetNodeWidth: flowWidth / targetHeaders.length,
        targetNodeHeight: 100,
      };
    })
    .with("horizontal", () => {
      return {
        sourceNodeWidth: 300,
        sourceNodeHeight: flowHeight / sourceHeaders.length,
        targetNodeWidth: 300,
        targetNodeHeight: flowHeight / targetHeaders.length,
      };
    })
    .exhaustive();
  // const someNodesSpotlighted = spotlightedNodesMap.size > 0;

  const sourceNodes = sourceHeaders.map((header, i) => {
    const id = getHeaderNodeId({ header, type: SOURCE_HEADER_NODE_TYPE });
    return {
      id,
      position: calculateNodePosition({
        i,
        nodeWidth: sourceNodeWidth,
        nodeHeight: sourceNodeHeight,
        flowWidth,
        flowHeight,
        orientation,
        type: SOURCE_HEADER_NODE_TYPE,
      }),
      data: {
        header,
        data: [exampleRowData?.[header]],
        orientation,
        // isSpotlighted: spotlightedNodesMap.has(id),
        // someNodesSpotlighted,
        style: {
          width: sourceNodeWidth,
          height: sourceNodeHeight,
        },
        className: cn(i !== 0 ? "border-l-transparent" : ""),
        outputs: {
          colType: {
            type: "string",
          },
        },
      },
      draggable: false,
      type: SOURCE_HEADER_NODE_TYPE,
    } satisfies HeaderNode;
  });

  const targetNodes = targetHeaders.map((header, i) => {
    const id = getHeaderNodeId({ header, type: TARGET_HEADER_NODE_TYPE });

    const sourceData = edges.reduce((acc, edge) => {
      if (edge.target === id) {
        const sourceNode = sourceNodes.find((node) => node.id === edge.source);
        if (sourceNode) {
          // acc.push(sourceNode.data.data[0]);
          acc = [...acc, ...sourceNode.data.data];
        }
      }
      return acc;
    }, [] as any[]);

    return {
      id,
      position: calculateNodePosition({
        i,
        nodeWidth: targetNodeWidth,
        nodeHeight: targetNodeHeight,
        flowWidth,
        flowHeight,
        orientation,
        type: TARGET_HEADER_NODE_TYPE,
      }),
      data: {
        header,
        orientation,
        // isSpotlighted: spotlightedNodesMap.has(id),
        // someNodesSpotlighted,
        data: sourceData,
        style: {
          width: targetNodeWidth,
          height: targetNodeHeight,
        },
        className: cn(i !== 0 ? "border-l-transparent" : ""),
        parameters: {
          colType: {
            type: "string",
          },
        },
      },
      draggable: false,
      type: TARGET_HEADER_NODE_TYPE,
    } satisfies HeaderNode;
  });

  return [...sourceNodes, ...targetNodes] as TabularMappingNode[];
};
