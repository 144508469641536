import { Icons } from "@/components/Icons";

import { DashHomePage } from "./home/DashHomePage";
import { ProductsPage } from "./products/ProductsPage";
import { SettingsLayout } from "./settings/SettingsLayout";
import { settingsRoutes } from "./settings/settingsRoutes";
import { SubmissionAnalyticsView } from "./submissions/:submissionId/(predictions)/analytics/SubmissionAnalyticsView";
import { SubmissionCorrectionsView } from "./submissions/:submissionId/(predictions)/SubmissionCorrectionsView";
import { SubmissionCorrectionsLayout } from "./submissions/:submissionId/corrections/SubmissionCorrectionsLayout";
import { SubmissionLayout } from "./submissions/:submissionId/SubmissionLayout";
import { SubmissionsPage } from "./submissions/SubmissionsPage";
// import TrainingPage from "./training/TrainingPage";
import { VendorsPage } from "./vendors/general/VendorsPage";
import { SubmissionMappingPage } from "./vendors/summary/submission/[submissionTypeId]/SubmissionMappingPage";
import { VendorSummary } from "./vendors/summary/VendorSummary";
import { VendorLayout } from "./vendors/VendorLayout";
import { VendorsLayout } from "./vendors/VendorsLayout";
import { getDecodedJwtCookie } from "@/lib/hooks/queries/Auth0";
import { getUserQueryOptions } from "@/lib/hooks/queries/User";
import { ensureQueryData } from "@/lib/hooks/graphql";
import { LoaderFunctionArgs, redirect, resolvePath } from "react-router-dom";

export const dashboardRoutes = [
  {
    id: "Home",
    path: "",
    element: <DashHomePage />,

    Icon: Icons.home,
    showInNav: true,
  },
  {
    id: "Submissions",
    path: "submissions",
    element: <SubmissionsPage />,
    Icon: Icons.submission,
    showInNav: true,
  },
  {
    id: "Submission",
    path: "submissions/:submissionId",
    element: <SubmissionLayout />,
    children: [
      {
        id: "Submission Corrections",
        path: "corrections",
        end: true,
        element: <SubmissionCorrectionsLayout />,
        children: [
          {
            id: "Transformation Predictions Table",
            path: "transformations/:transformationId",
            element: <SubmissionCorrectionsView />,
          },
        ],
      },
      {
        id: "Submission Analytics",
        path: "analytics",
        element: <SubmissionAnalyticsView />,
      },
    ],
  },
  {
    id: "Partners",
    path: "partner",
    element: <VendorsLayout />,
    Icon: Icons.users,
    showInNav: true,
    children: [
      {
        id: "Partner Selection",
        path: "",
        element: <VendorsPage />,
      },
      {
        id: "Partner",
        path: ":vendorId",
        element: <VendorLayout />,
        Icon: Icons.user,
        children: [
          {
            id: "Partner Summary",
            path: "",
            element: <VendorSummary />,
          },
          {
            id: "Partner Mapping",
            path: "mapping/:submissionType",
            element: <SubmissionMappingPage />,
          },
        ],
      },
    ],
  },
  // {
  //   id: "Training",
  //   path: "training",
  //   element: <TrainingPage />,
  //   Icon: Icons.ai,
  //   showInNav: true,
  // },
  {
    id: "Products",
    path: "products",
    element: <ProductsPage />,
    Icon: Icons.package,
    showInNav: true,
  },
  {
    id: "Settings",
    path: "settings",
    element: <SettingsLayout />,
    Icon: Icons.settings,
    showInNav: true,
    children: settingsRoutes,
    loader: async ({ request }: LoaderFunctionArgs) => {
      const decodedJwt = getDecodedJwtCookie();
      if (decodedJwt === null) return null;

      const options = getUserQueryOptions({ id: decodedJwt?.claims.id });
      // ensure user is being fetched prior to rendering dashboard
      const { user } = await ensureQueryData(options);
      if (!user) return null;

      const { pathname: basePathname } = resolvePath("", "/dashboard/settings");
      const requestPathname = new URL(request.url).pathname;

      // debugger;

      if (basePathname !== requestPathname) return user;

      if (user.organization.id) {
        return redirect(`org/${user.organization.id}`);
      }
      return redirect(`account/${user.id}`);
    },
  },
];
