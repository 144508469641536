import { graphql } from "@/gql";
import { SortOrder, Submission } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getSummaryDataQueryOptions = (submissionId: Submission["id"]) => {
  return createQueryOptions({
    query: graphql(`
      query getSubmissionSummaryData(
        $where: SubmissionWhereUniqueInput!
        $transformationsWhere2: TransformationWhereInput
        $take: Int
        $orderBy: [FeedbackOrderByWithRelationAndSearchRelevanceInput!]
      ) {
        submission(where: $where) {
          file {
            name
            createdAt
          }
          transformations(where: $transformationsWhere2) {
            id
            name
            status
            type {
              name
              id
              description
            }
            metadata {
              key
              value
            }
            predictions {
              id
              labels {
                name
                id
                feedback(take: $take, orderBy: $orderBy) {
                  id
                  content
                }
                value
              }
            }
          }
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
      transformationsWhere2: {
        type: {
          isNot: {
            templateId: {
              equals: 1,
            },
          },
        },
      },
      take: 1,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
    },
  });
};
