import { useMemo } from "react";
import { ColDef } from "ag-grid-community";

import { GetVendorsInfiniteQuery } from "@/gql/graphql";

import { VendorRowData, VendorsColumnHeaders } from "./getVendorsTableRows";

type Props = {
  vendors: GetVendorsInfiniteQuery["vendors"];
};

export const useGetVendorsTableColDefs = ({ vendors }: Props) => {
  const columnHeaders = Object.values(VendorsColumnHeaders);
  return useMemo(() => {
    const colDefs = columnHeaders.reduce(
      (acc, header) => {
        const standardColDef: ColDef<VendorRowData> = {
          headerName: header,
          field: header,
          colId: header,
          filter: false,
          editable: false,
          resizable: true,
          flex: 0.7,
        };

        let customColDef = standardColDef as ColDef<VendorRowData>;

        if (header === VendorsColumnHeaders.ID) {
          customColDef = {
            ...standardColDef,
            hide: true,
          };
        }

        acc.push(customColDef);
        return acc;
      },
      [] as Array<ColDef<VendorRowData>>,
    );

    return colDefs;
  }, [vendors, columnHeaders]);
};
