import { cn } from "@/lib/cn";

import { forwardRef } from "react";

interface IconDisplayBubbleProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  interactive?: boolean;
}

const IconDisplayBubble = forwardRef<HTMLButtonElement, IconDisplayBubbleProps>(
  ({ className, children, interactive, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "group flex h-12 w-12 shrink-0 items-center justify-center rounded-full border border-border bg-muted transition-all",
          interactive && "hover:border-primary",
          className,
        )}
        disabled={!interactive}
        {...props}
      >
        {children}
      </button>
    );
  },
);

IconDisplayBubble.displayName = "IconDisplayBubble";

export { IconDisplayBubble };
