import { useCurrentOrgSubmissionTypesQuery } from "@/lib/hooks/queries/SubmissionTypes";

import { SubmissionTypeItem } from "./SubmissionTypeItem";
import { useGetVendorData } from "./useGetVendorData";
import { useParams } from "react-router-dom";

export function SubmissionTypesSection() {
  const { vendorId } = useParams();

  const { data } = useCurrentOrgSubmissionTypesQuery();
  const { data: vendorData } = useGetVendorData({ vendorId: Number(vendorId) });
  const { submissionTypes: orgSubmissionTypes } = data;

  return (
    <div>
      <h2 className="text-sm font-medium">Submission Types</h2>
      <ul role="list" className="mt-3 flex items-center gap-4">
        {orgSubmissionTypes.map((submissionType) => {
          const isDisabled =
            vendorData?.vendor?.submissionTypes.find(
              (t) => t.id === submissionType.id,
            ) == null;

          return (
            <SubmissionTypeItem
              isDisabled={isDisabled}
              key={submissionType.id}
              submissionType={submissionType}
            />
          );
        })}
        {/* <Button className="h-full rounded-full p-1" variant="outline">
          <Icons.plus className="h-5" aria-hidden="true" />
        </Button> */}
      </ul>
    </div>
  );
}
