import { Link } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { cn } from "@/lib/cn";

type Props = {
  onApprove: () => void;
  approved: boolean;
  name: string;
  description: string;
  href: string;
};

export const ListItem = ({
  onApprove,
  approved,
  name,
  description,
  href,
}: Props) => {
  return (
    <li className="flex w-full flex-1 flex-col items-center gap-4 pb-4">
      <Card className="w-full">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <Button
              variant={"outline"}
              onClick={onApprove}
              className={cn(
                "relative mr-4 h-8 w-8 rounded-full border",
                approved && "border-green-500",
              )}
            >
              <span className="absolute inset-0 flex items-center justify-center text-lg font-bold text-primary-foreground">
                {approved && (
                  <Icons.check className="h-4 w-4  text-green-500" />
                )}
              </span>
            </Button>
            <div className="space-y-2">
              <CardTitle className="text-md">{name}</CardTitle>
              <CardDescription className="max-w-2xl">
                {description}
              </CardDescription>
            </div>
          </div>
          <Link
            to={href}
            className={buttonVariants({
              variant: "outline",
            })}
          >
            View
          </Link>
        </CardHeader>
      </Card>
    </li>
  );
};
