import { useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  filterOperationOptions,
  useTableFilterContext,
} from "./TableFiltersContext";

type Props = {
  activeFilterLabel?: string;
};

export const TableFiltersPopover = ({ activeFilterLabel }: Props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const { setActiveFilters, filterOptions } = useTableFilterContext();

  const onItemClick = (currentValue: string) => {
    const filterOption = filterOptions.find(
      (option) => option.selectValue === currentValue,
    );

    // if activeFilter then just change the active filter value otherwise create new
    if (filterOption) {
      if (activeFilterLabel) {
        setActiveFilters((prev) => {
          return prev.map((filter) => {
            if (filter.label === activeFilterLabel) {
              return {
                ...filter,
                value: filterOption.selectValue,
                label: filterOption.label,
                filterValues: [],
              };
            }
            return filter;
          });
        });
      } else {
        const defaultFilterOperation =
          filterOperationOptions.find(
            (operation) =>
              operation.value === filterOption.validFilterOperations[0],
          ) ?? filterOperationOptions[0];

        setActiveFilters((prev) => [
          ...prev,
          {
            label: filterOption.label,
            selectValue: filterOption.selectValue,
            searchField: filterOption.searchField,
            filterValues: [],
            filterOperation: defaultFilterOperation,
            validFilterOperations: filterOption.validFilterOperations,
          },
        ]);
      }
      setValue(currentValue === value ? "" : currentValue);
      setOpen(false);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={"outline"} size={"sm"} className="h-10">
          {activeFilterLabel ? (
            activeFilterLabel
          ) : (
            <>
              <Icons.plus className="h-4 w-4" />
              <span>Add Filter</span>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-1" align="center">
        <Command>
          <CommandInput placeholder="Search filters..." />
          <CommandList>
            <CommandEmpty>No filters found.</CommandEmpty>
            <CommandGroup>
              {filterOptions.map((filter) => (
                <CommandItem
                  className="rounded-md"
                  key={filter.selectValue}
                  value={filter.selectValue}
                  onSelect={onItemClick}
                >
                  {filter.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
