import { FileUpload } from "@/app/vendor-submit/:vendorId/home/FileUpload";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { useCreateManyVendors } from "./useCreateManyVendors";
import { VendorCreateManyInput } from "@/gql/graphql";
import { toast } from "sonner";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";
import {
  loadWorkbookFromBuffer,
  validateRequiredColumns,
} from "@/lib/fileUtils";

export const BulkUploadVendorDialog = () => {
  const orgId = useGetOrgIdOrLogout();

  const [error, setError] = useState<Error>();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const { mutate: bulkCreateVendors } = useCreateManyVendors();

  const onUpload = async (file: File) => {
    setIsUploading(true);
    const buffer = await file.arrayBuffer();
    const workbook = await loadWorkbookFromBuffer(buffer);
    const worksheet = workbook?.getWorksheet(1);

    const requiredColumns = ["Name", "Email", "Vendor Id"];
    if (!worksheet || !validateRequiredColumns(worksheet, requiredColumns)) {
      throw new Error("Invalid file format");
    }

    const headers = (worksheet?.getRow(1).values ?? []) as string[];
    const nameIndex = headers.indexOf("Name");
    const emailIndex = headers.indexOf("Email");
    const vendorIdIndex = headers.indexOf("Vendor Id");
    // check the file for the right columns
    if (
      worksheet == null ||
      nameIndex === -1 ||
      emailIndex === -1 ||
      vendorIdIndex === -1
    ) {
      setIsUploading(false);
      setError(new Error("Invalid file format"));
      return;
    }

    const vendors: VendorCreateManyInput[] = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) return;

      const rowValues = row.values as string[];

      vendors.push({
        email: String(rowValues[emailIndex]),
        organizationId: orgId,
        name: String(rowValues[nameIndex]),
        vendorId: String(rowValues[vendorIdIndex]),
      });
    });

    // create vendors
    bulkCreateVendors(
      {
        data: vendors,
        skipDuplicates: true,
      },
      {
        onSuccess: () => {
          setIsUploading(false);
          toast("Success", {
            description: `Vendors created.`,
            duration: 2000,
          });
          setOpen(false);
          setError(undefined);
        },
        onError: (error) => {
          setIsUploading(false);
          setError(error);
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>Bulk Upload</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Bulk Upload Vendors</DialogTitle>
          <DialogDescription>
            Upload a spreadsheet to bulk create vendors for your organization.
            It must have the following column headers in row 1: Name, Email, and
            Vendor Id.
          </DialogDescription>
        </DialogHeader>
        <FileUpload
          setFileToUpload={setFile}
          file={file}
          onUpload={onUpload}
          isUploading={isUploading}
        />
        {error && (
          <div className="text-destructive">
            <p>Error: {error.message}</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
