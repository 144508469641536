import { useEffect, useRef, useState } from "react";
import * as radixColors from "@radix-ui/colors";
import {
  Cell,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
} from "recharts";

const COLORS = [
  radixColors.blue.blue9,
  radixColors.cyan.cyan9,
  radixColors.yellow.yellow9,
  radixColors.red.red9,
  radixColors.green.green9,
  radixColors.purple.purple9,
  radixColors.orange.orange9,
  radixColors.teal.teal9,
  radixColors.violet.violet9,
  radixColors.pink.pink9,
  radixColors.lime.lime9,
  radixColors.brown.brown9,
  radixColors.sky.sky9,
  radixColors.amber.amber9,
  radixColors.mint.mint9,
  radixColors.plum.plum9,
  radixColors.tomato.tomato9,
  radixColors.grass.grass9,
  radixColors.crimson.crimson9,
  radixColors.indigo.indigo9,
];

type Params = {
  data: { name: string; value: number }[];
};

function ResponsivePieChart({ data }: Params) {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 300, height: 300 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
    };
  }, [ref.current]); // Dependency on the ref's current value

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: PieLabelRenderProps) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, name, index } = props;
    if (
      cx === undefined ||
      cy === undefined ||
      midAngle === undefined ||
      outerRadius === undefined ||
      innerRadius === undefined ||
      name === undefined ||
      index === undefined
    ) {
      return null;
    }
    const radius = Number(outerRadius) + 20; // Move label 20 units outside of the outer radius
    const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
    const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={COLORS[index % COLORS.length]}
        textAnchor={x > Number(cx) ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontSize: "12px" }} // Adjust the font size as needed
      >
        {`${name}`}
      </text>
    );
  };

  return (
    <div ref={ref} className="flex h-full w-full items-center justify-center">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            // label={({ name }) => name}
            label={renderCustomizedLabel}
            outerRadius={Math.min(dimensions.width, dimensions.height) / 3}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResponsivePieChart;
