import { IconDisplayBubble } from "@/components/icon-display-bubble";
import { LucidePicker } from "@/components/lucide-picker/lucide-picker";
import { Popover, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/cn";
import { icons } from "lucide-react";
import { useMemo, useState } from "react";

interface Props extends React.ComponentProps<typeof Popover> {
  icon: string;
  onIconSelect: (icon: string) => void;
}

export const SubmissionTypeIconSelect = ({
  icon,
  onIconSelect,
  ...props
}: Props) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const Icon = useMemo(() => {
    // @ts-expect-error
    return icons[icon];
  }, [icon]);

  return (
    <Popover
      open={isPickerOpen}
      onOpenChange={(isOpen) => {
        setIsPickerOpen(isOpen);
      }}
      {...props}
    >
      <PopoverTrigger asChild>
        <IconDisplayBubble
          interactive
          className={cn(isPickerOpen && "border-primary")}
        >
          <Icon />
        </IconDisplayBubble>
      </PopoverTrigger>

      <LucidePicker
        align="start"
        onIconSelect={(newIcon) => {
          setIsPickerOpen(false);
          onIconSelect(newIcon);
        }}
      />
    </Popover>
  );
};
