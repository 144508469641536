import { format } from "date-fns";

import { GetSubmissionsInfiniteQuery } from "@/gql/graphql";

export enum SubmissionsColumnHeaders {
  ID = "Id",
  FILE_NAME = "File Name",
  DOWNLOAD = "Download",
  TYPE = "Type",
  STATUS = "Status",
  SUBMISSION_PERIOD = "Period",
  UPLOADED = "Uploaded",
  PARTNER = "Partner",
}

type Props = {
  submissions: GetSubmissionsInfiniteQuery["submissions"];
};

type RowValue = Date | string | number;

export const getSubmissionsTableRows = ({ submissions }: Props) => {
  const rows: { [key: string]: RowValue }[] = [];
  //   to get the rows iterate over submissions
  submissions.forEach((submission) => {
    const row: { [key: string]: RowValue } = {};
    const { type, status, createdAt, file, submittedFor } = submission;
    const week = format(new Date(submittedFor), "w");
    const year = format(new Date(submittedFor), "yy");

    row[SubmissionsColumnHeaders.TYPE] = type?.type ?? "";
    row[SubmissionsColumnHeaders.UPLOADED] = new Date(createdAt);
    row[SubmissionsColumnHeaders.FILE_NAME] = file?.name ?? "";
    row[SubmissionsColumnHeaders.STATUS] = status ?? "";
    row[SubmissionsColumnHeaders.SUBMISSION_PERIOD] = `W${week}/Y${year}` ?? "";
    row[SubmissionsColumnHeaders.DOWNLOAD] = String(file.id) ?? "";
    row[SubmissionsColumnHeaders.ID] = String(submission.id) ?? "";
    row[SubmissionsColumnHeaders.PARTNER] = submission.vendor?.name ?? "";
    rows.push(row);
  });

  return rows;
};
