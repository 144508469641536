import "../ag-grid-theme-custom.css";
import "../cell-error.css"; // Custom CSS for cell error styling

import { useEffect, useMemo, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

import { useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

import { doesExternalFilterPass } from "./doesExternalFilterPass";
import { getTransformationPredictionsTableHeadersAndRows } from "./getTransformationPredictionsTableHeadersAndRows";
import { getTransformationPredictionsTableQueryOptions } from "./getTransformationPredictionsTableQueryOptions";
import {
  ExternalFilter,
  FilterType,
  TransformationPredictionsTableFilters,
} from "./TransformationPredictionsTableFilters";
import { useApproveAll } from "./useApproveAll";
import {
  Label,
  useGetTransformationsPredictionsTableColDefs,
} from "./useGetTransformationsPredictionsTableColDefs";
import { useSubmitFeedback } from "./useSubmitFeedback";

export const TransformationPredictionsTable = () => {
  const { transformationId: tId } = useParams();
  const transformationId = Number(tId);

  const options = useMemo(
    () => getTransformationPredictionsTableQueryOptions(transformationId),
    [transformationId],
  );
  const { data: predictionRowData } = useSuspenseQuery(options);
  const { mutate } = useSubmitFeedback({ queryKey: options.queryKey });
  const { headers, rows } = getTransformationPredictionsTableHeadersAndRows({
    predictionRowData,
  });
  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();

  const defaultExternalFilters = useMemo(() => {
    return [
      { type: FilterType.LOW, value: FilterType.LOW },
      { type: FilterType.MID, value: FilterType.MID },
      { type: FilterType.HIGH, value: FilterType.HIGH },
      { type: FilterType.PARTS, value: FilterType.PARTS },
    ];
  }, []);

  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>(
    defaultExternalFilters,
  );

  const colDefs = useGetTransformationsPredictionsTableColDefs({
    headers,
  });

  useEffect(() => {
    if (gridApi) {
      const isGridDestroyed = gridApi.isDestroyed();
      if (!isGridDestroyed) {
        // @ts-ignore my colDefs are correct
        gridApi.setGridOption("columnDefs", colDefs);

        gridApi.refreshCells({ force: true }); // Force refresh of cells
      }
    }
  }, [colDefs, rows, gridApi]);

  // submit feedback here
  const onCellValueChanged = (event: any) => {
    const { value, data, colDef } = event;

    // the only columns that can be edited are the labels columns, check that the column is a label column
    if (colDef.cellDataType === "label") {
      const { colId } = colDef;
      const label = data[colId] as Label;
      mutate({
        data: [
          {
            labelId: label.id,
            content: value,
            upvote: true,
          },
        ],
      });
    }
  };

  const { onApproveAll } = useApproveAll();

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <TransformationPredictionsTableFilters
          queryKey={options.queryKey}
          gridApi={gridApi}
          rows={rows}
          externalFilters={externalFilters}
          setExternalFilters={setExternalFilters}
        />
        <Button variant={"outline"} onClick={() => onApproveAll()}>
          Approve All
          <Icons.check className="ml-2 h-4 w-4" />
        </Button>
      </div>
      <div
        id="transformation-table"
        className="ag-theme-custom cell-error h-[600px]"
      >
        <AgGridReact
          gridOptions={{
            // @ts-ignore type error comes from the Params in valueGetter and not using ag-grid-community types so far
            columnDefs: colDefs,
            dataTypeDefinitions: {
              label: {
                // simple UPPER CASE formatter example
                // { valueFormatter: p => params.value.toUpperCase() }
                baseDataType: "object",
                extendsDataType: "object",
                dataTypeMatcher: (value) => {
                  return typeof value === "object" && value !== null;
                },
              },
            },
          }}
          // debug={true}
          onGridReady={(params) => {
            setGridApi(params.api);
            params.api.autoSizeAllColumns();
          }}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          pagination={true}
          // defaultColDef={defaultColDef}
          rowData={rows}
          // columnDefs={colDefs}
          reactiveCustomComponents={true}
          isExternalFilterPresent={() => externalFilters.length > 0}
          doesExternalFilterPass={(node) =>
            doesExternalFilterPass(node, externalFilters)
          }
        />
      </div>
    </div>
  );
};
