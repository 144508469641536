import { useEffect, useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Rive from "@rive-app/react-canvas-lite";
import { useQuery } from "@tanstack/react-query";
import { useParams, useRevalidator } from "react-router-dom";
import { useInterval } from "react-use";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { Icons } from "@/components/Icons";
import { Progress } from "@/components/ui/progress";
import { easeSwift } from "@/lib/motion";

import { getSubmissionRouteQueryOptions } from "./getSubmissionRouteQueryOptions";

const ESTIMATED_LOADING_TIME = 60000; // Ideally this should be fetched from the server
const POLLING_INTERVAL = 1000;

const AIAnim = () => <Rive src="/ai_extract.riv" />;

export const LoadingPage = () => {
  const { submissionId: sId, vendorId: vId } = useParams();
  const [progress, setProgress] = useState(0);

  const revalidator = useRevalidator();

  const [parent] = useAutoAnimate({
    duration: 250,
    easing: easeSwift,
  });

  const vendorId = Number(vId);
  const submissionId = Number(sId);

  const { data } = useQuery({
    ...getSubmissionRouteQueryOptions({ submissionId, vendorId }),
    refetchInterval: POLLING_INTERVAL,
    refetchIntervalInBackground: true,
    enabled: true,
    refetchOnWindowFocus: true,
  });
  const status = data?.submission?.status;

  useInterval(() => {
    setProgress((prev) => {
      const newProgress = prev + 100 / (ESTIMATED_LOADING_TIME / 110);
      return newProgress >= 99 ? 99 : newProgress;
    });
  }, 110);

  useEffect(() => {
    if (status === "PROCESSED") setProgress(100);
  }, [status]);

  useEffect(() => {
    if (progress === 100 && status === "PROCESSED") {
      setTimeout(() => {
        // throws it back to the router to determine what to do next
        revalidator.revalidate();
      }, 2000);
    }
  }, [progress, status, revalidator]);

  return (
    <DashboardScreen className="grid h-full w-full content-stretch gap-3">
      <div
        className="flex justify-center text-center text-base font-semibold"
        ref={parent}
      >
        {status !== "PROCESSED" ? (
          <div>
            <h1
              key="processing"
              className="flex select-none flex-col gap-2 text-4xl font-bold duration-1000"
            >
              Processing Your Submission!
            </h1>
            <h3 className="max-w-prose text-muted-foreground">
              {`This may take a few minutes. Feel free to close this page and check
              back later - your submission (#${submissionId}) will continue processing. You will be able to find it in the previous submissions table on the home page.`}
            </h3>
          </div>
        ) : (
          <h1
            key="processed"
            className="flex items-center gap-2 text-4xl font-medium"
          >
            <Icons.checkCircle className="h-10 w-10 text-status-success-solid" />
            Processed!
          </h1>
        )}
      </div>

      <div className="mx-auto max-h-[400px] w-full max-w-2xl">
        <AIAnim />
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-2">
        <Progress
          value={progress}
          className="h-2 w-full max-w-6xl bg-slate-600"
        />
      </div>
    </DashboardScreen>
  );
};
