import { useCurrentUserQuery } from "./queries/User";
import { useAuth0 } from "@auth0/auth0-react";

export const useGetOrgIdOrLogout = () => {
  const { logout } = useAuth0();
  const user = useCurrentUserQuery();
  const orgId = user.data?.user?.organization?.id;

  if (!orgId) {
    logout();
  }
  return orgId as number;
};
