import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Outlet } from "react-router-dom";

import { easeSnappy } from "@/lib/motion";

import { VendorSubmissionFlowTabularHeader } from "../../VendorSubmissionFlowTabularHeader";

export function VendorSubmissionLayout() {
  const [parent] = useAutoAnimate({
    duration: 100,
    easing: easeSnappy,
  });

  return (
    <div className="grid max-h-screen min-h-screen grid-rows-[auto_1fr] content-stretch">
      <VendorSubmissionFlowTabularHeader />
      <div ref={parent} className="grid content-stretch overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}
