import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Outlet } from "react-router-dom";

import { easeSnappy } from "@/lib/motion";

import { DashboardScreen } from "../DashboardScreen";

export function VendorsLayout() {
  const [content] = useAutoAnimate({
    duration: 200,
    easing: easeSnappy,
  });

  return (
    <DashboardScreen scrollable ref={content}>
      <Outlet />
    </DashboardScreen>
  );
}
