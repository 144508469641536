import { useMemo } from "react";
import { getConnectedEdges, useReactFlow } from "reactflow";
import { create } from "zustand";

import { useGetConnectedNodes } from "@/lib/hooks/reactflow/useConnectedNodes";

import { TabularMappingNode } from "./TabularMapping";

type TabularMappingState = {
  inspectedNode: TabularMappingNode | null;
  connectingNode: TabularMappingNode | null;
  selectedNodes: TabularMappingNode[];
  spotlightedNodes: TabularMappingNode[];
};

type TabularMappingActions = {
  setInspectedNode: (inspectedNode: TabularMappingNode | null) => void;
  setConnectingNode: (connectingNode: TabularMappingNode | null) => void;
  setSelectedNodes: (selectedNodes: TabularMappingNode[]) => void;
  setSpotlightedNodes: (
    spotlightedNodes: TabularMappingState["spotlightedNodes"],
  ) => void;
};

export const useTabularMappingStore = create<
  TabularMappingState & TabularMappingActions
>((set) => {
  return {
    inspectedNode: null,
    connectingNode: null,
    selectedNodes: [],
    spotlightedNodes: [],
    setInspectedNode: (inspectedNode) => set({ inspectedNode }),
    setConnectingNode: (connectingNode) => set({ connectingNode }),
    setSelectedNodes: (selectedNodes) => set({ selectedNodes }),
    setSpotlightedNodes: (spotlightedNodes) => set({ spotlightedNodes }),
  };
});

export const useSpotlighted = () => {
  const { getEdges } = useReactFlow();
  const { inspectedNode, selectedNodes } = useTabularMappingStore();
  const { getConnectedNodes } = useGetConnectedNodes();

  const spolighted = useMemo(() => {
    const _nodes = new Set<TabularMappingNode>();

    if (inspectedNode) {
      _nodes.add(inspectedNode);
      getConnectedNodes(inspectedNode.id).forEach((node) => _nodes.add(node));
    }

    selectedNodes.forEach((node) => {
      _nodes.add(node);
      getConnectedNodes(node.id).forEach((node) => _nodes.add(node));
    });

    const nodes = Array.from(_nodes);
    const edges = getConnectedEdges(nodes, getEdges());

    return { nodes, edges: getConnectedEdges(nodes, edges) };
  }, [inspectedNode, selectedNodes]);

  return spolighted;
};
