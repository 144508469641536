import { useCallback, useMemo, useState } from "react";
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { cn } from "@/lib/cn";

import { CustomLoadingOverlay } from "../submissions-table/CustomLoadingOverlay";
import { CustomNoRowsOverlay } from "../submissions-table/CustomNoRowsOverlay";
import { Filter } from "../table/Filter";
import { useTableFilterContext } from "../table/TableFiltersContext";
import { TableFiltersPopover } from "../table/TableFiltersPopover";
import { TablePagination } from "../table/TablePagination";
import { ProductDescriptionDialog } from "./description-dialog/ProductDescriptionDialog";
import { ProductNameFilter } from "./filters/ProductNameFilter";
import { getProductsTableRows, ProductRowData } from "./getProductsTableRows";
import { ProductsTableDatePicker } from "./ProductsTableDatePicker";
import { useGetProducts } from "./useGetProducts";
import { useGetProductsTableColDefs } from "./useGetProductsTableColDefs";

type Props = {
  customHeight?: string;
  vendorId?: number;
  orgId?: number;
};

export const PRODUCTS_TABLE_PAGINATION_SIZE = 20;

export const ProductsTable = ({
  customHeight = "h-[800px]",
  vendorId,
  orgId,
}: Props) => {
  const { activeFilters, getSearchVars } = useTableFilterContext();

  const searchVars = getSearchVars();

  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();

  const { data, isLoading, fetchNextPage, hasNextPage } = useGetProducts({
    searchVars,
  });

  const products = useMemo(() => {
    return data?.pages?.flatMap((page) => page.products) ?? [];
  }, [data]);

  const { rows } = getProductsTableRows({ products });

  const colDefs = useGetProductsTableColDefs({ products });

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlay;
  }, []);
  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "One moment please...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Oops! No submissions found.",
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const [selectedRow, setSelectedRow] = useState<ProductRowData>();

  const onRowClick = (e: RowClickedEvent<ProductRowData>) => {
    const rowData = e.data;
    setSelectedRow(rowData);
  };

  const onClose = () => {
    setSelectedRow(undefined);
  };

  return (
    <>
      {selectedRow && (
        <ProductDescriptionDialog rowData={selectedRow} onClose={onClose} />
      )}
      <div
        id="transformation-table"
        className={cn("ag-theme-custom cell-error flex flex-col", customHeight)}
      >
        <div className="flex flex-row items-center justify-between gap-4 pb-2">
          <div className="flex gap-2">
            <ProductNameFilter />
            <ProductsTableDatePicker />
            <TableFiltersPopover />
            <div className="flex items-center gap-2">
              {activeFilters.map((filter) => (
                <Filter key={filter.label} activeFilter={filter} />
              ))}
            </div>
          </div>
          <TablePagination
            gridApi={gridApi}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            paginationSize={PRODUCTS_TABLE_PAGINATION_SIZE}
            totalSubmissions={data?.pages[0]?.aggregateProduct._count?.id ?? 20}
          />
        </div>
        <AgGridReact
          onRowClicked={onRowClick}
          onGridReady={onGridReady}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={PRODUCTS_TABLE_PAGINATION_SIZE}
          suppressPaginationPanel={true}
          // onPaginationChanged={onPaginationChanged}
          suppressScrollOnNewData={true}
          rowData={isLoading ? undefined : rows}
          columnDefs={colDefs}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          reactiveCustomComponents
        />
      </div>
    </>
  );
};
