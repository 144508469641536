import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { TailwindIndicator } from "./components/TailwindIndicator";
import { ModeToggle } from "./components/ModeToggle";

export function DevTools() {
  return (
    <>
      <ReactQueryDevtools />
      <TailwindIndicator className="bottom-[4.5rem] left-auto right-3 z-20" />
      <ModeToggle className="absolute bottom-[6.5rem] right-3 z-20 h-6 w-6" />
    </>
  );
}
