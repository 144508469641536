import { useEffect } from "react";
import {
  LoaderFunction,
  NavigateOptions,
  To,
  useLoaderData,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ZodError, ZodObject, ZodRawShape } from "zod";

interface RedirectProps {
  matchPath: Parameters<typeof useMatch>[0];
  to: To | (() => To | undefined);
  options?: NavigateOptions;
}

// DEPRECATED: This should done be at the route loader level; it causes too many re-renders.
export const usePathRedirect = ({
  matchPath,
  to,
  options = { replace: true },
}: RedirectProps) => {
  const match = useMatch(matchPath);
  const resolvedTo = typeof to === "function" ? to() : to;
  const navigate = useNavigate();

  useEffect(() => {
    if (match && resolvedTo !== undefined && resolvedTo !== "") {
      navigate(resolvedTo, options);
    }
  }, [match, resolvedTo, options]);
};

interface ParsedSearchParam<T extends ZodObject<K>, K extends ZodRawShape> {
  schema: T;
}

export const useTypedSearchParams = <
  T extends ZodObject<K>,
  K extends ZodRawShape,
>({
  schema,
}: ParsedSearchParam<T, K>) => {
  const [searchParams] = useSearchParams();

  const requestedKeys = Object.keys(schema.shape);
  if (requestedKeys.length === 0) return;

  try {
    let requestedValues: Record<string, string> = {};

    requestedKeys.forEach((key) => {
      const val = searchParams.get(key);
      if (val !== null) requestedValues[key] = val;
    });

    if (Object.keys(requestedValues).length === 0) return;

    return schema.parse(requestedValues) as ReturnType<T["parse"]>;
  } catch (error) {
    if (error instanceof ZodError) {
      console.error("Error parsing search params", error);
    }
    return;
  }
};

export const useTypedLoaderData = <T extends LoaderFunction>() => {
  const data = useLoaderData() as Exclude<Awaited<ReturnType<T>>, Response>;
  return data;
};
