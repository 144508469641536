import { useMemo } from "react";
import { ValueGetterParams } from "ag-grid-community";

import { PredictionRowsQuery } from "@/gql/graphql";
import { getLabelValue } from "@/lib/getLabelValue";

import { ProductTypeahead } from "../product-typeahead/ProductTypeahead";
import { CustomDescriptionCellRenderer } from "./CustomDescriptionCellRenderer";
import { getConfidenceStyle } from "./getConfidenceStyle";

export type Label = PredictionRowsQuery["predictions"][0]["labels"][0];
export type TransformationPredictionsTableAgGridRow = {
  [key: string]: string | Label;
};

// Call this a node after confirming functionality
export type TransformationPredictionsTableAgGridParams = {
  value: string;
  data: TransformationPredictionsTableAgGridRow;
  setDataValue: (header: string, value: string | Label) => void;
};

type Params = {
  headers: string[];
};

enum TransformationsPredictionsTableFixedColumns {
  PRODUCT_SKU = "productSKU",
  PRODUCT_DESCRIPTION = "productDescription",
}

export const useGetTransformationsPredictionsTableColDefs = ({
  headers,
}: Params) => {
  return useMemo(() => {
    // TODO: figure out how to do this better, I want this enrichment width to be flex cause it's short
    const isEnrichProductType = headers.includes("Product Type");

    const dynamicCols = headers.map((header, i) => {
      const standardColDef = {
        headerName: header,
        filter: true, // Enable filtering on all columns
        editable: true, // Enable editing on all columns
        resizable: true,
        maxWidth: isEnrichProductType ? undefined : 300,
        flex: isEnrichProductType ? 1 : undefined,
        valueSetter: (
          params: TransformationPredictionsTableAgGridParams & {
            newValue: string | Label;
          },
        ) => {
          const newValue = params.newValue;
          if (typeof newValue === "string") {
            params.data[header] = newValue;
          } else {
            params.data[header] = {
              ...newValue,
            };
          }
          return true;
        },
        valueGetter: (params: ValueGetterParams) => {
          const value = params.data[header];
          if (typeof value === "string") {
            return value;
          } else if (value != null) {
            return getLabelValue(value);
          } else {
            return "N/A";
          }
        },
        enableCellChangeFlash: true,
        colId: header,
        cellDataType: "label",
        cellEditor: (params: {
          data: TransformationPredictionsTableAgGridRow;
          node: any;
        }) => {
          const value = params.data[header];

          if (typeof value === "string") {
            return (
              <ProductTypeahead
                labelName={header}
                currValue={value}
                handleSubmitFeedback={() => {}}
              />
            );
          } else if (value != null) {
            const node = params.node;
            const label = params.data[header] as Label;
            const onValueChange = (newValue: string) => {
              const newData = {
                ...label,
                feedback: [
                  ...label.feedback,
                  {
                    content: newValue,
                    upvote: true,
                    id: 6969420,
                  },
                ],
              };

              node?.setDataValue(header, newData);
            };

            const labelValueOrFeedback = getLabelValue(value);

            return (
              <ProductTypeahead
                labelName={header}
                currValue={labelValueOrFeedback}
                handleSubmitFeedback={onValueChange}
              />
            );
          }
        },
        cellClassRules: {
          // TODO: create classes for low confidence, mid confidence, high confidence
          "cell-low": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "low",
            });
          },
          "cell-mid": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "mid",
            });
          },
          "cell-high": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "high",
            });
          },
        },
      };

      if (header === TransformationsPredictionsTableFixedColumns.PRODUCT_SKU) {
        return {
          ...standardColDef,
          headerName: "Manufacturer SKU",
          field: header,
          editable: false,
          cellClassRules: {},
        };
      } else if (
        header ===
        TransformationsPredictionsTableFixedColumns.PRODUCT_DESCRIPTION
      ) {
        return {
          ...standardColDef,
          headerName: "Product Description",
          field: header,
          editable: false,
          cellClassRules: {},
          cellRenderer: CustomDescriptionCellRenderer,
          width: 300,
          maxWidth: undefined,
        };
      } else {
        return standardColDef;
      }
    });
    return [...dynamicCols];
  }, [headers]);
};
