import { match } from "ts-pattern";

import {
  SOURCE_HEADER_NODE_TYPE,
  tabularMappingNodeTypes,
  TARGET_HEADER_NODE_TYPE,
} from "./nodes";
import { TabularMappingProps } from "./TabularMapping";

interface calculateNodePositionProps
  extends Pick<TabularMappingProps, "orientation"> {
  i: number;
  nodeWidth: number;
  nodeHeight: number;
  flowWidth: number;
  flowHeight: number;
  type: keyof typeof tabularMappingNodeTypes;
}

export const calculateNodePosition = (props: calculateNodePositionProps) => {
  const { i, nodeWidth, nodeHeight, flowWidth, flowHeight, orientation, type } =
    props;

  return match({
    orientation,
    type,
  })
    .with({ orientation: "vertical", type: SOURCE_HEADER_NODE_TYPE }, () => {
      return {
        x: 0 + i * nodeWidth,
        y: 0,
      };
    })
    .with({ orientation: "vertical", type: TARGET_HEADER_NODE_TYPE }, () => {
      return {
        x: 0 + i * nodeWidth,
        y: flowHeight - nodeHeight,
      };
    })
    .with({ orientation: "horizontal", type: SOURCE_HEADER_NODE_TYPE }, () => {
      return {
        x: 0,
        y: 0 + i * nodeHeight,
      };
    })
    .with({ orientation: "horizontal", type: TARGET_HEADER_NODE_TYPE }, () => {
      return {
        x: flowWidth - nodeWidth,
        y: 0 + i * nodeHeight,
      };
    })
    .exhaustive();
};
