import { useGetProductTypeaheadItems } from "../../product-typeahead/useGetProductTypeaheadItems";
import { NameFilter } from "../../table/NameFilter";
import { useTableFilterContext } from "../../table/TableFiltersContext";

export const ProductNameFilter = () => {
  const { searchNameFilter } = useTableFilterContext();
  const names = useGetProductTypeaheadItems({
    searchField: "name",
    typeahead: searchNameFilter,
  });

  return (
    <NameFilter typeaheadItems={names} placeholder="Search product names..." />
  );
};
