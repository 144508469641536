import { useState } from "react";
import { subDays } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { SubmissionPeriodFilterDialog } from "./submission-period/SubmissionPeriodFilterDialog";
import { SubmissionPeriod } from "./TableFiltersContext";

const predefinedRanges = [
  { label: "No date range override", from: undefined, to: undefined },
  { label: "Today", from: new Date(), to: new Date() },
  {
    label: "Yesterday",
    from: subDays(new Date(), 1),
    to: subDays(new Date(), 1),
  },
  { label: "Last 24 hours", from: subDays(new Date(), 1), to: new Date() },
  { label: "Last 7 days", from: subDays(new Date(), 7), to: new Date() },
  { label: "Last 14 days", from: subDays(new Date(), 14), to: new Date() },
  { label: "Last 30 days", from: subDays(new Date(), 30), to: new Date() },
  { label: "Last 90 days", from: subDays(new Date(), 90), to: new Date() },
  { label: "Last 180 days", from: subDays(new Date(), 180), to: new Date() },
  {
    label: "This month",
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    to: new Date(),
  },
  {
    label: "Year to date",
    from: new Date(new Date().getFullYear(), 0, 1),
    to: new Date(),
  },
  { label: "All time", from: new Date(0), to: new Date() },
];

type Props = {
  dateRangeFilter: DateRange | undefined;
  setDateRangeFilter: (dateRange: DateRange | undefined) => void;
  selectedSubmissionPeriods: SubmissionPeriod[];
  setSelectedSubmissionPeriods: React.Dispatch<
    React.SetStateAction<SubmissionPeriod[]>
  >;
};

export const DateRangeFilter = ({
  dateRangeFilter,
  setDateRangeFilter,
  selectedSubmissionPeriods,
  setSelectedSubmissionPeriods,
}: Props) => {
  const [openSubmissionPeriodDialog, setOpenSubmissionPeriodDialog] =
    useState(false);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);

  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    undefined,
  );

  const onValueChange = (value: string) => {
    const newSelectedRange = predefinedRanges.find(
      (range) => range.label === value,
    );
    if (newSelectedRange) {
      setSelectedRange({
        from: newSelectedRange.from,
        to: newSelectedRange.to,
      });
      setDateRangeFilter({
        from: newSelectedRange.from,
        to: newSelectedRange.to,
      });
    }
  };

  const handleDateRangeSelect = (range: DateRange | undefined) => {
    setSelectedRange(range);
    if (range?.from && range?.to) {
      setDateRangeFilter({ from: range.from, to: range.to });
      setOpenDateRangePicker(false);
    }
  };

  return (
    <>
      <SubmissionPeriodFilterDialog
        selectedSubmissionPeriods={selectedSubmissionPeriods}
        setSelectedSubmissionPeriods={setSelectedSubmissionPeriods}
        open={openSubmissionPeriodDialog}
        setOpen={setOpenSubmissionPeriodDialog}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size={"icon"} className="p-2">
            {dateRangeFilter ? (
              <>
                {dateRangeFilter.from != null && dateRangeFilter.to != null ? (
                  <>
                    {dateRangeFilter.from.toLocaleDateString()} -{" "}
                    {dateRangeFilter.to.toLocaleDateString()}
                  </>
                ) : (
                  <Icons.calendar className=" h-5 text-primary/90" />
                )}
              </>
            ) : (
              <Icons.calendar className=" h-5 text-primary/90" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="h-72 w-56 overflow-auto scrollbar-hide">
          <DropdownMenuGroup>
            <span
              onClick={() => setOpenSubmissionPeriodDialog(true)}
              className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
            >
              Select by Submission Period
            </span>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {predefinedRanges.map((range) => (
              <DropdownMenuItem
                key={range.label}
                onClick={() => onValueChange(range.label)}
              >
                <span>{range.label}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => setOpenDateRangePicker(true)}>
              <span>Custom range</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      {openDateRangePicker && (
        <div className="absolute z-10 mt-2 rounded-md border bg-black">
          <DayPicker
            mode="range"
            selected={selectedRange}
            onSelect={handleDateRangeSelect}
          />
        </div>
      )}
    </>
  );
};
