import { Search, icons as _icons } from "lucide-react";
import * as React from "react";
import { VirtuosoGrid } from "react-virtuoso";

// import categories json
import categories from "./data/categories.json";
import tags from "./data/tags.json";

import { Button } from "../ui/button";
import { ValueOf } from "@/lib/typeHelpers";
import { cn } from "@/lib/cn";
import { useMeasure } from "react-use";
import { PopoverContent } from "../ui/popover";
import { Input } from "../ui/input";
import { convertCase } from "@/lib/string";
import { useDeferredValue, useState } from "react";

// extend popover content props
interface Props extends React.ComponentProps<typeof PopoverContent> {
  onIconSelect: (icon: string) => void;
}

const gridComponents = {
  List: React.forwardRef<HTMLDivElement>(
    (
      { style, children, ...props }: React.HTMLAttributes<HTMLDivElement>,
      ref,
    ) => (
      <div
        ref={ref}
        {...props}
        className=""
        style={{
          display: "flex",
          flexWrap: "wrap",
          ...style,
        }}
      >
        {children}
      </div>
    ),
  ),
  Item: ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      {...props}
      style={{
        width: "calc(100% % 32px)",
        display: "flex",
        flex: "none",
        alignContent: "stretch",
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  ),
};

interface Icon {
  name: string;
  tags: string[];
  Component: ValueOf<typeof _icons>;
}

const iconCategories = Object.entries(categories).reduce(
  (acc, [category, iconNames]) => {
    const comps = iconNames.reduce((acc, name) => {
      const formattedName = convertCase(name, "pascal");
      const icon = _icons[formattedName as keyof typeof _icons];

      if (icon) {
        acc.push({
          name: formattedName,
          Component: icon,
          tags: tags[name as keyof typeof tags],
        });
      }
      return acc;
    }, [] as Icon[]);

    acc[category] = comps;
    return acc;
  },
  {} as Record<string, Icon[]>,
);

const uniqueNames = new Set<string>();

const iconCategoriesFlattened = Object.values(iconCategories)
  .flat()
  .filter((icon) => {
    if (uniqueNames.has(icon.name)) return false;
    uniqueNames.add(icon.name);
    return true;
  });

export const LucidePicker = ({ onIconSelect, className, ...props }: Props) => {
  const [popoverContentRef, { height: popoverHeight }] =
    useMeasure<HTMLDivElement>();
  const [filter, setFilter] = useState("");
  const deferredFilter = useDeferredValue(filter);

  const icons = React.useMemo(() => {
    if (deferredFilter === "") return iconCategoriesFlattened;
    return iconCategoriesFlattened.filter(
      (icon) =>
        icon.name.toLowerCase().includes(deferredFilter.toLowerCase()) ||
        icon.tags.some((tag) =>
          tag.toLowerCase().includes(deferredFilter.toLowerCase()),
        ),
    );
  }, [deferredFilter]);

  return (
    <PopoverContent
      ref={popoverContentRef}
      onCloseAutoFocus={() => setFilter("")}
      className={cn("grid h-80 w-96 grid-rows-[auto_1fr] gap-4 p-3", className)}
      {...props}
    >
      <div className="relative w-full">
        <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2" />
        <Input
          className="h-7 w-full px-2 pl-7 text-sm"
          placeholder="Search icons..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        ></Input>
      </div>

      <VirtuosoGrid
        className={cn("self-stretch", icons.length === 0 && "opacity-0")}
        style={{ height: popoverHeight - 52 }}
        totalCount={icons.length}
        components={gridComponents}
        itemContent={(index) => {
          const icon = icons[index];

          return (
            <Button
              key={icon.name}
              variant={"ghost"}
              onClick={() => onIconSelect(icon.name)}
              className="h-8 w-8 text-muted-foreground"
              aria-label={icon.name}
            >
              <icon.Component className="h-5 w-5 shrink-0" />
            </Button>
          );
        }}
      />
      {icons.length === 0 && (
        <div className="absolute inset-0 flex h-full w-full items-center justify-center pt-10">
          <p className="text-center text-sm text-muted-foreground">
            No icons found
          </p>
        </div>
      )}
    </PopoverContent>
  );
};
