import { graphql } from "@/gql";
import { Submission } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getSubmissionTransformationsQueryOptions = (
  submissionId: Submission["id"],
) => {
  return createQueryOptions({
    query: graphql(`
      query getSubmissionTransformations(
        $where: SubmissionWhereUniqueInput!
        $transformationsWhere2: TransformationWhereInput
      ) {
        submission(where: $where) {
          transformations(where: $transformationsWhere2) {
            processed
            outputFile {
              name
              id
            }
            id
            name
            status
            type {
              templateId
              name
              id
              description
            }
            metadata {
              key
              value
            }
          }
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
    },
  });
};
