import { useEffect, useRef, useState } from "react";

import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const SubmissionChartCardSkeleton = () => {
  return (
    <Card className="col-span-1 row-span-1 flex flex-col p-2">
      <Tabs defaultValue="GPU" className="flex w-full flex-col">
        <TabsList className="w-fit self-end">
          <TabsTrigger value="GPU">GPU</TabsTrigger>
          <TabsTrigger value="CPU">CPU</TabsTrigger>
        </TabsList>
        <TabsContent value="GPU">
          <SkeletonPieChart />
        </TabsContent>
        <TabsContent value="CPU">
          <SkeletonPieChart />
        </TabsContent>
      </Tabs>
    </Card>
  );
};

const SkeletonPieChart = () => {
  const ref = useRef(null);
  const [_dimensions, setDimensions] = useState({ width: 300, height: 300 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  return (
    <div ref={ref} className="flex h-full w-full items-center justify-center">
      <div className="h-64 w-64 animate-pulse rounded-full bg-muted"></div>
    </div>
  );
};
