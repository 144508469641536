import { useCallback, useMemo, useState } from "react";
import { CellValue, Worksheet } from "exceljs";
import { Shuffle } from "lucide-react";
import { match, P } from "ts-pattern";

import { Icons } from "@/components/Icons";
import { TextShimmer } from "@/components/motion/text-shimmer";
import { TabularMapping } from "@/components/reactflow/tabular-mapping/TabularMapping";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { BackButton } from "@/components/BackButton";
import { getWorksheetHeaders } from "@/lib/fileUtils";

type Props = {
  worksheet: Worksheet;
  initialMapping?: {
    sourceHeader: string;
    targetHeader: string;
  }[];
  targetHeaders: string[];
  onSaveMapping: (mapping: Record<string, string>) => void;
  isPending?: boolean;
  buttonText: string;
  showBackButton?: boolean;
};

export const NormalizeMapping = ({
  worksheet,
  initialMapping,
  targetHeaders,
  onSaveMapping,
  isPending = false,
  buttonText,
  showBackButton = false,
}: Props) => {
  const [exampleDataIdx, setExampleDataIdx] = useState(2);

  const inputFileHeaders =
    useMemo(() => {
      if (worksheet) {
        const headers = getWorksheetHeaders(worksheet);

        return match(headers)
          .with(P.array(), (cells) => {
            return cells.slice(1) as string[];
          })
          .otherwise(() => {
            console.error("Invalid row values");
            // throw new Error("Invalid row values");
          });
      }
    }, [worksheet]) ?? ([] as string[]);

  const sourceHeaders =
    initialMapping && initialMapping.length > 0
      ? initialMapping.map((m) => m.sourceHeader)
      : inputFileHeaders;

  const inputFileExampleRowData = useMemo(() => {
    if (!inputFileHeaders || !worksheet) return;

    const _row = worksheet.getRow(exampleDataIdx);

    const rowVals = match(_row.values)
      .with(P.array(), (_rowVals) => {
        return _rowVals.slice(1);
      })
      .otherwise(() => {
        throw new Error("Invalid row values");
      });

    // if (rowVals.length !== inputFileHeaders.length) {
    //   console.log({ rowVals, inputFileHeaders });
    //   throw new Error("Row values do not match headers");
    // }

    return rowVals.reduce(
      (acc, val, i) => {
        acc[inputFileHeaders[i]] = val;
        return acc;
      },
      {} as Record<string, CellValue>,
    );
  }, [worksheet, inputFileHeaders, exampleDataIdx]);

  const setRandomExampleDataIdx = useCallback(() => {
    if (!worksheet) return;
    const lastRow = worksheet.rowCount; // 1-based

    setExampleDataIdx(Math.floor(Math.random() * (lastRow - 2) + 2));
  }, [setExampleDataIdx, worksheet]);

  const [newMapping, setNewMapping] = useState<Record<string, string>>({});

  return (
    <div className="relative isolate h-full p-6 pt-16">
      <div className="absolute left-0 top-[10px] flex h-12 w-full transform items-center justify-between gap-2 px-6 ">
        <Button
          variant="secondary"
          className="z-40 h-8 gap-2 rounded-full px-3 py-1.5 text-sm"
          onClick={setRandomExampleDataIdx}
          disabled={!worksheet}
        >
          <Shuffle size={16} />
          <TextShimmer>Show Next Row</TextShimmer>
        </Button>
        <div className="space-x-2">
          <Button variant={"outline"} onClick={() => onSaveMapping(newMapping)}>
            {buttonText}
            {isPending ? (
              <Spinner className="ml-2" />
            ) : (
              <Icons.reprocess className="ml-2 h-4 w-4" />
            )}
          </Button>
          {showBackButton && <BackButton relative="history" />}
        </div>
      </div>

      {inputFileExampleRowData && (
        <TabularMapping
          className="isolate h-full"
          orientation="horizontal"
          relationship="many-to-one"
          sourceHeaders={sourceHeaders}
          targetHeaders={targetHeaders}
          initialMapping={initialMapping}
          exampleRowData={inputFileExampleRowData ?? {}}
          onMappingChange={(_mapping) => {
            const mapping = Object.fromEntries(
              _mapping.map(({ sourceHeader, targetHeader }) => [
                sourceHeader,
                targetHeader,
              ]),
            );
            if (_mapping.length === 0) return;

            setNewMapping(mapping);
          }}
        />
      )}
    </div>
  );
};
