import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/cn";
import { getVendorSubmissionTypesQueryOptions } from "@/lib/hooks/queries/SubmissionTypes";

export const DataStewardshipSettings = () => {
  const { vendorId: vId } = useParams();
  const vendorId = Number(vId);
  const { data: submissionTypeData } = useSuspenseQuery(
    getVendorSubmissionTypesQueryOptions(vendorId),
  );
  const vendorSubmissionTypes =
    submissionTypeData.vendor?.submissionTypes ?? [];

  const formSchema = z.object({
    vendorId: z.string(),
    vendorName: z.string(),
    contactEmail: z.string(),
    approvedEmailList: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vendorId: "12345",
      vendorName: "Demo Vendor",
      contactEmail: "submissions@vendor.com",
      approvedEmailList: "matt@vendor.com, josh@vendor.com",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
  }

  const [show, setShow] = useState(false);

  return (
    <Card>
      <CardHeader className=" flex flex-row  justify-between">
        <div className="flex flex-col gap-1">
          <CardTitle>Data Stewardship</CardTitle>
          <CardDescription>
            Update your data stewardship settings
          </CardDescription>
        </div>
        <Button onClick={() => setShow(!show)} className="" variant={"outline"}>
          {show ? (
            <Icons.chevronsUp className="h-4 w-4" />
          ) : (
            <Icons.chevronsDown className="h-4 w-4" />
          )}
        </Button>
      </CardHeader>
      {show && (
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid grid-cols-2 gap-8"
            >
              <FormField
                control={form.control}
                name="approvedEmailList"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Approved Submission Emails</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="matt@vendor.com, joe@vendor.com"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Input valid emails separated by commas
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="col-span-2">
                <FormLabel>View Submission Types Mappings</FormLabel>
                <div className="flex gap-4 pt-2">
                  {vendorSubmissionTypes.map((submissionType, idx) => {
                    return (
                      <Card
                        className={cn(
                          "relative z-20 h-full w-full cursor-pointer overflow-hidden rounded-2xl border  p-4 hover:bg-black group-hover:border-slate-700",
                        )}
                      >
                        <CardHeader className="flex items-center justify-center">
                          {/* TODO: need icon on submissiontypes */}
                          {idx === 0 ? (
                            <Icons.package className="h-20 w-20" />
                          ) : (
                            <Icons.invoice className="h-20 w-20" />
                          )}
                        </CardHeader>
                        <CardContent className="text-center">
                          <p className="text-lg">{submissionType.name}</p>
                          <p className=" text-sm font-extralight text-primary/70">
                            {/* TODO: flesh out description */}
                            {submissionType.description}
                          </p>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </div>

              <div className="col-span-2 ">
                <Button
                  className="float-right"
                  variant={"outline"}
                  type="submit"
                >
                  Update Settings
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      )}
    </Card>
  );
};
