import { Icons } from "@/components/Icons";

export function Help() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center ">
      <Icons.help className="h-24 w-24 text-blue-600" />
      <div className="mt-12 text-center">
        <h1 className="mb-4 text-3xl font-bold">How can we help?</h1>
        <p className="text-lg">
          Check out our technical documentation or contact us at{" "}
          <a
            href="mailto:support@datasteward.ai"
            className="text-blue-600 hover:underline"
          >
            support@datasteward.ai
          </a>
        </p>
      </div>
    </div>
  );
}
