import { useAutoAnimate } from "@formkit/auto-animate/react";

import { PageHeader } from "@/components/PageHeader";
import { ProductsTableWrapper } from "@/components/shared-components/products-table/ProductsTableWrapper";
import { easeSnappy } from "@/lib/motion";

import { DashboardScreen } from "../DashboardScreen";

export function ProductsPage() {
  const [content] = useAutoAnimate({
    duration: 200,
    easing: easeSnappy,
  });

  return (
    <DashboardScreen className="">
      <main ref={content} className="">
        <PageHeader title={"Products"} />
        <ProductsTableWrapper />
      </main>
    </DashboardScreen>
  );
}
