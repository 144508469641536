import { useParams } from "react-router-dom";

import { TransformationStatus } from "@/gql/graphql";

import { getSubmissionTransformationsQueryOptions } from "../../../components/shared-components/transformation-predictions-table/getSubmissionTransformationsQueryOptions";
import { useUpdateTransformation } from "./useUpdateTransformation";

export const useApproveTransformation = () => {
  const { submissionId: pId } = useParams();
  const submissionId = Number(pId);
  const queryOptions = getSubmissionTransformationsQueryOptions(submissionId);
  const { mutate } = useUpdateTransformation({
    queryKey: queryOptions.queryKey,
  });

  const approveTransformation = (
    transformationId: number,
    currStatus: TransformationStatus,
  ) => {
    currStatus === TransformationStatus.Approved
      ? mutate({
          data: {
            status: {
              set: TransformationStatus.Pending,
            },
          },
          where: {
            id: transformationId,
          },
        })
      : mutate({
          data: {
            status: {
              set: TransformationStatus.Approved,
            },
          },
          where: {
            id: transformationId,
          },
        });
  };
  return { approveTransformation };
};
