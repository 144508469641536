import { memo, Ref, useCallback, useEffect } from "react";
import { useDebounce, useKey, useMeasure } from "react-use";
import {
  addEdge,
  Background,
  Edge,
  EdgeChange,
  getIncomers,
  getOutgoers,
  NodeChange,
  OnConnect,
  OnConnectEnd,
  OnConnectStart,
  OnEdgesChange,
  OnEdgesDelete,
  OnNodesChange,
  ReactFlow,
  SelectionMode,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { match } from "ts-pattern";

import { CardContent } from "@/components/ui/card";
import { cn } from "@/lib/cn";
import useUndoRedo from "@/lib/hooks/reactflow/useUndoRedo";

import { useIsValidDataConnection } from "../functional/nodes/FunctionNode";
import { createInitialEdges } from "./createInitialEdges";
import { createNodes } from "./createNodes";
import { TABULARMAPPING_EDGE_TYPE, tabularMappingEdgeTypes } from "./edges";
import {
  SOURCE_HEADER_NODE_TYPE,
  tabularMappingNodeTypes,
  TARGET_HEADER_NODE_TYPE,
} from "./nodes";
import { HeaderNode } from "./nodes/HeaderNode";
import { useTabularMappingStore } from "./store";

const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

export type TabularMappingNode = HeaderNode;

export interface TabularMappingProps
  extends React.HTMLAttributes<HTMLDivElement> {
  relationship?: "many-to-one";
  orientation: "horizontal" | "vertical";

  sourceHeaders: string[];
  targetHeaders: string[];
  initialMapping?: {
    sourceHeader: string;
    targetHeader: string;
  }[];

  exampleRowData: Record<string, any>;

  onMappingChange?: (
    mapping: NonNullable<TabularMappingProps["initialMapping"]>,
  ) => void;
}

function _TabularMapping({
  relationship = "many-to-one",
  orientation,
  sourceHeaders,
  targetHeaders,
  initialMapping,
  onMappingChange,
  exampleRowData,
  className,
  ...props
}: TabularMappingProps) {
  const [flowRef, { width: flowWidth, height: flowHeight }] = useMeasure();
  const { undo, redo, takeSnapshot } = useUndoRedo();
  const [edges, setEdges, _onEdgesChange] = useEdgesState(
    createInitialEdges(initialMapping),
  );

  const [nodes, setNodes, _onNodesChange] = useNodesState(
    createNodes({
      sourceHeaders,
      targetHeaders,
      orientation,
      flowWidth,
      flowHeight,
      exampleRowData,
      edges,
    }),
  );
  const { getNode } = useReactFlow();

  const { isValidDataConnection } = useIsValidDataConnection();

  const {
    setSpotlightedNodes,
    setInspectedNode,
    setConnectingNode,
    inspectedNode,
    connectingNode,
  } = useTabularMappingStore();

  // useEffect(() => {
  //   setSelectedNodes(_selectedNodes);
  // }, [_selectedNodes]);

  useEffect(() => {
    const spotlightSet = new Set<HeaderNode>();

    if (inspectedNode) spotlightSet.add(inspectedNode);
    if (connectingNode) spotlightSet.add(connectingNode);

    // add selected nodes to spotlighted
    nodes
      .filter((node) => node.selected)
      ?.forEach((node) => {
        spotlightSet.add(node);
      });

    const spotlightSources = Array.from(spotlightSet);

    spotlightSources.forEach((node) => {
      match(node.type as keyof typeof tabularMappingNodeTypes)
        .with(SOURCE_HEADER_NODE_TYPE, () => {
          getOutgoers(node, nodes, edges)?.forEach((outgoer: HeaderNode) => {
            spotlightSet.add(outgoer);
          });
        })
        .with(TARGET_HEADER_NODE_TYPE, () => {
          getIncomers(node, nodes, edges)?.forEach((incomer: HeaderNode) => {
            spotlightSet.add(incomer);
          });
        })
        .exhaustive();
    });

    setSpotlightedNodes(Array.from(spotlightSet));
  }, [inspectedNode, connectingNode, nodes, edges]);

  // undo/redo actions
  useKey(
    "z",
    ({ metaKey, shiftKey }) => {
      if (!metaKey) return;
      shiftKey ? redo() : undo();
    },
    undefined,
    [undo, redo],
  );

  const onConnect = useCallback<OnConnect>(
    (connection) => {
      // 👇 allows adding an edge to be undoable
      takeSnapshot();
      setEdges((_eds) => {
        // TODO: depend on the relationship
        const eds = _eds.filter((edge) => edge.source !== connection.source);

        const newEdges = addEdge(connection, eds).map((edge) => ({
          ...edge,
          type: TABULARMAPPING_EDGE_TYPE,
          animated: true,
        })) as Edge[];

        const mapping = newEdges.reduce(
          (acc, edge) => {
            const sourceNode = getNode(edge.source);
            const targetNode = getNode(edge.target);

            if (!sourceNode || !targetNode) return acc;

            acc.push({
              sourceHeader: sourceNode.data.header,
              targetHeader: targetNode.data.header,
            });
            return acc;
          },
          [] as NonNullable<TabularMappingProps["initialMapping"]>,
        );
        onMappingChange?.(mapping);
        return newEdges;
      });
    },
    [setEdges, takeSnapshot],
  );

  const onEdgesDelete = useCallback<OnEdgesDelete>(
    (deletedEdges) => {
      const mapping = edges.reduce(
        (acc, edge) => {
          const sourceNode = getNode(edge.source);
          const targetNode = getNode(edge.target);

          if (!sourceNode || !targetNode) return acc;

          const isDeletedEdge = deletedEdges.some(
            (deletedEdge) => deletedEdge.source === edge.source,
          );

          if (isDeletedEdge) {
            // do not add the deleted edge to the mapping
          } else {
            acc.push({
              sourceHeader: sourceNode.data.header,
              targetHeader: targetNode.data.header,
            });
          }

          return acc;
        },
        [] as NonNullable<TabularMappingProps["initialMapping"]>,
      );

      onMappingChange?.(mapping);
      // 👇 allows deleting an edge to be undo-able
      takeSnapshot();
    },
    [takeSnapshot],
  );

  const onNodesChange = useCallback<OnNodesChange>(
    (changes: NodeChange[]) => {
      // prevents removing nodes
      const filteredChanges: NodeChange[] = changes.filter((change) => {
        return change.type !== "remove";
      });
      _onNodesChange(filteredChanges);
    },
    [_onNodesChange],
  );

  const onEdgesChange = useCallback<OnEdgesChange>(
    (_changes: EdgeChange[]) => {
      const changes = _changes.map((change) => {
        if (change.type === "add") {
          change.item.animated = true;
        }
        return change;
      });

      _onEdgesChange(changes);
    },
    [_onEdgesChange, takeSnapshot],
  );

  const onConnectStart = useCallback<OnConnectStart>(
    (e, { nodeId }) => {
      if (!nodeId) {
        console.error("nodeId not found - onConnectStart");
        return;
      }
      const node = getNode(nodeId);
      if (!node) {
        console.error("node not found - onConnectStart");
        return;
      }

      setConnectingNode(node);
    },
    [getNode, setConnectingNode],
  );

  const onConnectEnd = useCallback<OnConnectEnd>(
    (e) => {
      setConnectingNode(null);
      setNodes((nodes) =>
        nodes.map((node) => {
          return { ...node, selected: false };
        }),
      );
    },
    [setConnectingNode],
  );

  // reset nodes on param change
  useDebounce(
    () => {
      setNodes(
        createNodes({
          sourceHeaders,
          targetHeaders,
          orientation,
          flowWidth,
          flowHeight,
          exampleRowData,
          edges,
          // spotlightedNodesMap,
        }),
      );
    },
    10,
    [
      sourceHeaders,
      targetHeaders,
      orientation,
      setNodes,
      flowWidth,
      flowHeight,
      exampleRowData,
      edges,
      // spotlightedNodesMap,
    ],
  );

  return (
    <CardContent
      className={cn(
        "relative overflow-hidden rounded-md border-[1.5px] border-card bg-[#19191A] bg-opacity-40 p-0",
        className,
      )}
      {...props}
    >
      <style>
        {`
          .react-flow__pane {
            cursor: default;
          }
          .react-flow__nodesselection-rect {
            border: inherit;
            background: inherit;
          }
        `}
      </style>
      <ReactFlow
        ref={flowRef as Ref<HTMLDivElement>}
        nodes={nodes}
        nodeTypes={tabularMappingNodeTypes}
        edges={edges}
        edgeTypes={tabularMappingEdgeTypes}
        onConnect={onConnect}
        isValidConnection={isValidDataConnection}
        className="ease-in-out"
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onEdgeUpdate={onEdgeUpdate}
        onEdgesDelete={onEdgesDelete}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodeMouseEnter={(e, node) => {
          setInspectedNode(node);
        }}
        onNodeMouseLeave={() => {
          setInspectedNode(null);
        }}
        proOptions={proOptions}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        panOnDrag={false}
        panOnScroll={false}
        autoPanOnNodeDrag={false}
        autoPanOnConnect={false}
        selectionOnDrag
        elevateEdgesOnSelect
        selectionMode={SelectionMode.Partial}
      >
        <Background />
      </ReactFlow>
    </CardContent>
  );
}

export const TabularMapping = memo(_TabularMapping);
