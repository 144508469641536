import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateOneVendor = () => {
  return useGraphqlMutation({
    mutationKey: ["createOneVendor"],
    mutation: graphql(`
      mutation CreateOneVendor($data: VendorCreateInput!) {
        createOneVendor(data: $data) {
          id
          name
          vendorId
        }
      }
    `),
  });
};
