import { useMemo } from "react";
import Excel from "exceljs";

import { getHeaderValue } from "./getHeaderValue";

type Props = {
  worksheet: Excel.Worksheet | undefined;
};

export const useGetValidationTableHeadersAndRows = ({ worksheet }: Props) => {
  const headers = worksheet ? worksheet.getRow(1) : [];
  const rows = worksheet
    ? worksheet.getRows(2, worksheet.lastRow?.number ?? 1)
    : [];
  const headerValues = useMemo(() => {
    return Array.isArray(headers?.values)
      ? headers.values
          .map((val) => {
            return getHeaderValue(val);
          })
          .slice(1)
      : [];
  }, [headers]);

  const rowsValues = useMemo(() => {
    return (
      rows?.reduce(
        (acc, row) => {
          const rowValues = Array.isArray(row?.values) ? row.values : [];
          if (rowValues.length > 0) {
            acc.push({
              rowIndex: row.number,
              ...rowValues.reduce(
                (acc, val, i) => {
                  const header = headerValues[i - 1];
                  if (header) {
                    // Ensure header is not an empty string or undefined
                    acc[header] = val;
                  }
                  return acc;
                },
                {} as { [key: string]: Excel.CellValue },
              ),
            });
          }
          return acc;
        },
        [] as { [key: string]: Excel.CellValue }[],
      ) ?? []
    );
  }, [rows, headerValues]);

  return {
    headers,
    rows,
    headerValues,
    rowsValues,
  };
};
