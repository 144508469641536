import { ScrollArea } from "@/components/ui/scroll-area";
import { NormalColumnsEdit } from "./(content)/(normalize-transformation-edit)/normal-columns-edit";

export const SubmissionTypeEditPage = () => {
  return (
    <ScrollArea>
      <NormalColumnsEdit className="pb-6" />
    </ScrollArea>
  );
};
