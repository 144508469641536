import { TimePeriod } from "@/app/dashboard/home/DatePickerWithRange";
import { startOfToday, subDays } from "date-fns";
import { DateRange } from "react-day-picker";

type Props = {
  dateRange: DateRange;
  dateRangeType: TimePeriod;
};

const today = startOfToday();
export const getDateRangesForQuery = ({ dateRange, dateRangeType }: Props) => {
  let prevDateRangeFrom = null;
  let prevDateRangeTo = null;

  if (dateRangeType === TimePeriod.WEEK) {
    prevDateRangeFrom = subDays(today, 14);
    prevDateRangeTo = subDays(today, 7);
  } else if (dateRangeType === TimePeriod.MONTH) {
    prevDateRangeFrom = subDays(today, 60);
    prevDateRangeTo = subDays(today, 30);
  } else if (dateRangeType === TimePeriod.YEAR) {
    prevDateRangeFrom = subDays(today, 730);
    prevDateRangeTo = subDays(today, 365);
  }

  return {
    currWhere: {
      createdAt: {
        gte: dateRange.from,
      },
    },
    prevWhere: {
      createdAt: {
        gte: prevDateRangeFrom,
        lt: prevDateRangeTo,
      },
    },
  };
};
