import { Outlet } from "react-router-dom";

import { SettingsNav } from "./SettingsNav";

export function SettingsLayout() {
  return (
    <div className="grid max-h-screen grid-cols-[auto_1fr] overflow-hidden p-0">
      <SettingsNav />
      <Outlet />
    </div>
  );
}
