import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useParams } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";
import { easeSnappy } from "@/lib/motion";

import {
  SubmissionTypeItem,
  SubmissionTypeItemSkeleton,
} from "./SubmissionTypeItem";
// import { Icons } from "@/components/Icons";
// import { SubmissionPeriodForm } from "./SubmissionPeriodForm";

export function OrgSubmissionTypePage() {
  const { organizationId } = useParams();
  const [parent] = useAutoAnimate({
    duration: 150,
    easing: easeSnappy,
  });

  const { data, isLoading } = useGraphqlQuery({
    queryKey: ["submission-types", `organizationId=${organizationId}`],
    query: graphql(`
      query OrgSubmissionTypes($where: SubmissionTypeWhereInput) {
        submissionTypes(where: $where) {
          description
          id
          name
          icon
          updatedAt
          vendors {
            id
          }
        }
      }
    `),
    variables: {
      where: {
        organizationId: {
          equals: Number(organizationId),
        },
      },
    },
  });

  return (
    <DashboardScreen scrollable>
      <div className="mb-4 flex max-w-3xl items-center justify-between">
        <h2 className="flex items-center text-2xl font-semibold">
          Submissions
        </h2>
        <Button disabled className="w-48">
          Create new submission
        </Button>
      </div>

      <div className="flex flex-col gap-4" ref={parent}>
        {isLoading ? (
          <>
            <SubmissionTypeItemSkeleton key="loader-1" />
            <SubmissionTypeItemSkeleton key="loader-2" />
          </>
        ) : data && data.submissionTypes.length > 0 ? (
          data.submissionTypes.map((submissionType, i) => (
            <SubmissionTypeItem
              key={submissionType.id + "-" + i}
              submissionType={submissionType}
            />
          ))
        ) : (
          <Card className="font-medum max-w-3xl text-lg text-muted-foreground">
            <CardContent className="flex justify-center pt-6">
              No Submission Types found
            </CardContent>
          </Card>
        )}
      </div>
    </DashboardScreen>
  );
}
