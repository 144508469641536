import { useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  MouseSensor,
  TouchSensor,
} from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/Column/CustomDnDSensors";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { SubmissionPeriod } from "../TableFiltersContext";
import { Draggable } from "./Draggable";
import { Droppable } from "./Droppable";
import { getSubmissionPeriods } from "./getSubmissionPeriods";
import { SubmissionPeriodItem } from "./SubmissionPeriodItem";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedSubmissionPeriods: SubmissionPeriod[];
  setSelectedSubmissionPeriods: React.Dispatch<
    React.SetStateAction<SubmissionPeriod[]>
  >;
};

export const SubmissionPeriodFilterDialog = ({
  open,
  setOpen,
  selectedSubmissionPeriods,
  setSelectedSubmissionPeriods,
}: Props) => {
  const past3years = Array.from({ length: 3 }, (_, i) =>
    String(new Date().getFullYear() - i),
  );
  const [selectedYear, setSelectedYear] = useState(past3years[0]);

  const submissionPeriods = getSubmissionPeriods({ selectedYear });

  const unselectedSubmissionPeriods = submissionPeriods.filter(
    (period) =>
      !selectedSubmissionPeriods.some(
        (selectedPeriod) => selectedPeriod.label === period.label,
      ),
  );

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  function handleDragStart(event: DragStartEvent) {
    setActiveItem(String(event.active.id));
  }
  function handleDragEnd(event: DragEndEvent) {
    const { over } = event;

    setActiveItem(null);

    if (over == null) {
      return;
    }

    if (over.id === "selected") {
      const draggedSubmissionPeriod = submissionPeriods.find(
        (period) => period.label === event.active.id,
      );
      if (draggedSubmissionPeriod != null) {
        setSelectedSubmissionPeriods([
          ...selectedSubmissionPeriods,
          draggedSubmissionPeriod,
        ]);
      }
    } else if (over.id === "unselected") {
      const draggedSubmissionPeriod = selectedSubmissionPeriods.find(
        (period) => period.label === event.active.id,
      );
      if (draggedSubmissionPeriod != null) {
        setSelectedSubmissionPeriods(
          selectedSubmissionPeriods.filter(
            (period) => period.label !== draggedSubmissionPeriod.label,
          ),
        );
      }
    }
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <DragOverlay>
        {activeItem ? (
          <Draggable key={activeItem} id={activeItem}>
            {activeItem}
          </Draggable>
        ) : null}
      </DragOverlay>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogContent className=" max-w-[940px]">
          <DialogHeader>
            <DialogTitle>Select Submission Periods</DialogTitle>
          </DialogHeader>

          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex h-12 items-center justify-between">
                <h2>Unselected</h2>
                <Select
                  defaultValue={past3years[0]}
                  onValueChange={setSelectedYear}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {past3years.map((year) => {
                        return (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <Droppable
                key={"unselected"}
                id={"unselected"}
                className={
                  "z-40 flex h-[400px] flex-wrap items-start justify-start gap-2 overflow-auto rounded-md border p-2 scrollbar-hide"
                }
              >
                {unselectedSubmissionPeriods.map((period) => {
                  return (
                    <SubmissionPeriodItem key={period.label} period={period} />
                  );
                })}
              </Droppable>
            </div>
            <div className="flex h-full flex-col gap-2">
              <div className="flex h-12 items-center justify-between">
                <h2>Selected</h2>
              </div>
              <Droppable
                key={"selected"}
                id={"selected"}
                className={
                  "z-40 flex h-[400px] flex-row flex-wrap items-start justify-start gap-2 overflow-auto rounded-md border p-2 scrollbar-hide"
                }
              >
                {selectedSubmissionPeriods.map((period) => {
                  return (
                    <SubmissionPeriodItem key={period.label} period={period} />
                  );
                })}
              </Droppable>
            </div>
          </div>

          <DialogFooter>
            <Button
              type="submit"
              variant={"destructive"}
              onClick={() => {
                setSelectedSubmissionPeriods([]);
                setOpen(false);
              }}
            >
              Clear All
            </Button>
            <Button onClick={() => setOpen(false)}>Done</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </DndContext>
  );
};
