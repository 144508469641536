import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  submissionId: number;
};

export const useGetSubmissionOutputFile = ({ submissionId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetSubmissionOutputFile", submissionId],
    query: graphql(`
      query Submission($where: SubmissionWhereUniqueInput!) {
        submission(where: $where) {
          outputFile {
            id
            name
          }
        }
      }
    `),
    variables: { where: { id: submissionId } },
  });
};
