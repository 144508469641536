import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  transformationId: number | undefined;
};

export const useGetTransformationType = ({ transformationId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetTransformationType", `transformationId=${transformationId}`],
    query: graphql(`
      query GetTransformationType($where: TransformationWhereUniqueInput!) {
        transformation(where: $where) {
          id
          name
          transformationTypeId
        }
      }
    `),
    variables: {
      where: {
        id: transformationId,
      },
    },
  });
};
