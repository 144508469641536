import { useInfiniteQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { GetSubmissionsInfiniteQueryVariables, SortOrder } from "@/gql/graphql";
import { getGraphqlClient } from "@/lib/hooks/graphql";

const query = graphql(`
  query GetSubmissionsInfinite(
    $where: SubmissionWhereInput
    $take: Int
    $cursor: SubmissionWhereUniqueInput
    $orderBy: [SubmissionOrderByWithRelationAndSearchRelevanceInput!]
    $aggregateSubmissionWhere2: SubmissionWhereInput
  ) {
    submissions(
      where: $where
      take: $take
      cursor: $cursor
      orderBy: $orderBy
    ) {
      id
      createdAt
      submittedFor
      metadata {
        value
        key
      }
      type {
        name
        type
      }
      file {
        id
        name
      }
      outputFile {
        id
        name
      }
      vendor {
        id
        name
      }
      transformations {
        id
      }
      validations {
        id
      }
      status
      validationStatus
    }
    aggregateSubmission(where: $aggregateSubmissionWhere2) {
      _count {
        id
      }
    }
  }
`);

type Params = {
  vendorId?: number;
  searchVars: GetSubmissionsInfiniteQueryVariables["where"];
};

export const useGetSubmissions = ({ vendorId, searchVars }: Params) => {
  const client = getGraphqlClient();

  return useInfiniteQuery({
    queryKey: ["GetSubmissionsInfinite", vendorId, searchVars],
    queryFn: async (data) => {
      const lastSubmissionId = data.pageParam;
      const cursorObj =
        data.pageParam !== 0
          ? {
              id: lastSubmissionId,
            }
          : undefined;

      const variables = {
        where: {
          ...searchVars,
        },
        take: 40,
        cursor: cursorObj,
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
        ],
        aggregateSubmissionWhere2: {
          ...searchVars,
        },
      };

      const submissionData = await client.request(query, variables);
      return submissionData;
    },
    initialPageParam: 0,
    getNextPageParam: (currentQuery, allQueriedData) => {
      const submissions = allQueriedData.flatMap((data) => data.submissions);
      const totalSubmissions = currentQuery.aggregateSubmission._count?.id ?? 0;

      if (submissions.length === totalSubmissions) {
        return undefined;
      }
      return submissions[submissions.length - 1].id;
    },
  });
};
