import { NormalizeVendorMapping } from "./useGetNormalizeVendorMappings";

type Params = {
  normalizeMapping: NormalizeVendorMapping | undefined;
  headers: string[];
};

export const detectMappingChanges = ({ normalizeMapping, headers }: Params) => {
  //   check that mapping exists
  if (normalizeMapping == null) {
    return true;
  }
  // check that all the headers match all of the mapping keys
  // aka there are no new headers that are not in the mapping
  // and that there are no mapping keys that are not in the headers

  const mappingKeys = Object.keys(normalizeMapping.mapping);

  const allKeysInHeaders = mappingKeys.every((key) => headers.includes(key));

  const allHeadersInMapping = headers.every((header) =>
    mappingKeys.includes(header),
  );

  return !allKeysInHeaders || !allHeadersInMapping;
};
