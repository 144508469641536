import { Suspense } from "react";

import { PageHeader } from "@/components/PageHeader";
import { StatCardSkeleton } from "@/components/StatCardSkeleton";
import { cn } from "@/lib/cn";

import { LowConfidencePartnersCard } from "./analytics/LowConfidencePartnersCard";
import { TotalActivePartnersCard } from "./analytics/TotalActivePartnersCard";
import { TotalPartnersCard } from "./analytics/TotalPartnersCard";
import { VendorsTableWrapper } from "@/components/shared-components/vendors-table/VendorsTableWrapper";
import { VendorsSummaryCard } from "./analytics/VendorsSummaryCard";
import { TopPerformingVendorsChart } from "./analytics/TopPerformingVendorsChart";

export const VendorsPage = () => {
  return (
    <div className="grid h-full grid-rows-[auto_auto_1fr] gap-4">
      <PageHeader title={"Partners"} />

      <article className={cn("grid grid-cols-3 gap-4")}>
        <Suspense fallback={<StatCardSkeleton />}>
          <TotalPartnersCard />
        </Suspense>

        <Suspense fallback={<StatCardSkeleton />}>
          <TotalActivePartnersCard />
        </Suspense>

        <Suspense fallback={<StatCardSkeleton />}>
          <LowConfidencePartnersCard />
        </Suspense>
      </article>
      <div className="grid w-full grid-cols-2 grid-rows-[400px_1fr] gap-6">
        <VendorsSummaryCard />
        <TopPerformingVendorsChart />
      </div>
      <VendorsTableWrapper />
    </div>
  );
};
