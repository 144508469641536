import React, { createContext, ReactNode, useContext, useState } from "react";
import { DateRange } from "react-day-picker";

import { Icons } from "@/components/Icons";

import { FilterTypeaheadItem } from "./FilterTypeaheadTextInput";

export type FilterOperation = {
  label: string;
  value: string;
  icon: React.FC<React.HTMLAttributes<SVGElement>>;
};

export enum FilterOperationOptions {
  CONTAINS = "contains",
  IN = "in",
}

export const filterOperationOptions: FilterOperation[] = [
  {
    label: "Contains",
    value: FilterOperationOptions.CONTAINS,
    icon: Icons.search,
  },
  {
    label: "In",
    value: FilterOperationOptions.IN,
    icon: Icons.equals,
  },
];

type additionalProps = Record<string, any>;

export type UseTypeaheadHookProps = {
  searchField: string;
  typeahead?: string;
  searchVars?: Record<string, any>;
  activeFilter?: ActiveFilter;
} & additionalProps;

export type UseTypeaheadHook = (
  props: UseTypeaheadHookProps,
) => FilterTypeaheadItem[];

type useGetSearchVarsHook = () => Record<string, any>;

export type ActiveFilter = FilterOption & {
  filterValues?: string[];
  filterOperation: FilterOperation;
};

// Pass these to the table to show all the filters available for the table rows - generally the column headers
export type FilterOption = {
  label: string;
  selectValue: string;
  searchField: string;
  validFilterOperations: FilterOperationOptions[];
};

export type SubmissionPeriod = {
  start: string;
  end: string;
  label: string;
};

interface TableFilterState {
  filterOptions: FilterOption[];
  searchNameFilter: string;
  setSearchNameFilter: React.Dispatch<React.SetStateAction<string>>;
  dateRangeFilter: DateRange | undefined;
  setDateRangeFilter: (dateRange: DateRange | undefined) => void;
  selectedSubmissionPeriods: SubmissionPeriod[];
  setSelectedSubmissionPeriods: React.Dispatch<
    React.SetStateAction<SubmissionPeriod[]>
  >;
  activeFilters: ActiveFilter[];
  setActiveFilters: React.Dispatch<React.SetStateAction<ActiveFilter[]>>;
  useTypeaheadHook: UseTypeaheadHook;
  additionalProps?: additionalProps;
  getSearchVars: useGetSearchVarsHook;
}

const TableFilterContext = createContext<TableFilterState | undefined>(
  undefined,
);

export const useTableFilterContext = () => {
  const context = useContext(TableFilterContext);
  if (!context) {
    throw new Error(
      "useTableFilterState must be used within a TableFilterProvider",
    );
  }
  return context;
};

type Props = {
  children: ReactNode;
  useTypeaheadHook: UseTypeaheadHook;
  filterOptions: FilterOption[];
  getSearchVars: useGetSearchVarsHook;
  additionalProps?: additionalProps;
  initActiveFilters?: ActiveFilter[];
};

export const TableFilterProvider = ({
  children,
  useTypeaheadHook,
  filterOptions,
  getSearchVars,
  additionalProps,
  initActiveFilters = [],
}: Props) => {
  const [searchNameFilter, setSearchNameFilter] = useState<string>("");
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(
    undefined,
  );
  const [activeFilters, setActiveFilters] =
    useState<ActiveFilter[]>(initActiveFilters);
  const [selectedSubmissionPeriods, setSelectedSubmissionPeriods] = useState<
    SubmissionPeriod[]
  >([]);

  return (
    <TableFilterContext.Provider
      value={{
        additionalProps,
        filterOptions,
        useTypeaheadHook,
        getSearchVars,
        searchNameFilter,
        setSearchNameFilter,
        dateRangeFilter,
        setDateRangeFilter,
        selectedSubmissionPeriods,
        setSelectedSubmissionPeriods,
        activeFilters,
        setActiveFilters,
      }}
    >
      {children}
    </TableFilterContext.Provider>
  );
};
