import Excel from "exceljs";
import Papa from "papaparse";

export const loadWorkbookFromBuffer = async (
  buffer: ArrayBuffer,
): Promise<Excel.Workbook | undefined> => {
  const workbook = new Excel.Workbook();

  try {
    // Try to load as Excel file first
    await workbook.xlsx.load(buffer);
    return workbook;
  } catch (error) {
    // If Excel loading fails, try CSV
    if (
      error instanceof Error &&
      error.message.includes("Can't find end of central directory")
    ) {
      // Convert ArrayBuffer to string
      const decoder = new TextDecoder("utf-8");
      const csvString = decoder.decode(buffer);

      return new Promise((resolve, reject) => {
        Papa.parse(csvString, {
          complete: (results) => {
            const worksheet = workbook.addWorksheet("Sheet1");
            // @ts-ignore - data is an array of arrays
            results.data.forEach((row: any[]) => {
              worksheet.addRow(row);
            });
            resolve(workbook);
          },
          error: (error: any) => {
            reject(new Error(`Error parsing CSV: ${error}`));
          },
        });
      });
    } else {
      // If it's not a CSV parsing error, throw the original error
      throw error;
    }
  }
};

export const getWorksheetHeaders = (
  worksheet: Excel.Worksheet | undefined,
): string[] => {
  return (worksheet?.getRow(1).values ?? []) as string[];
};

export const findColumnIndex = (
  headers: string[],
  columnName: string,
): number => {
  return headers.indexOf(columnName);
};

export const validateRequiredColumns = (
  worksheet: Excel.Worksheet | undefined,
  requiredColumns: string[],
): boolean => {
  const headers = getWorksheetHeaders(worksheet);
  return requiredColumns.every(
    (column) => findColumnIndex(headers, column) !== -1,
  );
};

export const getWorksheet = (
  workbook: Excel.Workbook,
): Excel.Worksheet | undefined => {
  return workbook.getWorksheet(1);
};
