type ProductTaxonomies =
  | "manufacturer"
  | "manufacturerPartNumbers"
  | "searchFamily"
  | "searchLine"
  | "model"
  | "name"
  | "category"
  | "unknown";

export const normalizeLabelToProductTaxonomy = (
  labelName: string,
): ProductTaxonomies => {
  const manufacturerRegex = /manufacturer(?! part number)/i;
  const manufacturerPartNumbersRegex = /^manufacturerPartNumbers$/i;
  const familyRegex = /family/i;
  const lineRegex = /line/i;
  const modelRegex = /model/i;
  const nameRegex = /name/i;
  const categoryRegex = /category/i;

  if (labelName === "Product Type" || labelName === "Form Factor") {
    return "unknown";
  } else if (manufacturerPartNumbersRegex.test(labelName)) {
    return "manufacturerPartNumbers";
  } else if (manufacturerRegex.test(labelName)) {
    return "manufacturer";
  } else if (familyRegex.test(labelName)) {
    return "searchFamily";
  } else if (lineRegex.test(labelName)) {
    return "searchLine";
  } else if (modelRegex.test(labelName)) {
    return "model";
  } else if (nameRegex.test(labelName)) {
    return "name";
  } else if (categoryRegex.test(labelName)) {
    return "category";
  } else {
    return "unknown";
  }
};
