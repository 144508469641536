import { TimePeriod } from "@/app/dashboard/home/DatePickerWithRange";
import { Icons } from "@/components/Icons";
import { AnalyticsCard } from "@/components/KPMCard";

export const LowConfidencePartnersCard = () => {
  const count = 1;

  return (
    <AnalyticsCard
      isLoading={false}
      name={"Low Confidence Partners"}
      icon={<Icons.flag className="h-5 w-5" />}
      label={count.toString()}
      value={count}
      prevValue={1}
      timePeriod={TimePeriod.WEEK}
    />
  );
};
