// import { RowClickedEvent } from "ag-grid-community";
// import { useNavigate } from "react-router-dom";

import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { DashboardScreen } from "../DashboardScreen";
import { DashboardKPMs } from "./(KPMs)/DashboardKPMs";
import { DashboardSubmissionsChart } from "./(submission-chart)/DashboardSubmissionsChart";
import { ChannelAnalysis } from "./ChannelAnalysis";
import { HomeHeader } from "./DashHomeHeader";
import { useDashHomeStore } from "./dashHomeStore";

export function DashHomePage() {
  useDashHomeStore();
  // This is just to cache the user data (will move to a route loader later)
  // @ts-ignore
  const { data } = useCurrentUserQuery();

  // const navigate = useNavigate();
  // const onRowClick = (params: RowClickedEvent) => {
  //   const submissionId = params.data.Id;
  //   navigate(`/dashboard/submissions/${submissionId}`);
  // };

  return (
    <DashboardScreen scrollable={true} className="h-screen">
      <div className="grid h-full grid-rows-[auto_1fr]">
        <HomeHeader />

        <section className="grid w-full grid-rows-[auto_1fr] gap-4 pt-6">
          <DashboardKPMs className="" />

          <div className="grid grid-cols-2 gap-4">
            {/* TODO: Ben fix the height on this chart comparatively with the right section */}
            <DashboardSubmissionsChart />
            {/* <div>
              Replace This section with product charts, breaking down overall
              performance in the channel
            </div> */}
            <ChannelAnalysis />
            {/* <div>
              <SubmissionsTableWrapper
                onRowClick={onRowClick}
                customHeight="h-[600px]"
                validStatuses={[SubmissionStatus.Processed]}
                columnHeaders={[
                  SubmissionsColumnHeaders.PARTNER,
                  SubmissionsColumnHeaders.TYPE,
                  SubmissionsColumnHeaders.SUBMISSION_PERIOD,
                  SubmissionsColumnHeaders.STATUS,
                ]}
              />
            </div> */}
          </div>
        </section>
      </div>
    </DashboardScreen>
  );
}
