import { RowClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router-dom";

import { SubmissionsKPMs } from "@/app/dashboard/submissions/SubmissionsKPMs";
import { PageHeader } from "@/components/PageHeader";
import { SubmissionsTableWrapper } from "@/components/shared-components/submissions-table/SubmissionsTableWrapper";
import { SubmissionStatus } from "@/gql/graphql";

import { DashboardScreen } from "../DashboardScreen";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";
import { SubmissionsColumnHeaders } from "@/components/shared-components/submissions-table/getSubmissionsTableRows";

export function SubmissionsPage() {
  const orgId = useGetOrgIdOrLogout();

  const navigate = useNavigate();
  const onRowClick = (params: RowClickedEvent) => {
    const submissionId = params.data.Id;
    navigate(`/dashboard/submissions/${submissionId}`);
  };

  return (
    <DashboardScreen
      className="grid h-full grid-rows-[auto_auto_1fr] gap-4"
      scrollable
    >
      <PageHeader title={"Submissions"} />
      <SubmissionsKPMs />
      <SubmissionsTableWrapper
        orgId={orgId}
        customHeight="h-[650px]"
        onRowClick={onRowClick}
        validStatuses={[SubmissionStatus.Processed]}
        columnHeaders={[
          SubmissionsColumnHeaders.PARTNER,
          SubmissionsColumnHeaders.TYPE,
          SubmissionsColumnHeaders.SUBMISSION_PERIOD,
          SubmissionsColumnHeaders.STATUS,
          SubmissionsColumnHeaders.FILE_NAME,
          SubmissionsColumnHeaders.DOWNLOAD,
        ]}
      />
    </DashboardScreen>
  );
}
