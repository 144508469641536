import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    toast("Logging out...", {
      icon: <Spinner />,
    });
    setTimeout(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }, 500);
  }, [logout]);

  return (
    <Button
      className="h-8 w-8"
      variant="outline"
      size="icon"
      onClick={handleLogout}
    >
      <Icons.logout className="h-4 w-4" />
    </Button>
  );
};
