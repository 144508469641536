import { QueryMode } from "@/gql/graphql";

import {
  FilterOperationOptions,
  useTableFilterContext,
} from "../table/TableFiltersContext";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";

export const useGetVendorsSearchVarsFromActiveFilters = () => {
  const { searchNameFilter, activeFilters } = useTableFilterContext();
  const orgId = useGetOrgIdOrLogout();

  const variables: Record<string, any> = {
    organizationId: {
      equals: orgId,
    },
  };
  activeFilters.forEach((filter) => {
    // for each filter value we need to add a new key to the variables object
    // then the filterValues will be an array of strings as the value to that key
    if (filter?.filterValues && filter.filterValues.length > 0) {
      const { searchField, filterValues } = filter;

      variables[searchField] = {};
      variables[searchField].mode = QueryMode.Insensitive;

      //   switch statement to handle the different filter operations
      switch (filter.filterOperation.value) {
        case FilterOperationOptions.CONTAINS:
          if (filterValues && filterValues.length > 0) {
            variables.OR = filterValues.map((value) => ({
              [searchField]: {
                mode: QueryMode.Insensitive,
                contains: value,
              },
            }));
          }
          break;
        case FilterOperationOptions.IN:
          variables[searchField].in = filter.filterValues;
          break;
        default:
          break;
      }
    }
  });

  if (searchNameFilter) {
    variables.name = {
      mode: QueryMode.Insensitive,
      contains: searchNameFilter,
    };
  }

  //   if (dateRangeFilter) {
  //     variables.createdAt = {
  //       gte: dateRangeFilter.from,
  //       lte: dateRangeFilter.to,
  //     };
  //   }

  return variables;
};
