import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

export const usePrepareFileDownloadQuery = (fileId: number) => {
  return useGraphqlQuery({
    queryKey: ["prepareFileDownload", fileId],
    query: graphql(`
      mutation PrepareFileDownload($fileId: Float!) {
        prepareFileDownload(fileId: $fileId) {
          signedUrl
        }
      }
    `),
    variables: { fileId },
  });
};
