import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { TransformationStatus } from "@/gql/graphql";

import { useUpdateTransformation } from "../../../lib/hooks/mutations/useUpdateTransformation";
import { getSubmissionTransformationsQueryOptions } from "./getSubmissionTransformationsQueryOptions";
import { getTransformationPredictionsTableQueryOptions } from "./getTransformationPredictionsTableQueryOptions";
import { useSubmitFeedback } from "./useSubmitFeedback";

export const useApproveAll = () => {
  const { transformationId: tId, submissionId: pId } = useParams();
  const transformationId = Number(tId);
  const submissionId = Number(pId);

  const submissionQueryOptions =
    getSubmissionTransformationsQueryOptions(submissionId);
  const { mutate: changeTransformationStatus } = useUpdateTransformation({
    queryKey: submissionQueryOptions.queryKey,
  });

  const predictionsQueryOptions = useMemo(
    () => getTransformationPredictionsTableQueryOptions(transformationId),
    [transformationId],
  );
  const { data: predictionRowData } = useQuery(predictionsQueryOptions);

  const { mutate: submitFeedback } = useSubmitFeedback({
    queryKey: predictionsQueryOptions.queryKey,
  });

  const onApproveAll = () => {
    if (!predictionRowData) {
      toast.error("No data to approve");
      return;
    }
    // given the current transformation get all the labels and create feedback for them
    const labels =
      predictionRowData?.predictions
        .map((prediction) => prediction.labels)
        .flat() ?? [];

    const feedback = labels.map((label) => ({
      labelId: label.id,
      upvote: true,
    }));

    submitFeedback({
      data: feedback,
    });
    // set transformation status to approved
    changeTransformationStatus({
      data: {
        status: {
          set: TransformationStatus.Approved,
        },
      },
      where: {
        id: transformationId,
      },
    });
  };

  return { onApproveAll };
};
