import { useLocation } from "react-router-dom";

import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import {
  GetSubmissionTransformationsQuery,
  TransformationStatus,
} from "@/gql/graphql";

import { useApproveTransformation } from "../../../../../../lib/hooks/mutations/useApproveTransformation";
import { CorrectionsPageSkeleton } from "./CorrectionsPageSkeleton";
import { ListItem } from "./ListItem";

type Submission = NonNullable<GetSubmissionTransformationsQuery["submission"]>;

type Props = {
  enrichments: Submission["transformations"];
};

const EnrichmentsListComponent = ({ enrichments }: Props) => {
  const location = useLocation();
  const { approveTransformation } = useApproveTransformation();

  return (
    <ul>
      {enrichments.map((enrichments, index) => {
        const approved = enrichments.status === TransformationStatus.Approved;
        return (
          <ListItem
            key={index}
            onApprove={() =>
              approveTransformation(enrichments.id, enrichments.status)
            }
            approved={approved}
            name={enrichments.type.name}
            description={enrichments.type?.description}
            href={`${location.pathname}/${enrichments.id}`}
          />
        );
      })}
    </ul>
  );
};

export const EnrichmentsList = WithSuspenseAndErrorBoundary({
  Component: EnrichmentsListComponent,
  suspenseFallback: <CorrectionsPageSkeleton />,
});
