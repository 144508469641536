export const validateCell = (
  cellValue: any,
  fieldType: string,
  column: string,
) => {
  if (column === "Vendor ID") {
    if (cellValue === "NULL") {
      return "Vendor ID cannot be NULL";
    }
  }
  switch (fieldType) {
    // case "number":
    //   return typeof cellValue === "number" ? true : "Invalid number";
    // case "date":
    //   return Date.parse(cellValue) ? true : "Invalid date";
    default:
      return true; // Default to valid unless specified otherwise
  }
};
