import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

export const SubmissionSummaryCardSkeleton = () => {
  return (
    <Card className="col-span-1 row-span-1">
      <CardHeader>
        <CardTitle className="flex items-center gap-4">
          <div className="flex items-center justify-center">
            <p className="mr-2">{`Submission Summary for`}</p>
            <div className="h-6 w-16 animate-pulse rounded-lg bg-muted"></div>{" "}
          </div>
        </CardTitle>
        <CardDescription>
          <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
        </CardDescription>
      </CardHeader>
      {/* labels on the far left, stats on far right */}
      <CardContent>
        <div className="flex justify-between py-2">
          <p>Rows Submitted</p>
          <p>
            <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
          </p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Correction Completion %</p>
          <p>
            <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
          </p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>CPUs Sold</p>
          <p>
            <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
          </p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>GPUs Sold</p>
          <p>
            <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
          </p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Estimated Rebate Value</p>
          <p>
            <div className="h-6 w-32 animate-pulse rounded-lg bg-muted"></div>
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
