import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const processSubmissionMutation = graphql(`
  mutation ProcessSubmission($submissionId: Float!) {
    processSubmission(submissionId: $submissionId) {
      id
    }
  }
`);

export const useProcessSubmission = () => {
  return useGraphqlMutation({
    mutation: processSubmissionMutation,
    onSuccess: () => {},
  });
};
