import { useDroppable } from "@dnd-kit/core";

import { cn } from "@/lib/cn";

type Props = {
  id: string;
  children: React.ReactNode;
  className?: string;
};

export function Droppable({ children, id, className }: Props) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div ref={setNodeRef} className={cn(isOver ? "bg-accent" : "", className)}>
      {children}
    </div>
  );
}
