import React from "react";
import { useDraggable } from "@dnd-kit/core";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/cn";

type Props = {
  id: string;
  children: React.ReactNode;
  className?: string;
};

export function Draggable({ id, children, className }: Props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Button
      variant={"outline"}
      ref={setNodeRef}
      className={cn(className)}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children}
    </Button>
  );
}
