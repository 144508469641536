import { GetVendorsInfiniteQuery } from "@/gql/graphql";

export enum VendorsColumnHeaders {
  ID = "ID",
  VENDOR_ID = "Vendor Id",
  NAME = "Name",
  EMAIL = "Email",
  SUBMISSIONS = "Submissions",
}

export type VendorRowData = {
  [VendorsColumnHeaders.ID]: number;
  [VendorsColumnHeaders.VENDOR_ID]: string;
  [VendorsColumnHeaders.NAME]: string;
  [VendorsColumnHeaders.EMAIL]: string;
  [VendorsColumnHeaders.SUBMISSIONS]: number;
};

type Props = {
  vendors: GetVendorsInfiniteQuery["vendors"];
};

export const getVendorsTableRows = ({ vendors }: Props) => {
  const rows: VendorRowData[] = [];
  //   to get the rows iterate over submissions
  vendors.forEach((vendor) => {
    // const row: { [key: string]: RowValue } = {};
    const row: VendorRowData = {} as VendorRowData;
    const { vendorId, name, email, totalSubmissions: submissions, id } = vendor;

    row[VendorsColumnHeaders.ID] = id;
    row[VendorsColumnHeaders.VENDOR_ID] = vendorId;
    row[VendorsColumnHeaders.NAME] = name;
    row[VendorsColumnHeaders.EMAIL] = email;
    row[VendorsColumnHeaders.SUBMISSIONS] = submissions?.submissions ?? 0;

    rows.push(row);
  });

  return { rows };
};
