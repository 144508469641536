import { useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { GetSubmissionsInfiniteQuery } from "@/gql/graphql";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";

import { CustomDownloadHeader } from "./CustomDownloadHeader";
import { SubmissionsColumnHeaders } from "./getSubmissionsTableRows";
import { usePrepareFileDownloadMutation } from "@/lib/hooks/mutations/usePrepareFileDownloadMutation";

type Props = {
  submissions: GetSubmissionsInfiniteQuery["submissions"];
  columnHeaders: SubmissionsColumnHeaders[];
};

export const useGetSubmissionsTableColDefs = ({
  submissions,
  columnHeaders,
}: Props) => {
  const { mutate: prepareFileDownload } = usePrepareFileDownloadMutation();

  return useMemo(() => {
    const colDefs = columnHeaders.reduce((acc, header) => {
      if (columnHeaders.find((col) => col === header) === undefined) {
        return acc;
      }

      const standardColDef = {
        headerName: header,
        field: header,
        colId: header,
        filter: false,
        editable: false,
        resizable: true,
        flex: 0.7,
      };

      let customColDef = standardColDef as ColDef;

      if (header === SubmissionsColumnHeaders.ID) {
        customColDef = {
          ...standardColDef,
          checkboxSelection: true,
          maxWidth: 100,
        };
      } else if (header === SubmissionsColumnHeaders.TYPE) {
        customColDef = {
          ...standardColDef,
          maxWidth: 100,
        };
      } else if (header === SubmissionsColumnHeaders.STATUS) {
        customColDef = {
          ...standardColDef,
          maxWidth: 150,
        };
      } else if (header === SubmissionsColumnHeaders.SUBMISSION_PERIOD) {
        customColDef = {
          ...standardColDef,
          maxWidth: 120,
        };
      } else if (header === SubmissionsColumnHeaders.FILE_NAME) {
        customColDef = {
          ...standardColDef,
          flex: 1.5,
        };
      } else if (header === SubmissionsColumnHeaders.DOWNLOAD) {
        customColDef = {
          ...standardColDef,
          headerComponent: CustomDownloadHeader,
          maxWidth: 100,
          headerName: header,
          cellRenderer: ({ value }: { value: string }) => {
            return (
              <Button
                className="z-50"
                size={"icon"}
                variant={"ghost"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const submission = submissions.find(
                    (submission) => submission.file.id === Number(value),
                  );
                  if (submission?.file) {
                    prepareFileDownload(
                      { fileId: submission.file.id },
                      {
                        onSuccess: (data) => {
                          const signedUrl = data.prepareFileDownload.signedUrl;
                          onDownloadFileToComputer(
                            signedUrl,
                            submission.file.name,
                          );
                        },
                        onError: (error) => {
                          console.error({ error });
                        },
                      },
                    );
                  } else {
                    toast.error("No file found");
                  }
                }}
              >
                <Icons.download className="h-6 w-6" />
              </Button>
            );
          },
        };
      }

      acc.push(customColDef);
      return acc;
    }, [] as Array<ColDef>);

    return colDefs;
  }, [submissions, columnHeaders]);
};
