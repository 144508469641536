import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateManyVendors = () => {
  return useGraphqlMutation({
    mutationKey: ["createManyVendors"],
    mutation: graphql(`
      mutation CreateManyVendors(
        $data: [VendorCreateManyInput!]!
        $skipDuplicates: Boolean
      ) {
        createManyVendor(data: $data, skipDuplicates: $skipDuplicates) {
          count
        }
      }
    `),
  });
};
