import { Icons } from "@/components/Icons";
import { AnalyticsCard } from "@/components/KPMCard";

import { useGetTotalPartnersData } from "./useGetTotalPartnersCard";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";
import { TimePeriod } from "@/app/dashboard/home/DatePickerWithRange";

export const TotalPartnersCard = () => {
  const orgId = useGetOrgIdOrLogout();

  const { data, isLoading } = useGetTotalPartnersData({ orgId });
  const count = data?.organization?._count?.vendors ?? "N/A";

  return (
    <AnalyticsCard
      isLoading={isLoading}
      name={"Total Partners"}
      icon={<Icons.users className="h-5 w-5" />}
      label={count.toString()}
      value={count}
      prevValue={1}
      timePeriod={TimePeriod.WEEK}
    />
  );
};
