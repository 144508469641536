import { useQuery } from "@tanstack/react-query";

import { getNormalizeTransformationOutputFile } from "./getNormalizedFileOutputFileQueryOptions";
import { useGetTypedOutputColumns } from "./useGetTypedOutputColumns";
import { useGetValidationTableHeadersAndRows } from "./useGetValidationTableHeadersAndRows";
import { ValidationExcelTable } from "./ValidationExcelTable";
import { ValidationExcelTableSkeleton } from "./ValidationExcelTableSkeleton";
import { useExcelWorkbookFromFileId } from "@/lib/hooks/useExcelWorkbookFromFileId";

type Props = {
  vendorId: number;
  submissionId: number;
};

export const ValidationExcelTableDataWrapper = ({
  vendorId,
  submissionId,
}: Props) => {
  const { data } = useQuery(
    getNormalizeTransformationOutputFile({ submissionId }),
  );
  const fileId = data?.submission?.transformations[0]?.outputFileId ?? -1;

  const { worksheet, workbook } = useExcelWorkbookFromFileId(fileId);

  const { headerValues, rowsValues } = useGetValidationTableHeadersAndRows({
    worksheet,
  });
  const { typedOutputColumns, isLoading } = useGetTypedOutputColumns({
    submissionId,
    vendorId,
  });

  if (fileId === -1) {
    return <div>No file found</div>;
  }

  return worksheet == null || isLoading ? (
    <ValidationExcelTableSkeleton />
  ) : (
    <div>
      <ValidationExcelTable
        headerValues={headerValues}
        rowsValues={rowsValues}
        workbook={workbook}
        worksheet={worksheet}
        typedOutputColumns={typedOutputColumns}
      />
    </div>
  );
};
