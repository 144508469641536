import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { Prediction } from "@/gql/graphql";
import { cn } from "@/lib/cn";
import { CONFIDENCE_INTERVALS } from "@/lib/constants/confidenceIntervals";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { ValueOf } from "@/lib/typeHelpers";

import { useTransformationPredictionIds } from "./useTransformationPredictionIds";

interface NumLabelsToReviewProps extends React.HTMLAttributes<HTMLSpanElement> {
  transformationId: number;
}

export const query = graphql(`
  query NumLabelsToReview($where: LabelWhereInput) {
    aggregateLabel(where: $where) {
      _count {
        id
      }
    }
  }
`);

export const getQueryOptions = ({
  predictionIds,
  interval,
}: {
  predictionIds: Prediction["id"][];
  interval: ValueOf<typeof CONFIDENCE_INTERVALS>;
}) =>
  createQueryOptions({
    query,
    variables: {
      where: {
        predictionId: {
          in: predictionIds,
        },
        confidence: {
          gte: interval[0],
          lt: interval[1],
        },
        feedback: {
          none: {
            id: {
              gt: 0,
            },
          },
        },
      },
    },
  });

export const NumLabelsToReview = ({
  transformationId,
  className,
  ...props
}: NumLabelsToReviewProps) => {
  const { data: predictionIds } =
    useTransformationPredictionIds(transformationId);

  const options = getQueryOptions({
    predictionIds,
    interval: CONFIDENCE_INTERVALS.Low,
  });
  const { data: labelData } = useSuspenseQuery(options);
  const count = labelData.aggregateLabel._count?.id || 0;

  return (
    <span
      key={`${transformationId}-${count}`}
      className={cn("tabular-nums", className)}
    >
      {count}
    </span>
  );
};

export const NumLabelsToReviewSkeleton = ({
  className,
  ...props
}: Omit<NumLabelsToReviewProps, "transformationId">) => {
  return (
    <span
      className={cn(
        "tabularNums animate-pulse rounded bg-muted text-muted",
        className,
      )}
      {...props}
    >
      00
    </span>
  );
};
