import { graphql } from "@/gql";
import { Vendor } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getSubmissionTypeQueryOptions = (
  submissionId: number,
  vendorId: Vendor["id"],
) => {
  return createQueryOptions({
    query: graphql(`
      query GetSubmissionType($where: SubmissionWhereUniqueInput!) {
        submission(where: $where) {
          id
          type {
            id
            name
            outputColumns {
              name
              dataType
              description
            }
          }
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
    },
  });
};
