import { match } from "ts-pattern";
export function toSnakeCase(str: string) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .trim() // Remove leading and trailing whitespace
    .split(/\s+/) // Split the string into an array of words based on one or more whitespace characters
    .join("_"); // Join the words with underscores
}

export function camelCaseToHeader(input: string): string {
  // Use a regular expression to split the camelCase string
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  const capitalizedWords = words.replace(/\b\w/g, (match) =>
    match.toUpperCase(),
  );

  return capitalizedWords;
}

export function formatUSDRounded(amount: number): string {
  return "$" + Math.round(amount).toLocaleString();
}

export function formatUSD(amount: number): string {
  return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function convertDecimaltoPercentageString(
  decimalNumber: number,
  decimalPlaces: number = 2,
) {
  return (decimalNumber * 100).toFixed(decimalPlaces) + "%";
}

type Casing = "hyphen" | "camel" | "pascal" | "snake" | "kebab";

function determineCase(str: string) {
  // Regular expressions for different casing styles
  const hyphenCase = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  const kebabCase = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  const camelCase = /^[a-z][a-z0-9]*([A-Z][a-z0-9]*)*$/;
  const pascalCase = /^([A-Z][a-z0-9]*)+$/;
  const snakeCase = /^[a-z0-9]+(_[a-z0-9]+)*$/;

  if (hyphenCase.test(str)) return "hyphen";
  if (camelCase.test(str)) return "camel";
  if (pascalCase.test(str)) return "pascal";
  if (snakeCase.test(str)) return "snake";
  if (kebabCase.test(str)) return "kebab";

  return undefined;
}

export function convertCase(str: string, targetCase: Casing) {
  const currentCase = determineCase(str);

  if (!currentCase) throw new Error("Unknown casing");

  // First, convert to a common format (array of lowercase words)
  const words = match(currentCase)
    .with("hyphen", "kebab", () => str.split("-"))
    .with("camel", "pascal", () =>
      str
        .replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()
        .split(" "),
    )
    .with("snake", () => str.split("_"))
    .exhaustive();

  // Then, convert to the target case
  return match(targetCase)
    .with("hyphen", "kebab", () => words.join("-"))
    .with("camel", () =>
      words
        .map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join(""),
    )
    .with("pascal", () =>
      words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(""),
    )
    .with("snake", () => words.join("_"))
    .exhaustive();
}
