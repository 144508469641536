import { useEffect, useRef, useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/cn";

import { ActiveFilter, useTableFilterContext } from "./TableFiltersContext";

export type FilterTypeaheadItem = {
  label: string;
  value: string;
  selected: boolean;
};

type Props = {
  activeFilter: ActiveFilter;
  setIsWide: (isWide: boolean) => void;
};

export const FilterTypeaheadTextInput = ({
  activeFilter,
  setIsWide,
}: Props) => {
  const { label, filterValues, searchField } = activeFilter;
  const { setActiveFilters, getSearchVars, useTypeaheadHook, additionalProps } =
    useTableFilterContext();
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [typeahead, setTypeahead] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkWidth = () => {
      if (buttonContainerRef.current) {
        const width = buttonContainerRef.current.offsetWidth;
        setIsWide(width > 500);
      }
    };

    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, [setIsWide, filterValues]);

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Check if the click target is not a button or its child elements
    if (
      e.target instanceof Node &&
      buttonContainerRef.current?.contains(e.target)
    ) {
      inputRef.current?.focus();
    }
  };

  const searchVars = getSearchVars();

  const typeaheadItems = useTypeaheadHook({
    searchField,
    typeahead,
    activeFilter,
    searchVars,
    ...additionalProps,
  });

  const setNewFilterValue = (value: string) => {
    const doesValueExist = filterValues?.includes(value);
    if (doesValueExist) {
      setActiveFilters((prev) => {
        return prev.map((filter) => {
          if (filter.label === label) {
            return {
              ...filter,
              filterValues: filter.filterValues?.filter(
                (filterValue) => filterValue !== value,
              ),
            };
          }
          return filter;
        });
      });
      return;
    } else {
      setActiveFilters((prev) => {
        return prev.map((filter) => {
          if (filter.label === label) {
            const newFilterValues = filterValues
              ? [...filterValues, value]
              : [value];
            return {
              ...filter,
              filterValues: newFilterValues,
            };
          }
          return filter;
        });
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (highlightedIndex >= 0 && highlightedIndex < typeaheadItems.length) {
        setNewFilterValue(typeaheadItems[highlightedIndex].value);
        setTypeahead("");
        setHighlightedIndex(-1);
      } else if (typeahead.trim() !== "") {
        setNewFilterValue(typeahead);
        setTypeahead("");
        setHighlightedIndex(-1);
      }
      setOpen(false);
    } else if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, typeaheadItems.length - 1),
      );
      setOpen(true);
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      setOpen(true);
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!inputRef.current?.contains(e.relatedTarget as Node)) {
      setOpen(false);
    }
  };

  return (
    <div className="relative" ref={containerRef} onBlur={handleBlur}>
      <div
        ref={buttonContainerRef}
        className="flex max-w-lg flex-wrap items-center gap-1 rounded-md border-2 p-2"
        onClick={handleContainerClick}
      >
        {filterValues?.map((filterValue, i) => (
          <Button
            variant={"outline"}
            onClick={() => {
              setActiveFilters((prev) => {
                return prev.map((filter) => {
                  if (filter.label === label) {
                    return {
                      ...filter,
                      filterValues: filter.filterValues?.filter(
                        (value) => value !== filterValue,
                      ),
                    };
                  }
                  return filter;
                });
              });
            }}
            key={`${filterValue}-${i}`}
            className="inline-flex h-6 min-w-fit cursor-pointer items-center  whitespace-nowrap  rounded border-0 bg-primary/10 p-0 px-1.5 py-1"
          >
            <span className="overflow-hidden text-ellipsis">{filterValue}</span>
            <span className="ml-1 shrink-0">
              <Icons.remove className="h-4 w-4  text-primary/60" />
            </span>
          </Button>
        ))}
        <input
          ref={inputRef}
          className="border-0 bg-transparent p-0 focus:border-0 focus:outline-none focus:ring-0"
          onFocus={handleFocus}
          onChange={(e) => {
            setTypeahead(e.target.value);
            setHighlightedIndex(-1);
          }}
          onKeyDown={handleKeyDown}
          value={typeahead}
          placeholder={
            (filterValues ?? []).length === 0 ? "Enter value..." : ""
          }
        />
      </div>
      {open && (
        <Card className="absolute z-20 mt-1 max-h-[300px] min-w-full overflow-auto bg-black scrollbar-hide">
          <CardContent className="p-1">
            {typeaheadItems.length === 0 ? (
              <div className="p-2 text-sm text-primary/60">
                {(filterValues ?? []).length > 0
                  ? "All options are added"
                  : "Start typing and press Enter to add Options"}
              </div>
            ) : (
              <ul>
                {typeaheadItems.map((item, index) => (
                  <li
                    className={cn(
                      "flex cursor-pointer items-center rounded-md p-2 hover:bg-gray-800",
                      highlightedIndex === index && "bg-gray-700",
                    )}
                    key={`${item.value}-${index}`}
                    onMouseDown={() => {
                      setNewFilterValue(item.value);
                      setTypeahead("");
                      setHighlightedIndex(-1);
                      setOpen(false);
                    }}
                  >
                    <Icons.check
                      className={cn(
                        "mr-2 h-4 w-4",
                        item.selected ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {item.label}
                    {highlightedIndex === index &&
                      (item.selected ? (
                        <Icons.remove className="ml-auto h-4 w-4" />
                      ) : (
                        <Icons.plus className="ml-auto h-4 w-4 " />
                      ))}
                  </li>
                ))}
              </ul>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};
