import { CustomLoadingOverlayProps } from "ag-grid-react";

import { Spinner } from "@/components/Spinner";

export const CustomLoadingOverlay = (
  props: CustomLoadingOverlayProps & { loadingMessage: string },
) => {
  return (
    <div className="" role="presentation">
      <div role="presentation" className="mx-auto h-40 w-40">
        <Spinner className="h-full w-full" />
      </div>
      <div aria-live="polite" aria-atomic="true" className="mt-4">
        {props.loadingMessage}
      </div>
    </div>
  );
};
