import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { Icons } from "@/components/Icons";

import { Card, CardContent } from "@/components/ui/card";
import { OrgSubmissionTypesQuery } from "@/gql/graphql";

import { formatDistanceToNow } from "date-fns";
import { IconDisplayBubble } from "@/components/icon-display-bubble";
import { icons } from "lucide-react";

interface SubmissionTypeItemProps extends React.HTMLAttributes<HTMLDivElement> {
  submissionType: OrgSubmissionTypesQuery["submissionTypes"][number];
}

export const SubmissionTypeItem = ({
  submissionType,
  ...props
}: SubmissionTypeItemProps) => {
  const Icon = useMemo(() => {
    return icons[submissionType.icon as keyof typeof icons];
  }, [submissionType.icon]);

  return (
    <Link to={`${submissionType.id}`}>
      <Card
        className="group max-w-3xl transition-all hover:border-primary/80 hover:bg-accent/60"
        {...props}
      >
        <CardContent className="px-5 py-4">
          <div className="flex items-start justify-between">
            <div className="flex gap-5">
              <div className="flex items-start">
                <IconDisplayBubble>
                  <Icon />
                </IconDisplayBubble>
              </div>
              <div>
                <h2 className="text-lg font-medium">{submissionType.name}</h2>

                <p className="text-sm text-muted-foreground">
                  {submissionType.vendors.length} vendors submitting
                </p>
              </div>
            </div>

            <p className="mt-1 text-xs text-muted-foreground">
              Updated {formatDistanceToNow(new Date(submissionType.updatedAt))}{" "}
              ago
            </p>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

export const SubmissionTypeItemSkeleton: React.FC = () => {
  return (
    <Card className="max-w-3xl animate-pulse">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div className="flex items-start">
              <IconDisplayBubble className="animate-pulse"></IconDisplayBubble>
            </div>
            <div className="w-64">
              <div className="mb-2 h-6 w-3/4 rounded bg-muted" />
              <div className="mb-2 h-4 w-1/4 rounded bg-muted" />
              <div className="h-4 w-1/2 rounded bg-muted" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-24 rounded bg-muted" />
            <div className="flex h-10 w-10 items-center justify-center rounded-full border border-border">
              <Icons.edit className="text-foreground" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
