import { SortOrder } from "@intuitive-systems/protege-engine";

import { graphql } from "@/gql/gql";
import { QueryMode } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

import { UseTypeaheadHookProps } from "../table/TableFiltersContext";
import { normalizeLabelToProductTaxonomy } from "./LabelToProductTaxonomyMapping";

type Props = UseTypeaheadHookProps;

export const useGetProductTypeahead = ({
  searchField,
  typeahead = "",
  searchVars,
}: Props) => {
  const normalizedLabelName = normalizeLabelToProductTaxonomy(searchField);

  const queryVars = {
    where: {
      ...searchVars,

      [normalizedLabelName]: {
        mode: QueryMode.Insensitive,
        contains: typeahead,
      },
    },
  };

  return useGraphqlQuery({
    enabled: normalizedLabelName !== "unknown",
    queryKey: [
      "GetProductTypeahead",
      normalizedLabelName,
      typeahead,
      searchVars,
    ],
    query: graphql(`
      query ProductTypeahead(
        $take: Int
        $where: ProductWhereInput
        $distinct: [ProductScalarFieldEnum!]
        $orderBy: [ProductOrderByWithRelationAndSearchRelevanceInput!]
      ) {
        products(
          take: $take
          where: $where
          distinct: $distinct
          orderBy: $orderBy
        ) {
          id
          category
          family
          description
          line
          manufacturer
          manufacturerPartNumbers
          model
          name
          searchFamily
          searchLine
          searchName
          updatedAt
          createdAt
          attributes
          id
        }
      }
    `),
    // variables: queryVars,
    variables: {
      ...queryVars,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      take: 10,
      // @ts-ignore labelName is correct
      distinct: normalizedLabelName,
    },
  });
};
