import { useEffect, useRef, useState } from "react";

import { Card, CardContent } from "@/components/ui/card";
import { TextInput } from "@/components/ui/text-input";
import { cn } from "@/lib/cn";

import { useTableFilterContext } from "./TableFiltersContext";

type Props = {
  typeaheadItems: { label: string; value: string }[];
  placeholder: string;
  width?: 52 | 72 | 96;
  showTypeahead?: boolean;
};

export const NameFilter = ({
  typeaheadItems,
  placeholder,
  width = 52,
  showTypeahead = true,
}: Props) => {
  const { searchNameFilter, setSearchNameFilter } = useTableFilterContext();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchNameFilter.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchNameFilter]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={inputRef}>
      <TextInput
        className={cn(`w-${width}`)}
        onFocus={() => {
          if (searchNameFilter.length > 0) {
            setOpen(true);
          }
        }}
        onChange={(e) => {
          setSearchNameFilter(e.target.value);
        }}
        value={searchNameFilter}
        placeholder={placeholder}
      />
      {showTypeahead && open && (
        <Card
          className={cn(
            "absolute z-20 mt-1 max-h-[300px] overflow-auto bg-black",
            `w-${width}`,
          )}
        >
          <CardContent className="p-1">
            <ul>
              {typeaheadItems.map((item) => (
                <li
                  className={cn(
                    "flex cursor-pointer items-center rounded-md p-2 hover:bg-gray-800",
                  )}
                  key={item.value}
                  onClick={() => {
                    setSearchNameFilter(item.value);
                    setOpen(false);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
