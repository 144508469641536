import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

import { ProductSummaryData } from "./getAllProductsSold";

type Props = {
  submissionWeek: string;
  fileName: string;
  productSummaryData: ProductSummaryData;
};

export const SubmissionSummaryCard = ({
  submissionWeek,
  fileName,
  productSummaryData,
}: Props) => {
  return (
    <Card className="col-span-1 row-span-1">
      <CardHeader>
        <CardTitle>Submission Summary for W{submissionWeek}</CardTitle>
        <CardDescription>{fileName}</CardDescription>
      </CardHeader>
      {/* labels on the far left, stats on far right */}
      <CardContent>
        <div className="flex justify-between py-2">
          <p>Rows Submitted</p>
          <p>{productSummaryData.totalRows}</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Correction Completion %</p>
          <p>98%</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>CPUs Sold</p>
          <p>{productSummaryData.validCPUs.length}</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>GPUs Sold</p>
          <p>{productSummaryData.validGPUs.length}</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Eligible Quantix Microsystems Products</p>
          {/* random number adding $100 per row */}
          <p>
            {productSummaryData.validCPUs.length +
              productSummaryData.validGPUs.length}
          </p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Estimated Rebate Value</p>
          {/* random number adding $100 per row */}
          <p>${productSummaryData.totalRows * 6}</p>
        </div>
      </CardContent>
    </Card>
  );
};
