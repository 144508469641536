import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";
import { Header } from "../submission/:submissionId/Header";
import { SubmissionTypeUploadGrid } from "./SubmissionTypeUploadGrid";
import { VendorUploadToggleTables } from "./VendorUploadToggleTables";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const VendorUploadPage = () => {
  const [showTypes, _setShowTypes] = useState(true);
  const [_selectedVendor, setSelectedVendor] = useState("");
  const [parent] = useAutoAnimate({
    duration: 100,
    easing: easeSnappy,
  });

  return (
    <div className="grid max-h-screen min-h-screen grid-rows-[auto_1fr] content-stretch gap-6">
      <div className="flex w-full flex-col items-center md:px-16">
        <Header
          title="Data Steward Submissions Portal"
          description="Upload your inventory or spreadsheet files for processing and our advanced AI and validation tools will handle the rest. Simply drag and drop your files to get started."
          className="py-6 pt-24"
        />

        <HoverBorderGradient
          as="button"
          containerClassName=""
          className={cn("min-w-[250px] bg-black  hover:text-accent-foreground")}
        >
          <Select onValueChange={setSelectedVendor} value="amd">
            <SelectTrigger className="full flex items-center justify-center gap-4 border-0 bg-none  ring-offset-black focus:ring-0">
              <SelectValue placeholder="Select partner program" />
            </SelectTrigger>
            <SelectContent className="rounded-xl bg-black">
              <SelectItem value="amd">AMD</SelectItem>
              <SelectItem disabled value="intel">
                Intel
              </SelectItem>
              <SelectItem disabled value="lenovo">
                Lenovo
              </SelectItem>
              <SelectItem disabled value="nvidia">
                Nvidia
              </SelectItem>
              <SelectItem disabled value="hp">
                HP
              </SelectItem>
            </SelectContent>
          </Select>
        </HoverBorderGradient>

        {/* <HoverBorderGradient
          as="button"
          containerClassName=""
          onClick={() => setShowTypes(!showTypes)}
          className={cn(
            "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {showTypes ? "View Past Submissions" : "Create New Submission"}
        </HoverBorderGradient> */}
      </div>

      <div className="flex justify-center p-2 sm:p-4 lg:p-6" ref={parent}>
        {showTypes ? (
          <div className="max-w-2xl" key="upload-grid">
            <SubmissionTypeUploadGrid />
          </div>
        ) : (
          <div className="w-full" key="tables">
            <VendorUploadToggleTables />
          </div>
        )}
      </div>
    </div>
  );
};
