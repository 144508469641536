import { useParams, useRevalidator } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";

import { useProcessSubmission } from "../../../lib/hooks/mutations/useProcessSubmission";
import { useResetSubmission } from "../../../lib/hooks/mutations/useResetSubmission";

type Props = {
  text?: string;
  onReprocessSubmission?: () => void;
};

export const ReprocessSubmissionButton = ({
  text = "Reprocess Submission",
  onReprocessSubmission,
}: Props) => {
  const revalidator = useRevalidator();
  // const navigate = useNavigate();
  const { submissionId: sId, vendorId: _vId } = useParams();
  const submissionId = Number(sId);
  // const vendorId = Number(vId);

  const { mutate: resetSubmission, isPending: isResetPending } =
    useResetSubmission();
  const { mutate: processSubmission, isPending: isProcessPending } =
    useProcessSubmission();
  const reprocess = () => {
    onReprocessSubmission && onReprocessSubmission();

    // call resetSubmission
    resetSubmission(
      { submissionId },
      {
        onSuccess: () => {
          processSubmission(
            { submissionId },
            {
              onSuccess: () => {
                revalidator.revalidate();
              },
            },
          );
        },
      },
    );
  };

  const isLoading = isResetPending || isProcessPending;

  return (
    <Button variant={"outline"} onClick={reprocess}>
      {text}
      {isLoading ? (
        <Spinner className="ml-2" />
      ) : (
        <Icons.reprocess className="ml-2 h-4 w-4" />
      )}
    </Button>
  );
};
