import { Icons } from "@/components/Icons";

type Props = {
  submissionTypeId: number;
};

export const SubmissionTypeIcon = ({ submissionTypeId }: Props) => {
  // Inventory
  if (submissionTypeId === 1) {
    return <Icons.package className="h-5 w-5" aria-hidden="true" />;
  } else if (submissionTypeId === 2) {
    return <Icons.invoice className="h-5 w-5" aria-hidden="true" />;
  } else {
    return <Icons.action className="h-5 w-5" aria-hidden="true" />;
  }
};
