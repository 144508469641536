import { useCallback, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Excel from "exceljs";

import { Icons } from "@/components/Icons";
import { Combobox } from "@/components/ui/combobox";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

import { FindAndReplaceDialog } from "./findAndReplaceDialog";
import { useValidationStore } from "./ValidationStore";

export enum FilterType {
  All = "all",
  Valid = "valid",
  Invalid = "invalid",
  Custom = "custom",
}

enum ActionType {
  Download = "download",
  FindReplace = "find-replace",
  UploadNewFile = "upload-new-file",
  DisplayColumns = "display-columns",
  PinColumns = "pin-columns",
}

type Props = {
  gridApi: AgGridReact["api"] | undefined;
  rowsValues: {
    [key: string]: Excel.CellValue;
  }[];
  setExternalFilter: React.Dispatch<
    React.SetStateAction<{
      type: FilterType;
      value: string;
    }>
  >;
  workbook: Excel.Workbook;
};

export const ValidationExcelTableFilters = ({
  gridApi,
  rowsValues,
  setExternalFilter,
  workbook,
}: Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { validationErrors } = useValidationStore();
  const totalRows = rowsValues.length;
  //   get all unique rowIndexes from validationErrors
  const totalRowsWithErrors = validationErrors.reduce((acc, error) => {
    if (!acc.includes(error.rowIndex)) {
      acc.push(error.rowIndex);
    }
    return acc;
  }, [] as number[]).length;

  const onDownload = useCallback(() => {
    if (workbook) {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "output.xlsx";
        a.click();
      });
    }
  }, [workbook]);

  const handleActionChange = useCallback(
    (item: ActionType) => {
      if (item === ActionType.Download) {
        onDownload();
      } else if (item === ActionType.FindReplace) {
        setDialogOpen(true);
      }
      // Additional cases for other actions
    },
    [onDownload],
  );

  const handleFilterChange = useCallback(
    (item: string) => {
      setExternalFilter({
        type: FilterType.Custom,
        value: item,
      });
    },
    [setExternalFilter],
  );

  //  TODO: display selected Filters and be able to clear them

  return (
    <>
      <section className="flex gap-4 pb-2">
        <ToggleGroup
          variant="outline"
          type="single"
          defaultValue={FilterType.All}
          onValueChange={(val) =>
            setExternalFilter({
              type: val as FilterType,
              value: val as FilterType,
            })
          }
        >
          <ToggleGroupItem value={FilterType.All} aria-label="Toggle all">
            {`All ${totalRows}`}
          </ToggleGroupItem>
          <ToggleGroupItem value={FilterType.Valid} aria-label="Toggle valid">
            {`Valid ${totalRows - totalRowsWithErrors}`}
          </ToggleGroupItem>
          <ToggleGroupItem
            value={FilterType.Invalid}
            aria-label="Toggle invalid"
          >
            {`Invalid ${totalRowsWithErrors}`}
          </ToggleGroupItem>
        </ToggleGroup>

        <Combobox
          placeholder="Actions"
          items={[
            { label: "Download", value: ActionType.Download },
            {
              label: "Find and Replace",
              value: ActionType.FindReplace,
            },
          ]}
          value=""
          onChange={(val) => handleActionChange(val as ActionType)}
        />
        {/* Open up a combobox that has all the different types of validation errors */}
        <Combobox
          placeholder={
            <div className="flex w-full items-center justify-between gap-2">
              <span className="">Filter By Error</span>
              <Icons.filter className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </div>
          }
          //   create a list of unique validation errors
          items={validationErrors.reduce(
            (acc, error) => {
              if (!acc.find((item) => item.value === error.validationStatus)) {
                acc.push({
                  label: error.validationStatus,
                  value: error.validationStatus,
                });
              }
              return acc;
            },
            [] as { label: string; value: string }[],
          )}
          value=""
          onChange={(val) => handleFilterChange(val)}
        />
      </section>
      {isDialogOpen && (
        <FindAndReplaceDialog
          gridApi={gridApi}
          isOpen={isDialogOpen}
          setIsOpen={setDialogOpen}
        />
      )}
    </>
  );
};
