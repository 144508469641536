import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql/gql";
import {
  GetVendorSubmissionTypesQuery,
  SubmissionTypesQuery,
  Vendor,
} from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

import { useDecodedJwtCookie } from "./Auth0";

const query = graphql(`
  query SubmissionTypes($where: SubmissionTypeWhereInput) {
    submissionTypes(where: $where) {
      id
      createdAt
      updatedAt
      name
      description
      organizationId
      outputColumns {
        name
        dataType
        description
      }
      type
    }
  }
`);

export type OrgSubmissionType = SubmissionTypesQuery["submissionTypes"][0];

export const getOrgSubmissionTypesQueryOptions = ({
  organizationId,
}: {
  organizationId: number | undefined;
}) => {
  return createQueryOptions({
    enabled: !!organizationId,
    query,
    variables: {
      where: {
        organizationId: {
          equals: organizationId,
        },
      },
    },
  });
};

export const useCurrentOrgSubmissionTypesQuery = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const organizationId = decodedJwt?.claims?.orgId;

  const options = getOrgSubmissionTypesQueryOptions({
    organizationId,
  });

  return useSuspenseQuery(options);
};

export type VendorSubmissionType = NonNullable<
  GetVendorSubmissionTypesQuery["vendor"]
>["submissionTypes"][0];

export const getVendorSubmissionTypesQueryOptions = (
  vendorId: Vendor["id"] | undefined,
) => {
  return createQueryOptions({
    enabled: vendorId != null,
    query: graphql(`
      query getVendorSubmissionTypes($where: VendorWhereUniqueInput!) {
        vendor(where: $where) {
          submissionTypes {
            id
            createdAt
            updatedAt
            name
            description
            organizationId
            outputColumns {
              name
              dataType
              description
            }
            type
          }
        }
      }
    `),
    variables: {
      where: {
        id: vendorId,
      },
    },
  });
};
