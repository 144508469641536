import { SortOrder } from "@intuitive-systems/protege-engine";

import { graphql } from "@/gql/gql";
import { QueryMode } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

import { FilterTypeaheadItem } from "../table/FilterTypeaheadTextInput";
import { UseTypeaheadHook } from "../table/TableFiltersContext";

export const useGetProductTypeaheadItems: UseTypeaheadHook = ({
  searchField,
  typeahead = "",
  searchVars,
  activeFilter,
}) => {
  const queryVars = {
    where: {
      ...searchVars,
      [searchField]: {
        mode: QueryMode.Insensitive,
        contains: typeahead,
      },
    },
  };

  const { data } = useGraphqlQuery({
    queryKey: ["GetProductTypeaheadItems", searchField, typeahead, searchVars],
    query: graphql(`
      query GetProductTypeaheadItems(
        $take: Int
        $where: ProductWhereInput
        $distinct: [ProductScalarFieldEnum!]
        $orderBy: [ProductOrderByWithRelationAndSearchRelevanceInput!]
      ) {
        products(
          take: $take
          where: $where
          distinct: $distinct
          orderBy: $orderBy
        ) {
          id
          category
          family
          description
          line
          manufacturer
          manufacturerPartNumbers
          model
          name
          searchFamily
          searchLine
          searchName
          updatedAt
          createdAt
          attributes
        }
      }
    `),
    variables: {
      ...queryVars,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      take: 10,
      // @ts-ignore labelName is correct
      distinct: searchField,
    },
  });

  // Transform the data into FilterTypeaheadItem[]
  const typeaheadItems: FilterTypeaheadItem[] =
    data?.products?.map((product) => ({
      // @ts-ignore - searchField is correct
      label: product[searchField],
      // @ts-ignore - searchField is correct
      value: product[searchField],
      selected:
        // @ts-ignore - searchField is correct
        activeFilter?.filterValues?.includes(product[searchField]) ?? false,
    })) ?? [];

  return typeaheadItems;
};
