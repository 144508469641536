import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";

import { ProductRowData, ProductsColumnHeaders } from "../getProductsTableRows";

type Props = {
  rowData: ProductRowData;
  onClose: () => void;
};

export const ProductDescriptionDialog = ({ rowData, onClose }: Props) => {
  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange} open={true}>
      <DialogContent className="sm:max-w-[850px]">
        <DialogHeader>
          <DialogTitle>{rowData[ProductsColumnHeaders.NAME]}</DialogTitle>
          <DialogDescription>
            Make changes to this product here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>

        <div className=" py-4">
          <section className="grid grid-cols-2 gap-4">
            {Object.entries(rowData).map(([key, value]) => {
              // exclude id, mfg part numbers, attributes from the main section
              if (key === ProductsColumnHeaders.ID) return null;
              if (key === ProductsColumnHeaders.ATTRIBUTES) return null;

              if (key === ProductsColumnHeaders.MANUFACTURER_PART_NUMBER)
                return (
                  <div
                    key={key}
                    className="grid grid-cols-4 items-center gap-4"
                  >
                    <Label htmlFor={key} className="text-right">
                      {key}
                    </Label>
                    <Input
                      id={key}
                      defaultValue={(value as string[]).join(", ")}
                      className="col-span-3"
                    />
                  </div>
                );

              return (
                <div key={key} className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor={key} className="text-right">
                    {key}
                  </Label>
                  <Input
                    id={key}
                    defaultValue={value as string}
                    className="col-span-3"
                  />
                </div>
              );
            })}
          </section>
          <Separator className="my-4" />
          <h1 className="pb-4 text-center">Product Attributes</h1>
          <section className="grid grid-cols-2 gap-4">
            {Object.entries(rowData[ProductsColumnHeaders.ATTRIBUTES]).map(
              ([key, value]) => (
                <div key={key} className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor={key} className="text-right">
                    {key}
                  </Label>
                  <Input id={key} defaultValue={value} className="col-span-3" />
                </div>
              ),
            )}
          </section>
        </div>
        <DialogFooter>
          <Button type="submit">Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
