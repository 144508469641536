import React, { Suspense, useMemo } from "react";
import { addDays } from "date-fns";

import { StatCardSkeleton } from "@/components/StatCardSkeleton";
import { cn } from "@/lib/cn";
import { useTodayStore } from "@/lib/stores/date";

import { KPMCardSubmissionAccuracy } from "../home/(KPMs)/KPMCardSubmissionAccuracy";
import { KPMCardSubmissionCount } from "../home/(KPMs)/KPMCardSubmissionCount";
import { KPMCardTimeSaved } from "../home/(KPMs)/KPMCardTimeSaved";
import { TimePeriod } from "../home/DatePickerWithRange";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const SubmissionsKPMs = ({ className, ...props }: Props) => {
  const { today } = useTodayStore();

  const dateRange = useMemo(() => {
    return {
      from: addDays(today, -7),
      to: today,
    };
  }, [today]);

  const dateRangeType = TimePeriod.WEEK;

  return (
    <article
      {...props}
      className={cn("grid gap-4 sm:grid-cols-3 lg:grid-cols-3", className)}
    >
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardSubmissionCount
          dateRange={dateRange}
          dateRangeType={dateRangeType}
        />
      </Suspense>
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardSubmissionAccuracy
          dateRange={dateRange}
          dateRangeType={dateRangeType}
        />
      </Suspense>

      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardTimeSaved dateRange={dateRange} dateRangeType={dateRangeType} />
      </Suspense>
    </article>
  );
};
