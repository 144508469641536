import { graphql } from "@/gql";
import { Submission, Vendor } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getSubmissionRouteQueryOptions = ({
  submissionId,
  vendorId,
}: {
  submissionId: Submission["id"];
  vendorId: Vendor["id"];
}) => {
  return createQueryOptions({
    query: graphql(`
      query SubmissionRoute(
        $where: SubmissionWhereUniqueInput!
        $normalizeMappingsWhere2: NormalizeMappingWhereInput
      ) {
        submission(where: $where) {
          status
          transformations {
            status
            type {
              templateId
            }
          }
          type {
            id
            normalizeMappings(where: $normalizeMappingsWhere2) {
              mapping
            }
          }
          fileId
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
      normalizeMappingsWhere2: {
        vendorId: {
          equals: vendorId,
        },
      },
    },
  });
};
