import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import {
  CreateOneFileMutationMutation,
  CreateSubmissionMutation,
  PrepareFileUploadMutationMutation,
} from "@/gql/graphql";

import { useCreateOneFileMutation } from "./useCreateOneFileMutation";
import { useCreateSubmissionMutation } from "./useCreateSubmissionMutation";
import { usePrepareFileUploadMutation } from "./usePrepareFileUploadMutation";

type UploadFileAndCreateSubmissionArgs = {
  file: File;
  submissionTypeId: number;
};

export const useUploadFileAndCreateSubmission = () => {
  const { mutate: prepareFileUpload } = usePrepareFileUploadMutation();
  const { mutate: createOneFile } = useCreateOneFileMutation();
  const { mutate: createSubmission } = useCreateSubmissionMutation();
  const { vendorId: vId } = useParams();
  const vendorId = Number(vId);

  return useMutation({
    mutationKey: ["uploadFileAndCreateSubmission"],
    mutationFn: async ({
      file,
      submissionTypeId,
    }: UploadFileAndCreateSubmissionArgs) => {
      try {
        const prepareData = (await new Promise((resolve, reject) => {
          prepareFileUpload(
            { fileName: file.name },
            {
              onSuccess: (data) => resolve(data),
              onError: (error) => {
                toast("Failed to prepare file upload");
                reject(error);
              },
            },
          );
        })) as PrepareFileUploadMutationMutation;

        const { signedUrl, key } = prepareData.prepareFileUpload;
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "Content-Length": String(file.size),
          },
          body: file,
        };

        const uploadResponse = await fetch(signedUrl, options);
        if (!uploadResponse.ok) {
          throw new Error("Failed to upload file");
        }

        const createFileData = (await new Promise((resolve, reject) => {
          createOneFile(
            {
              data: {
                key,
                mimetype: file.type,
                name: file.name,
                size: file.size,
              },
            },
            {
              onSuccess: (data) => {
                resolve(data);
              },
              onError: (error) => {
                toast("Failed to create file");
                reject(error);
              },
            },
          );
        })) as CreateOneFileMutationMutation;

        const fileId = createFileData.createOneFile.id;
        const submissionData = (await new Promise((resolve, reject) => {
          createSubmission(
            {
              submittedFor: new Date().toISOString(),
              submissionTypeId,
              vendorId,
              fileId,
            },
            {
              onSuccess: (data) => {
                resolve(data);
              },
              onError: (error) => {
                toast("Failed to create submission");
                reject(error);
              },
            },
          );
        })) as CreateSubmissionMutation;

        return submissionData; // Assuming 'id' is the field where the file ID is stored
      } catch (error) {
        console.error(
          "An error occurred during the file upload process:",
          error,
        );
        throw error; // You can handle specific errors or rethrow them to be caught by the mutation error handlers
      }
    },
  });
};
