import {
  AlertCircle,
  AlertTriangle,
  Archive,
  ArrowDownIcon,
  ArrowDownUp,
  ArrowLeft,
  ArrowRight,
  ArrowRightCircleIcon,
  ArrowUpIcon,
  Banknote,
  BookOpenIcon,
  Building,
  Calendar,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  Circle,
  ClockIcon,
  Code2,
  Coins,
  Container,
  Copy,
  CornerDownRight,
  Database,
  DatabaseZap,
  DollarSign,
  Download,
  Dumbbell,
  Edit,
  Equal,
  Eye,
  EyeOff,
  File,
  FileQuestion,
  FileSpreadsheet,
  Filter,
  Flag,
  Gauge,
  Globe,
  GripVertical,
  HandCoins,
  HardHat,
  HelpCircle,
  Home,
  Hourglass,
  Infinity,
  Info,
  LayoutDashboard,
  LineChart,
  Lock,
  LogOut,
  Mail,
  Menu,
  MessageSquare,
  MoreHorizontal,
  Package,
  Paperclip,
  PencilLine,
  Plus,
  ReceiptText,
  RefreshCcw,
  SaveIcon,
  ScaleIcon,
  Search,
  Settings,
  Store,
  Tag,
  Target,
  ThumbsUp,
  Trash,
  Undo2,
  UploadCloud,
  User,
  UserPlus,
  Users,
  XCircle,
  XCircleIcon,
  Zap,
} from "lucide-react";

import { BrainAiThinking } from "./icons/BrainAiThinking";
import { SparklesSoft } from "./icons/sparkles-soft";

export const Register = {
  equals: Equal,
  pencilLine: PencilLine,
  billOfLading: Container,
  invoice: HandCoins,
  receipt: ReceiptText,
  question: FileQuestion,
  package: Package,
  coins: Coins,
  globe: Globe,
  money: Banknote,
  gripVertical: GripVertical,
  search: Search,
  chevronDown: ChevronDown,
  cornerDownRight: CornerDownRight,
  chevronsDown: ChevronsDown,
  chevronsLeft: ChevronsLeft,
  chevronsUp: ChevronsUp,
  hardHat: HardHat,
  book: BookOpenIcon,
  dollar: DollarSign,
  upload: UploadCloud,
  user: User,
  check: Check,
  home: Home,
  analytics: LineChart,
  settings: Settings,
  file: File,
  building: Building,
  copy: Copy,
  checkCircle: CheckCircle,
  dumbbell: Dumbbell,
  gauge: Gauge,
  action: Zap,
  ai: BrainAiThinking,
  aiStars: SparklesSoft,
  archive: Archive,
  arrowDown: ArrowDownIcon,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  arrowRightCircle: ArrowRightCircleIcon,
  arrowUp: ArrowUpIcon,
  attachment: Paperclip,
  back: Undo2,
  calendar: Calendar,
  chevronsRight: ChevronsRight,
  comment: MessageSquare,
  confirm: ThumbsUp,
  dashboard: LayoutDashboard,
  database: Database,
  databaseExport: DatabaseZap,
  download: Download,
  edit: Edit,
  ellipses: MoreHorizontal,
  error: AlertTriangle,
  eye: Eye,
  eyeOff: EyeOff,
  filter: Filter,
  flag: Flag,
  help: HelpCircle,
  information: Info,
  infinity: Infinity,
  inProgress: Hourglass,
  invalidated: XCircle,
  label: Tag,
  lock: Lock,
  logout: LogOut,
  menu: Menu,
  notice: ScaleIcon,
  plus: Plus,
  priorityHigh: AlertCircle,
  remove: XCircleIcon,
  reprocess: RefreshCcw,
  save: SaveIcon,
  send: Mail,
  signUp: UserPlus,
  sort: ArrowDownUp,
  submission: FileSpreadsheet,
  target: Target,
  time: ClockIcon,
  todo: Circle,
  trash: Trash,
  transformation: Code2,
  users: Users,
  validated: CheckCircle,
  vendor: Store,
} as const;

type IconComponents = typeof Register;
type IconPropsComponents = {
  [K in keyof IconComponents]: React.FC<React.HTMLAttributes<SVGElement>>;
};

export const Icons: IconPropsComponents = Register;

export type IconType = (typeof Icons)[keyof typeof Icons];
