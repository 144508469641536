import { ColumnDataType } from "@/gql/graphql";

import {
  TypedOutputColumn,
  TypedOutputColumnTypes,
} from "./useGetTypedOutputColumns";

type Params = {
  header: string;
  typedOutputColumns: TypedOutputColumn[];
};

export const getHeaderType = ({ header, typedOutputColumns }: Params) => {
  const typedOutputColumn = typedOutputColumns.find(
    (col) => col.name === header,
  );

  const type = typedOutputColumn?.type ?? TypedOutputColumnTypes.STRING;

  const dataType = typedOutputColumn?.dataType ?? ColumnDataType.String;

  return { type, dataType };
};
