import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateNormalizeMapping = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation CreateNormalizeMapping($data: NormalizeMappingCreateInput!) {
        createOneNormalizeMapping(data: $data) {
          id
        }
      }
    `),
  });
};
