import { useInfiniteQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { GetProductsInfiniteQueryVariables, SortOrder } from "@/gql/graphql";
import { getGraphqlClient } from "@/lib/hooks/graphql";

const query = graphql(`
  query GetProductsInfinite(
    $take: Int
    $where: ProductWhereInput
    $orderBy: [ProductOrderByWithRelationAndSearchRelevanceInput!]
    $cursor: ProductWhereUniqueInput
    $aggregateProductWhere2: ProductWhereInput
  ) {
    products(take: $take, where: $where, orderBy: $orderBy, cursor: $cursor) {
      attributes
      category
      createdAt
      description
      family
      id
      line
      manufacturer
      manufacturerPartNumbers
      model
      name
      searchFamily
      searchLine
      searchName
      updatedAt
    }
    aggregateProduct(where: $aggregateProductWhere2) {
      _count {
        id
      }
    }
  }
`);

type Params = {
  searchVars: GetProductsInfiniteQueryVariables["where"];
};

export const useGetProducts = ({ searchVars }: Params) => {
  const client = getGraphqlClient();

  return useInfiniteQuery({
    queryKey: ["GetProductsInfinite", searchVars],
    queryFn: async (data) => {
      const lastProductId = data.pageParam;
      const cursorObj =
        data.pageParam !== 0
          ? {
              id: lastProductId,
            }
          : undefined;

      const queryVars = {
        where: {
          ...searchVars,
        },
        take: 40,
        cursor: cursorObj,
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
        ],
        aggregateProductWhere2: {
          ...searchVars,
        },
      };

      const productData = await client.request(query, queryVars);
      return productData;
    },
    initialPageParam: 0,
    getNextPageParam: (currentQuery, allQueriedData) => {
      const products = allQueriedData.flatMap((data) => data.products);
      const totalProducts = currentQuery.aggregateProduct._count?.id ?? 0;

      if (products.length === totalProducts) {
        return undefined;
      }
      return products[products.length - 1].id;
    },
  });
};
