import { ReactNode } from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/cn";
import { TimePeriod } from "@/app/dashboard/home/DatePickerWithRange";

interface ValueLabel {
  value: string | number;
  label: string;
}

export interface Stat {
  name: string;
  data: ValueLabel;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  stat: Stat;
  prevStat?: Stat;
  icon: ReactNode;
  timePeriod: TimePeriod;
  onClick?: () => void;
}

export default function StatCard({
  stat,
  prevStat,
  timePeriod,
  icon,
  className,
  onClick,
}: Props) {
  const change =
    prevStat &&
    calcChange(stat.data.value as number, prevStat?.data.value as number);

  const spanText = getSpanText(change, timePeriod);

  return (
    <Card
      className={cn("w-full", onClick && "cursor-pointer", className)}
      onClick={onClick}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="line-clamp-1 text-sm font-medium leading-6 text-muted-foreground">
          {stat.name}
        </CardTitle>
        <div className="text-muted-foreground">{icon}</div>
      </CardHeader>
      <CardContent>
        <div className="pb-2 text-3xl font-semibold tracking-tight text-foreground">
          {stat.data.label}
        </div>
        {prevStat && (
          <span className=" line-clamp-2 text-xs text-muted-foreground">
            {spanText}
          </span>
        )}
      </CardContent>
    </Card>
  );
}

export const getSpanText = (
  change: number | undefined,
  timePeriod: TimePeriod,
) => {
  const period = timePeriod?.toLocaleLowerCase();
  if (change == null) {
    return `No data previous ${period}}`;
  }

  if (change === Infinity) {
    return `No data previous ${period}`;
  } else if (change > 0) {
    return `+${change}% over last ${period}`;
  } else if (change < 0) {
    return `${change}% over last ${period}`;
  }
  return `No change over last ${period}`;
};

const calcChange = (current: number, prev: number) => {
  const change = ((current - prev) / prev) * 100;
  return isNaN(change) ? 0 : Math.round(change);
};
