import { formatISO, parseISO } from "date-fns";
import { match } from "ts-pattern";

import { QueryMode } from "@/gql/graphql";

import {
  FilterOperationOptions,
  useTableFilterContext,
} from "../table/TableFiltersContext";
import { SubmissionFilterOptions } from "./SubmissionsTableWrapper";

export const useGetSubmissionsSearchVarsFromActiveFilters = () => {
  const {
    searchNameFilter,
    dateRangeFilter,
    activeFilters,
    selectedSubmissionPeriods,
  } = useTableFilterContext();

  const variables: Record<string, any> = {};
  activeFilters.forEach((filter) => {
    if (filter?.filterValues && filter.filterValues.length > 0) {
      const searchField = filter.searchField as SubmissionFilterOptions;
      const filterValues = filter.filterValues;
      const filterOperationOption = filter.filterOperation
        .value as FilterOperationOptions;
      variables[searchField] = {};

      match(searchField)
        .with(SubmissionFilterOptions.Status, () => {
          match(filterOperationOption)
            .with(FilterOperationOptions.IN, () => {
              variables[searchField].in = filterValues;
            })
            .with(FilterOperationOptions.CONTAINS, () => {})
            .exhaustive();
        })
        .with(SubmissionFilterOptions.Type, () => {
          match(filterOperationOption)
            .with(FilterOperationOptions.IN, () => {
              variables[searchField].in = filterValues;
            })
            .with(FilterOperationOptions.CONTAINS, () => {})
            .exhaustive();
        })
        .with(SubmissionFilterOptions.Vendor, () => {
          match(filterOperationOption)
            .with(FilterOperationOptions.IN, () => {})
            .with(FilterOperationOptions.CONTAINS, () => {
              if (filter?.filterValues && filter?.filterValues.length > 0) {
                variables[searchField] = {
                  is: {
                    OR: filterValues.map((value) => ({
                      name: {
                        mode: QueryMode.Insensitive,
                        contains: value,
                      },
                    })),
                  },
                };
              }
            })
            .exhaustive();
        })
        .exhaustive();
    }
  });

  if (searchNameFilter) {
    variables.searchName = {
      mode: QueryMode.Insensitive,
      contains: searchNameFilter,
    };
  }

  // use either date range or submission period, prioritize submission period
  if (selectedSubmissionPeriods.length > 0) {
    variables.OR = selectedSubmissionPeriods.map((period) => ({
      submittedFor: {
        gte: formatISO(parseISO(`${period.start}T00:00:00`)),
        lte: formatISO(parseISO(`${period.end}T23:59:59`)),
      },
    }));
  } else if (dateRangeFilter) {
    variables.createdAt = {
      gte: dateRangeFilter.from,
      lte: dateRangeFilter.to,
    };
  }

  return variables;
};
